import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

const BuilderNavLink = ({
  href,
  className,
  title,
  content,
  target,
  isCollapsed,
  isActive,
  isFullLink,
}) => {
  const ref = useRef();
  const refActive = useRef();
  const router = useRouter();
  const { pathname, asPath } = router;
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      setIsHover(true);
    }
  };

  const handleMouseOut = (event) => {
    setIsHover(false);
  };

  const handleActiveMouseEnter = (event) => {
    if (refActive.current && refActive.current.contains(event.target)) {
      setIsHover(true);
    }
  };

  const handleActiveMouseOut = (event) => {
    setIsHover(false);
  };

  useEffect(() => {
    if (!isActive) {
      setIsHover(false);
    }
    return () => {};
  }, [isActive]);

  useEffect(() => {
    if (ref?.current) {
      const { current } = ref;
      current.addEventListener('mouseenter', handleMouseEnter);
      current.addEventListener('mouseleave', handleMouseOut);
    }

    return () => {
      if (ref?.current) {
        const { current } = ref;
        current.removeEventListener('mouseenter', handleMouseEnter);
        current.removeEventListener('mouseleave', handleMouseOut);
      }
    };
  }, [ref, isActive]);

  useEffect(() => {
    if (refActive?.current) {
      const { current } = refActive;
      current.addEventListener('mouseenter', handleActiveMouseEnter);
      current.addEventListener('mouseleave', handleActiveMouseOut);
    }

    return () => {
      if (refActive?.current) {
        const { current } = refActive;
        current.removeEventListener('mouseenter', handleActiveMouseEnter);
        current.removeEventListener('mouseleave', handleActiveMouseOut);
      }
    };
  }, [refActive, isActive]);

  const currentLink = isFullLink ? href : `/builder${href}`;

  return (
    <>
      {isActive ? (
        <span
          ref={refActive}
          className={`nav-link ${className} ${isActive ? 'active' : ''} ${
            isHover ? 'hover' : ''
          } ${isCollapsed ? 'collapse' : ''}`}
          // title={`${isCollapsed ? '' : title}`}
          // aria-label={title}
          // onMouseOver={handleActiveMouseEnter}
          // onMouseOut={handleActiveMouseOut}
        >
          <span>{content}</span>
        </span>
      ) : (
        <Link href={isActive ? asPath : currentLink} shallow>
          <a
            ref={ref}
            className={`nav-link ${className} ${isActive ? 'active' : ''} ${
              isHover ? 'hover' : ''
            } ${isCollapsed ? 'collapse' : ''}`}
            // title={`${isCollapsed ? '' : title}`}
            // aria-label={title}
            target="_self"
            // onMouseOver={handleMouseEnter}
            // onMouseOut={handleMouseOut}
          >
            <span>{content}</span>
          </a>
        </Link>
      )}
      <span className="hover-label">{content}</span>
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .nav-link {
            @include font(14px, 600, -0.3px, 32px, rgba($white, 0.6));

            &:before {
              position: absolute;
              display: inline-block;
              content: '';
              top: 4px;
              left: -8px;
              width: 4px;
              height: 24px;
              background: transparent;
            }

            &.hover {
              color: $white;
              &:before {
                background: $white;
              }
            }

            &.active {
              color: $white;
              cursor: default;
              &:before {
                background: $point_1;
              }
            }

            &.home {
              background-image: url('#{$image_url}/bt_home/normal.png');

              &.active,
              &.hover {
                background-image: url('#{$image_url}/bt_home/focus.png');
              }
            }

            &.scope {
              background-image: url('#{$image_url}/bt_scope/normal.png');

              &.active,
              &.hover {
                background-image: url('#{$image_url}/bt_scope/focus.png');
              }
            }

            &.knowledge {
              background-image: url('#{$image_url}/bt_knowledge/normal.png');

              &.active,
              &.hover {
                background-image: url('#{$image_url}/bt_knowledge/focus.png');
              }
            }

            &.livechat {
              background-image: url('#{$image_url}/bt_livechat/normal.svg');
              &.active,
              &.hover {
                background-image: url('#{$image_url}/bt_livechat/focus.svg');
              }
            }

            &.mypage {
              background-image: url('#{$image_url}/bt_my_page_white/inactive.svg');
              &.active,
              &.hover {
                background-image: url('#{$image_url}/bt_my_page_white/normal@2x.png');
              }
            }

            &.analysis {
              background-image: url('#{$image_url}/bt_analytics/normal.png');
              &.active,
              &.hover {
                background-image: url('#{$image_url}/bt_analytics/focus.png');
              }
            }

            & + .hover-label {
              display: none;
            }

            &.collapse {
              visibility: visible;
              & > span {
                display: none;
              }

              &:before {
                left: -12px;
              }

              &.hover {
                & + .hover-label {
                  display: block;
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

BuilderNavLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.any]).isRequired,
  isCollapsed: PropTypes.bool.isRequired,
};

BuilderNavLink.defaultProps = {
  className: '',
};

export default BuilderNavLink;
