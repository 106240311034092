import { createSlice, createSelector } from '@reduxjs/toolkit';

const referenceSlice = createSlice({
  name: 'reference',
  initialState: {
    selectedStep: null,
    startRef: null,
    destinationRef: null,
    destinationHistory: [],
    isDestinationUpdated: false,
    resetSubjectFlowPosition: false,
  },
  reducers: {
    clearAllRef: (state, action) => {
      state.selectedStep = null;
      state.startRef = null;
      state.destinationRef = null;
      state.destinationStep = null;
      state.isDestinationUpdated = false;
      state.destinationHistory = [];
    },
    setSelectedStep: (state, action) => {
      state.selectedStep = action.payload;
    },
    setStartRef: (state, action) => {
      state.startRef = action.payload;
    },
    setDestinationRef: (state, action) => {
      state.destinationRef = action.payload;
    },
    setDestinationStep: (state, action) => {
      state.destinationStep = action.payload;
    },
    setIsDestinationUpdated: (state, action) => {
      state.isDestinationUpdated = action.payload;
    },
    setDestinationHistory: (state, action) => {
      state.destinationHistory = action.payload;
    },
    setResetSubjectFlowPosition: (state, action) => {
      state.resetSubjectFlowPosition = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  clearAllRef,
  setSelectedStep,
  setStartRef,
  setDestinationRef,
  setDestinationStep,
  setIsDestinationUpdated,
  setDestinationHistory,
  setResetSubjectFlowPosition,
} = referenceSlice.actions;

export const reference = createSelector(
  (state) => ({
    selectedStep: state.reference.selectedStep,
    startRef: state.reference.startRef,
    destinationRef: state.reference.destinationRef,
    destinationStep: state.reference.destinationStep,
    isDestinationUpdated: state.reference.isDestinationUpdated,
    destinationHistory: state.reference.destinationHistory,
    resetSubjectFlowPosition: state.reference.resetSubjectFlowPosition,
  }),
  (state) => state
);

export default referenceSlice.reducer;
