import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { useAcademy } from '@context/UseAcademy';

const ACTION_POPUP_TYPE = {
  ACTION: 'ACTION',
  CLOSE: 'CLOSE',
};

const WIDTH = {
  X_SMALL: 360,
  SMALL: 384,
  SMALL_MEDIUM: 408,
  MEDIUM: 480,
  MEDIUM_LARGE: 592,
  LARGE: 663,
  X_LARGE: 782,
  XX_LARGE: 1016,
  SUPER_LARGE: 1286,
  know_small: 432,
  know_medium: 584,
  know_large: 632,
  IMPORT_SUBJECT: 696,
  IMPORT_SUBJECT_DETAIL: 928,
  VALIDATE_RESULT: 1040,
  MESSAGE: 1054,
  ONTOLOGY_EXTEND: 1151,
  REFERENCE_SUBJECT: 682,
};

const ActionPopup = ({
  type,
  className,
  title,
  children,
  lblOK,
  lblCancel,
  onOKHandled,
  onCancelHandled,
  isOKDisabled,
  isFooter,
  canICancel,
  isSaving,
  outSideClassName,
  bodyRef,
  submitButtonRef,
  size,
  isFullScreen = false,
  width = WIDTH.SMALL,
  footerContent = null,
  bodyPadding = true,
  btnType = 'primary',
  isResponsive = false,
}) => {
  const [isAni, setIsAni] = useState(false);
  const { isAcademyMode } = useAcademy();
  const ref = useRef();
  const popupRef = useRef();
  const [isDrag, setIsDrag] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  const onOKClicked = () => {
    if (onOKHandled) {
      onOKHandled();
    }
  };

  const onCancelClicked = useCallback(() => {
    setIsDrag(false);
    setIsMoving(false);

    if (onCancelHandled) {
      onCancelHandled();
    }
  }, [onCancelHandled]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event?.key === 'Escape' && canICancel) {
        const popups = document.querySelectorAll('.builder-action-popup');
        const list = Array.from(popups);
        if (list.length < 2) {
          onCancelClicked();
        } else {
          const last = list[list.length - 1];
          const splits = className.split(' ');
          let name = className;
          if (splits.length > 0) {
            name = splits[0];
          }

          if (last && last.classList.contains(name)) {
            onCancelClicked();
          }
        }
      }
    },
    [ref, onCancelClicked]
  );

  const onClickContainer = (event) => {
    const popups = document.querySelectorAll('.builder-action-popup');
    const list = Array.from(popups);
    if (list.length > 1) {
      event.stopPropagation();
    }

    if (isMoving) {
      setIsMoving(false);
      return;
    }

    if (ref.current && !ref.current.contains(event.target) && canICancel) {
      // && isOKDisabled
      let outsides = [];
      let result = false;

      if (outSideClassName?.length > 0) {
        outsides = outSideClassName.map(
          (out) => document.querySelector(`.${out}`) // context-menu
        );

        if (outsides.length > 0) {
          outsides.forEach((out) => {
            if (out && out.contains(event.target)) {
              result = true;
            }
          });
        }
      }

      setIsMoving(false);
      if (!result) {
        onCancelClicked();
      }
    }
  };

  const onMouseDown = () => {
    setIsDrag(true);
  };

  const onMouseUp = () => {
    setIsDrag(false);
  };

  const onMouseMove = () => {
    if (isDrag) {
      setIsMoving(true);
    } else {
      setIsMoving(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (ref?.current) {
      setTimeout(() => {
        setIsAni(true);
      }, 1);
    }

    return () => {
      setIsAni(false);
    };
  }, [ref]);

  const node = isAcademyMode
    ? document && document.getElementById('builder-layout')
    : undefined;

  return (
    <Portal node={node}>
      <div
        ref={popupRef}
        className={`popup-default builder-action-popup positive ${className} ${type.toLowerCase()} ${
          isAni ? 'open' : ''
        } ${bodyPadding ? '' : '--no-padding'}`}
      >
        <div
          className="popup-container"
          onClick={onClickContainer}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          <div className="popup-wrapper">
            <div className="popup-content">
              <div
                ref={ref}
                className="content-wrapper"
                style={{
                  width: WIDTH[size] ?? width,
                  maxWidth: isResponsive ? 'calc(100vw - 32px)' : 'none',
                }}
              >
                {title && <div className="popup-header">{title}</div>}
                <div ref={bodyRef} className="popup-body">
                  {children}
                </div>
                {isFooter && (
                  <div className={`popup-footer ${size}`}>
                    {footerContent}
                    <div className="float-right">
                      {onCancelHandled && (
                        <button
                          type="button"
                          tabIndex={0}
                          className="btn-cancel"
                          onClick={onCancelClicked}
                          title={lblCancel}
                          data-testid="btn-cancel"
                        >
                          {lblCancel}
                        </button>
                      )}

                      {type === ACTION_POPUP_TYPE.ACTION && (
                        <button
                          ref={submitButtonRef}
                          type="button"
                          tabIndex={0}
                          className={`btn-ok ${btnType} ${
                            isSaving ? 'saving' : ''
                          }`}
                          onClick={onOKClicked}
                          disabled={isOKDisabled || isSaving}
                          title={lblOK}
                          data-testid="btn-submit"
                        >
                          {lblOK}
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx global>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .builder-action-popup {
            &.popup-default {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100vw;
              height: 100vh;
              z-index: 998;
              overflow: auto;
              background: rgba(43, 44, 45, 0.5);
              -webkit-overflow-scrolling: touch;

              &.open {
                .popup {
                  &-wrapper {
                    opacity: 1;
                    transform: translateY(0);
                  }
                }
              }

              &.scope-settings .popup-header {
                font-size: 18px;
              }

              &.terminate-session {
                .popup-header {
                  font-size: 18px;
                  line-height: 24px;
                  letter-spacing: -0.02em;
                  padding: 32px;
                }
                .popup-body {
                  letter-spacing: -0.02em;
                  color: #394551;
                }
                .popup-footer {
                  padding: 48px 32px 32px;
                  line-height: 20px;
                }
              }

              &.popup-welcome {
                .popup-body {
                  width: 100%;
                  padding: 0;
                }
              }

              &.--no-padding {
                .popup-body {
                  width: 100%;
                  padding: 0;
                }
              }

              &.import-template {
                .content-wrapper {
                  height: 100vh;
                }

                .popup-body {
                  padding: 0;
                }
                .popup-footer {
                  padding-top: 48px;
                  padding-right: 0;
                }
                &.h-auto {
                  .content-wrapper {
                    height: auto;
                  }
                }
              }

              &.edit-principal-block {
                .popup-body {
                  padding-bottom: 20px;
                }
                .popup-footer {
                  padding-top: 28px;
                }
              }

              &.delete-ai-image {
                .popup-header {
                  font-size: 18px;
                  line-height: 30px;
                  letter-spacing: -0.02em;
                  padding: 32px;
                }
                .popup-footer {
                  padding-top: 16px;
                }
              }

              &.popup-target-message {
                .popup-header {
                  font-size: 18px;
                  line-height: 24px;
                  letter-spacing: -0.02em;
                  padding: 32px;
                }
              }

              &.import-subject-popup {
                .content-wrapper {
                  height: 587px;
                }
              }
            }

            &.extend {
              .content-wrapper {
                height: 100%;
              }
            }

            .popup {
              &-container {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                ${!isResponsive ? `min-width: 1366px;` : ''}
                min-height: $builder_min_height;
              }

              &-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: table;
                opacity: 0;
                transform: translateY(-30px);
                transition: transform 0.5s, opacity 0.3s;
              }

              &-content {
                display: table-cell;
                ${!isResponsive ? `min-width: 1366px;` : ''}
                vertical-align: middle;
                padding: ${isFullScreen ? '0' : '24px 0'};
                height: 100%;
                @media all and (min-height: $builder_min_height) {
                  height: auto;
                }

                .content-wrapper {
                  position: relative;
                  margin: 0 auto;
                  width: 384px;
                  border-radius: 2px;
                  display: flex;
                  flex-direction: column;
                  background: $white;
                  max-height: ${isFullScreen ? 'auto' : '664px'};
                  @media all and (min-height: $builder_min_height) {
                    max-height: ${isFullScreen ? 'auto' : 'calc(100vh - 48px)'};
                  }
                }
              }

              &-header {
                @include font(20px, 700, -0.4px, 27px, inherit);
                padding: 32px 32px 24px 32px;
                flex: 0;
              }

              &-body {
                flex: 1;
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 100%;
                padding: 0 32px;
                height: auto !important;
                overflow-y: auto;
              }

              &-footer {
                padding: 48px 32px 32px 32px;
                display: block;

                button {
                  display: inline-block;
                  width: auto;
                  min-width: 88px;
                  margin-left: 0px;
                  margin-right: 16px;

                  &:last-child {
                    margin-right: 0px;
                  }
                }

                &.X_SMALL {
                  padding-left: 32px;
                }
              }
            }

            .footer-right {
              .popup-footer {
                text-align: right;
              }
            }
          }
        `}
      </style>
    </Portal>
  );
};

ActionPopup.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node.isRequired,
  lblOK: PropTypes.string,
  lblCancel: PropTypes.string,
  onOKHandled: PropTypes.func,
  onCancelHandled: PropTypes.func,
  isOKDisabled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  isFooter: PropTypes.bool,
  canICancel: PropTypes.bool,
  isSaving: PropTypes.bool,
  footerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bodyPadding: PropTypes.bool,
  outSideClassName: PropTypes.arrayOf(PropTypes.string),
  btnType: PropTypes.string,
  isResponsive: PropTypes.bool,
};

ActionPopup.defaultProps = {
  type: ACTION_POPUP_TYPE.ACTION,
  className: '',
  title: '',
  lblOK: 'OK',
  lblCancel: 'Cancel',
  onOKHandled: () => {},
  isOKDisabled: false,
  isFooter: true,
  canICancel: false,
  isSaving: false,
};

export default ActionPopup;
