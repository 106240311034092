const env = process.env.ENV || 'development';

const configs = {
  development: {
    DEV: true,
    GA4: 'G-82CZNJVJGL',
    index: {
      main_video_src:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/video/en/intro_video.mp4',
      main_video_thumb:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/video/en/intro_video_thumbnail.png',
      key_1_src:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/video/en/features_1_inference.mp4',
      key_1_thumb:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/video/en/features_1_inference_thumbnail.png',
      key_2_src:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/video/en/features_2_activity_tracing.mp4',
      key_2_thumb:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/video/en/features_2_activity_tracing_thumbnail.png',
      key_3_src:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/video/en/features_3_context_hopping.mp4',
      key_3_thumb:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/video/en/features_3_context_hopping_thumbnail.png',
    },
    AWSStaticURL:
      'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static',
    ENTITY_TEMPLATE: {
      LITERAL:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/entity_template/mindx_sample_template_literal_entities.csv',
      REGEX:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/dev/static/entity_template/mindx_sample_template_regex_entities.csv',
    },
  },
  // staging: {
  //   api: 'https://api.staging.com',
  // },
  production: {
    DEV: false,
    GA4: 'G-652ER6YH4F',
    index: {
      main_video_src:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/video/en/intro_video.mp4',
      main_video_thumb:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/video/en/intro_video_thumbnail.png',
      key_1_src:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/video/en/features_1_inference.mp4',
      key_1_thumb:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/video/en/features_1_inference_thumbnail.png',
      key_2_src:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/video/en/features_2_activity_tracing.mp4',
      key_2_thumb:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/video/en/features_2_activity_tracing_thumbnail.png',
      key_3_src:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/video/en/features_3_context_hopping.mp4',
      key_3_thumb:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/video/en/features_3_context_hopping_thumbnail.png',
    },
    AWSStaticURL:
      'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static',
    ENTITY_TEMPLATE: {
      LITERAL:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/entity_template/mindx_sample_template_literal_entities.csv',
      REGEX:
        'https://mind-expression.s3.ap-northeast-2.amazonaws.com/prod/static/entity_template/mindx_sample_template_regex_entities.csv',
    },
  },
}[env];

export default configs;
