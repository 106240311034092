import React from 'react';

const DropdownDivider = ({ option }) => (
  <li className="dropdown-divider">
    <span>{option.label}</span>
    <style jsx>
      {`
        @use 'sass:math';
        @import './src/sass/_vars.scss';
        @import './src/sass/_mixins.scss';

        .dropdown-divider {
          position: relative;
          padding: 4px 3px 13px;
          @include font(12px, 600, -0.3px, 16px, rgba($black, 0.4));
          span {
            display: inline-block;
            vertical-align: top;
          }
          &:before {
            position: absolute;
            bottom: 4px;
            left: 3px;
            right: 3px;
            content: '';
            display: block;
            height: 1px;
            background-color: #eaedef;
          }
        }
      `}
    </style>
  </li>
);

export default DropdownDivider;
