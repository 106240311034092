import React, { useEffect, useState } from 'react';
import NProgress from 'nprogress';
import Router, { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { builder, setLoadingBuilder } from '@js/store/slices/builderSlice';
import { LOADING_STATUS } from '@js/constants';

/* eslint-disable react/prefer-stateless-function */
const NextNProgress = ({
  color,
  height,
  startPosition,
  stopDelayMs,
  options,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { loadingBuilder, hideProgress } = useSelector(builder);
  const { pathname, asPath } = router;
  let timer = null;

  // Routing format
  const filtered = [
    '/builder/scopes/[scopeId]/subject/[subjectId]',
    '/builder/knowledge',
    '/builder/scopes/[scopeId]/conversation-history',
    '/builder/scopes/[scopeId]/conversation-history/[convId]',
  ];

  // regex subject detail /\/builder\/scopes\/[0-9a-zA-Z_-]{22}\/subject\/[0-9a-zA-Z_-]{22}/g
  // regex knowledge /\/builder\/knowledge?page=1/g
  // regex conversation history /\/builder\/scopes\/[0-9a-zA-Z_-]{22}\/conversation-history/g
  // regex history detail /\/builder\/scopes\/[0-9a-zA-Z_-]{22}\/conversation-history\/[0-9a-zA-Z_-]{22}/g
  const regexFiltered = [
    '^/builder/scopes/[0-9a-zA-Z_-]{22}/subject/[0-9a-zA-Z_-]{22}$',
    '^/builder/scopes/[0-9a-zA-Z_-]{22}/subject/[0-9a-zA-Z_-]{22}\\?[a-zA-Z0-9=&%-_]*$',
    '^/builder/knowledge\\?[a-zA-Z0-9=&%-_]*$',
    '^/builder/scopes/[0-9a-zA-Z_-]{22}/conversation-history\\?[a-zA-Z0-9=&%-_]*$',
    '^/builder/scopes/[0-9a-zA-Z_-]{22}/conversation-history/([0-9a-zA-Z_-]{22}|[0-9a-zA-Z_-]{36})\\?[a-zA-Z0-9=&%-_]*$',
  ];
  const forceNoStart = [
    '^/builder/knowledge$',
    '^/builder/scopes/[0-9a-zA-Z_-]{22}/conversation-history/([0-9a-zA-Z_-]{22}|[0-9a-zA-Z_-]{36})',
  ];
  const REGEX_JOINED = new RegExp(regexFiltered.join('|'), 'g');
  const NO_END_REGEX_JOINED = new RegExp(forceNoStart.join('|'), 'g');

  const routeChangeStart = (url) => {
    dispatch(setLoadingBuilder(LOADING_STATUS.LOADING));
  };

  const routeChangeEnd = (url, opt) => {
    const matched = url.match(REGEX_JOINED);
    if (!matched || matched?.length < 1) {
      const more = url.match(NO_END_REGEX_JOINED);
      if (!more || more?.length < 1) {
        dispatch(setLoadingBuilder(LOADING_STATUS.END));
      }
    }
  };

  const routeChangeForceEnd = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      NProgress.done(true);
    }, stopDelayMs);
  };

  useEffect(() => {
    if (options) {
      NProgress.configure(options);
    }

    const matched = asPath.match(REGEX_JOINED);
    if (matched?.length > 0) {
      dispatch(setLoadingBuilder(LOADING_STATUS.LOADING));
    }

    Router.events.on('routeChangeStart', routeChangeStart);
    Router.events.on('routeChangeComplete', routeChangeEnd);
    Router.events.on('routeChangeError', routeChangeForceEnd);

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart);
      Router.events.off('routeChangeComplete', routeChangeEnd);
      Router.events.off('routeChangeError', routeChangeForceEnd);
    };
  }, []);

  useEffect(() => {
    if (!hideProgress) {
      if (loadingBuilder === LOADING_STATUS.LOADING) {
        NProgress.set(startPosition);
        NProgress.start();
      }

      if (loadingBuilder === LOADING_STATUS.END) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          NProgress.done(true);
        }, stopDelayMs);
      }
    }
  }, [loadingBuilder, hideProgress]);

  return (
    <>
      <style jsx global>
        {`
          #nprogress {
            pointer-events: none;
            display: ${hideProgress ? 'none' : 'block'} !important;
          }
          #nprogress .bar {
            background: ${color};
            position: fixed;
            z-index: 99999999999;
            top: 0;
            left: 0;
            width: 100%;
            height: ${height}px;
          }
          #nprogress .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            //box-shadow: 0 0 10px ${color}, 0 0 5px ${color};
            opacity: 1;
            -webkit-transform: rotate(3deg) translate(0px, -4px);
            -ms-transform: rotate(3deg) translate(0px, -4px);
            transform: rotate(3deg) translate(0px, -4px);
          }
          #nprogress .spinner {
            display: none;
            position: fixed;
            z-index: 1031;
            top: 15px;
            right: 15px;
          }
          #nprogress .spinner-icon {
            width: 18px;
            height: 18px;
            box-sizing: border-box;
            border: solid 2px transparent;
            border-top-color: ${color};
            border-left-color: ${color};
            border-radius: 50%;
            -webkit-animation: nprogresss-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
          }
          .nprogress-custom-parent {
            overflow: hidden;
            position: relative;
          }
          .nprogress-custom-parent #nprogress .spinner,
          .nprogress-custom-parent #nprogress .bar {
            position: absolute;
          }
          @-webkit-keyframes nprogress-spinner {
            0% {
              -webkit-transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          @keyframes nprogress-spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </>
  );
};

/* class NextNProgress extends React.Component {
  static defaultProps = {
    color: '#F7B716',
    startPosition: 0,
    stopDelayMs: 200,
    height: 4,
  };

  timer = null;

  routeChangeStart = () => {
    NProgress.set(this.props.startPosition);
    NProgress.start();
  };

  routeChangeEnd = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      NProgress.done(true);
    }, this.props.stopDelayMs);
  };

  render() {
    const { color, height } = this.props;
    return (
      <style jsx global>{`

      `}</style>);
  }

  componentDidMount() {
    const { options } = this.props;

    if (options) {
      NProgress.configure(options);
    }

    // window.onbeforeunload = function() {
    window.onload = function() {
      this.routeChangeStart();
      //return '';
    }.bind(this);

    this.routeChangeEnd();

    Router.events.on('routeChangeStart', this.routeChangeStart);
    Router.events.on('routeChangeComplete', this.routeChangeEnd);
    Router.events.on('routeChangeError', this.routeChangeEnd);
  }

  componentWillUnmount() {
    this.routeChangeEnd();
  }
} */

NextNProgress.propTypes = {
  color: PropTypes.string,
  startPosition: PropTypes.number,
  stopDelayMs: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.object,
};

NextNProgress.defaultProps = {
  color: '#F7B716',
  startPosition: 0,
  stopDelayMs: 200,
  height: 4,
  options: {},
};

export default NextNProgress;
