import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '@context/UseAuth';
import { NAV_PAGE, URLS } from '@js/constants';
import GlobalLinks, { menus } from './GlobalLinks';
import MenuLinks from './MenuLinks';
import DocumentLinks from './DocumentLinks';

const GlobalNavPages = ({
  t,
  isMenuAni,
  setCurrentNavView,
  getCurrentNavView,
}) => {
  const navRef = useRef();
  const { user } = useAuth();

  const onSolutionsLinkClicked = () => {
    setCurrentNavView(NAV_PAGE.SOLUTIONS);
  };

  const onUseCasesLinkClicked = () => {
    setCurrentNavView(NAV_PAGE.USE_CASES);
  };

  const onResourcesLinkClick = () => {
    setCurrentNavView(NAV_PAGE.RESOURCES);
  };

  return (
    <div className="nav-pages">
      <ul
        style={{ left: getCurrentNavView() === NAV_PAGE.GLOBAL ? 0 : '-100%' }}
      >
        <li className="nav-page">
          <nav ref={navRef}>
            <GlobalLinks
              t={t}
              onResourcesLinkClick={onResourcesLinkClick}
              onSolutionsLinkClicked={onSolutionsLinkClicked}
              onUseCasesLinkClicked={onUseCasesLinkClicked}
              isMenu
            />
            {user && (
              <a
                href="/builder"
                className="btn-builder"
                id="btn-builder"
                title="Go to Builder"
              >
                Go to Builder
              </a>
            )}
          </nav>
        </li>
        <li
          className={`nav-page sub ${NAV_PAGE.SOLUTIONS.toLowerCase()}`}
          style={{
            display:
              getCurrentNavView() === NAV_PAGE.SOLUTIONS ? 'block' : 'none',
          }}
        >
          <MenuLinks
            title="Solutions"
            menus={[
              // { title: 'By Solution', heading: true },
              {
                title: 'HLI-X',
                href: '/solutions/hli-x',
              },
              {
                title: 'HLI-Automate',
                href: '/solutions/hli-automate',
              },
              // { title: 'By Use Case', heading: true },
              // {
              //   title: 'Customer Support Automation',
              //   href: '/solutions/customer-support-automation',
              // },
              // {
              //   title: 'Product Search and Order',
              //   href: '/solutions/product-search-and-order',
              // },
              // {
              //   title: 'Marketing and Sales',
              //   href: '/solutions/marketing-and-sales',
              // },
              // { title: 'Food Ordering', href: '/use-cases/food-ordering' },
              // { title: 'By Industry', heading: true },
              // { title: 'eCommerce', href: '/use-cases/ecommerce' },
              // {
              //   title: 'Telecommunications',
              //   href: '/use-cases/telecommunications',
              // },
            ]}
          />
        </li>
        <li
          className={`nav-page sub ${NAV_PAGE.RESOURCES.toLowerCase()}`}
          style={{
            display:
              getCurrentNavView() === NAV_PAGE.RESOURCES ? 'block' : 'none',
          }}
        >
          <MenuLinks
            title="Resources"
            subMenuTitle={{
              title: 'Documentation',
              href: URLS.DOCS,
              target: '_blank',
            }}
            menus={[{ title: 'Tutorials', heading: true }, ...menus.resources]}
          />
        </li>
        <li
          className={`nav-page sub ${NAV_PAGE.DOCS.toLowerCase()}`}
          style={{
            display: getCurrentNavView() === NAV_PAGE.DOCS ? 'block' : 'none',
          }}
        >
          <DocumentLinks isMenuAni={isMenuAni} />
        </li>
      </ul>
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          nav {
            position: relative;
            height: 100%;
            transition: all 0.15s linear;
            transform: translateY(0%);
            overflow: auto;
          }

          nav.ani {
            transition: all 0.5s;
            transform: translateY(0);
            opacity: 1;
            transition-delay: 0.25s;
          }

          .nav {
            &-pages {
              position: relative;
              width: 100%;
              height: 100%;
              font-size: 0px;
              overflow-x: hidden;
              ul {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                transition: left 0.5s;
              }
            }

            &-page {
              position: absolute;
              padding-top: 64px;
              left: 0;
              display: inline-block;
              width: 100%;
              height: 100%;
              vertical-align: top;
              @include font(18px, 400, -0.4px, 30px, $black);

              &.sub {
                left: 100%;
                overflow: hidden;
                nav {
                  opacity: 1;
                  padding-left: 0;
                  padding-right: 0;
                  height: 100%;
                  overflow-y: auto;
                }
              }
            }
          }

          #btn-builder {
            display: inline-block;
            width: 100%;
            @include font(14px, 700, -0.32px, 44px, $primary);
            text-align: left;
            height: 44px;
            padding-left: 24px;
            padding-right: 16px;

            @media (hover: hover) {
              &:hover {
                color: $primary_hover;
              }
            }

            &:active {
              color: $primary_hover;
            }
          }

          @media all and (min-width: $resolution_d_min) {
            nav,
            nav.ani {
              height: 40px;
              transition: none;
              transform: translateY(0%);
              opacity: 1;
              overflow: hidden;
              padding-right: 0;
            }

            #btn-builder {
              float: right;
              line-height: 40px;
              padding: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

GlobalNavPages.propTypes = {
  t: PropTypes.func.isRequired,
  setCurrentNavView: PropTypes.func.isRequired,
  getCurrentNavView: PropTypes.func.isRequired,
};

GlobalNavPages.defaultProps = {};

export default GlobalNavPages;
