import React, {
  Component,
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';

import { WARNING_POPUP_TYPE } from '@js/constants';
import { Portal } from 'react-portal';

const WarningPopup = ({
  className,
  type,
  title,
  content,
  lblOK,
  lblCancel,
  onOKHandled,
  onCancelHandled,
}) => {
  const localLblOK = lblOK || 'OK';
  const localLblCancel = lblCancel || 'Cancel';
  const [isAni, setIsAni] = useState(false);
  const ref = useRef();

  const onOKClicked = () => {
    if (onOKHandled) {
      onOKHandled();
    }
  };

  const onCancelClicked = useCallback(() => {
    if (onCancelHandled) {
      onCancelHandled();
    }
  }, [onCancelHandled]);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onCancelClicked();
      }
    },
    [ref, onCancelClicked]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event?.key === 'Escape') {
        onCancelClicked();
      }
    },
    [ref, onCancelClicked]
  );

  const onClickContainer = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onCancelClicked();
    }
  };

  useEffect(() => {
    // document.addEventListener('mousedown', handleClickOutside, true);
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      // document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        setIsAni(true);
      }, 0);
    }
  }, [ref]);

  return (
    <Portal>
      <div
        className={`popup-default ex-warning-popup ${type.toLowerCase()} ${className} ${
          isAni ? 'open' : ''
        }`}
      >
        <div className="popup-container" onMouseDown={onClickContainer}>
          <div className="popup-wrapper">
            <div className="popup-content">
              <div ref={ref} className="content-wrapper">
                {title && <div className="popup-header">{title}</div>}
                <div className="popup-body">{content}</div>
                <div className="popup-footer">
                  {type === WARNING_POPUP_TYPE.CONFIRM && (
                    <button
                      type="button"
                      tabIndex={0}
                      className="grey btn-cancel"
                      onClick={onCancelClicked}
                      title={localLblCancel}
                    >
                      {localLblCancel}
                    </button>
                  )}
                  <button
                    type="button"
                    tabIndex={0}
                    className="btn-ok"
                    onClick={onOKClicked}
                    title={localLblOK}
                  >
                    {localLblOK}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx global>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .ex-warning-popup.popup-default {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100vw;
            height: 100vh;
            z-index: 100000;
            overflow: auto;
            background: rgba(43, 44, 45, 0.5);
            -webkit-overflow-scrolling: touch;

            &.open {
              .popup {
                &-wrapper {
                  opacity: 1;
                  transform: translateY(0);
                }
              }
            }

            .popup {
              &-container {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                min-width: 100%;
                min-height: 100%;
              }

              &-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: table;
                opacity: 0;
                transform: translateY(-30px);
                transition: transform 0.5s, opacity 0.3s;
              }

              &-content {
                display: table-cell;
                vertical-align: middle;
                padding: 24px 0;
                height: 100%;

                .content-wrapper {
                  position: relative;
                  margin: 0 auto;
                  width: 384px;
                  border-radius: 2px;
                  display: flex;
                  flex-direction: column;
                  background: $white;
                  padding: 40px 16px 32px;
                }
              }

              &-header {
                font-weight: 700;
                padding: 0 8px;
                text-align: left;
              }

              &-body {
                padding: 0 8px;
                margin-top: 16px;
              }

              &-footer {
                margin-top: 40px;
                button {
                  display: inline-block;
                  vertical-align: top;
                  width: calc((100% - 24px) / 2);
                  font-weight: 600;

                  &:last-child {
                    margin-left: 24px;
                  }
                }

                .btn-ok {
                }
              }
            }

            &.ok {
              .popup {
                &-footer {
                  text-align: right;
                }
              }
            }

            &.negative {
              .popup {
                &-footer {
                  .btn-ok {
                    background: $negative;
                    border-color: $negative;
                    color: $white;

                    @media (hover: hover) {
                      &:hover {
                        background: $negative_hover;
                        border-color: $negative_hover;
                      }
                    }

                    &:active {
                      background: $negative_hover;
                      border-color: $negative_hover;
                    }
                  }
                }
              }
            }

            &.positive {
              .popup {
                &-footer {
                  .btn-ok {
                    background: $primary;
                    border-color: $primary;
                    color: $white;

                    @media (hover: hover) {
                      &:hover {
                        background: $primary_hover;
                        border-color: $primary_hover;
                      }
                    }

                    &:active {
                      background: $primary_hover;
                      border-color: $primary_hover;
                    }
                  }
                }
              }
            }

            @media all and (min-width: $resolution_d_min) {
              .popup {
                &-container {
                  min-width: $resolution_d_min;
                  min-height: 400px;
                }
                &-content {
                  .content-wrapper {
                    padding: 32px 24px;
                  }
                }
                &-header {
                  padding: 0px;
                  font-size: 18px;
                  line-height: 30px;
                }
                &-body {
                  font-size: 18px;
                  line-height: 30px;
                  padding: 0px;
                }
                &-footer {
                  text-align: right;
                  button {
                    width: 120px;
                    &:last-child {
                      margin-left: 16px;
                    }
                  }
                }
              }
            }
          }
        `}
      </style>
    </Portal>
  );
};

WarningPopup.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  lblOK: PropTypes.string,
  lblCancel: PropTypes.string,
  onOKHandled: PropTypes.func,
  onCancelHandled: PropTypes.func,
};

WarningPopup.defaultProps = {
  className: '',
  title: '',
  lblOK: 'OK',
  lblCancel: 'Cancel',
  onOKHandled: () => {},
  onCancelHandled: () => {},
};

export default WarningPopup;
