import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Feedback from '@components/common/Feedback';
import { withTranslation } from 'next-i18next';
import ActionPopup from '@components/builder/subject/ActionPopup';
import { useAcademy } from '@context/UseAcademy';
import useClickOutside from '@js/useClickOutside';
import DatePicker from './DatePicker';

/**
 *
 * @param t
 * @param label
 * @param htmlFor
 * @param isInvalid
 * @param errMsg
 * @param optional
 * @param feedback
 * @param className
 * @param style
 * @param options
 * @param autoFocus
 * @function onExpandClicked
 *   Support only for options.type is textarea
 *   To check can open the popup or not
 * @function onExpandCanceled
 *   Callback after close the expand popup
 * @returns {JSX.Element}
 * @constructor
 */
const LabelInputWrapper = ({
  t,
  label,
  popupTitle,
  popupLabel,
  htmlFor,
  isInvalid,
  errMsg,
  optional,
  feedback,
  className,
  style,
  formControlOptions: options,
  autoFocus,
  onExpandClicked,
  showExpandButton = true,
  onExpandCanceled,
  errorMessages,
  feedbackClassName,
  isRemoveAll,
  length,
  subLabel = '',
  scrollToView = true,
  lengthType = 'char',
  testId,
  isBlockedEdit = false,
}) => {
  const ref = useRef();
  const { isAcademyMode } = useAcademy() || {};
  const [value, setValue] = useState(options.value);
  const [isErrorValue, setIsErrorValue] = useState(isInvalid);
  const [isExpandPopup, setIsExpandPopup] = useState(false);
  const [popupText, setPopupText] = useState('');
  const [errList, setErrList] = useState(null);
  const [errFocus, setErrFocus] = useState([]);
  const [isPopupTextAutoFocus, setIsPopupTextAutoFocus] = useState(false);
  const [isPopupTextUpdated, setIsPopupTextUpdated] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleClickOutside = () => {
    setShowCalendar(false);
  };

  const targetRef = useClickOutside(handleClickOutside);

  const onFieldChanged = (event) => {
    if (isBlockedEdit) {
      return;
    }
    setValue(event.target.value);
    setIsErrorValue(false);
    options.onChange(event.target.value);
  };

  const onBtnExpandClicked = () => {
    const callback = () => {
      setIsExpandPopup(!isExpandPopup);
      setIsPopupTextAutoFocus(true);
      setPopupText(value);
    };
    onExpandClicked(callback);
  };

  const onPopupTextChanged = (value) => {
    setPopupText(value);
    setIsPopupTextUpdated(true);
    if (errList?.popupText?.required) {
      const err = errList;
      delete err.popupText;
      setErrList(err);
    }
  };

  const onExpandPopupOKClicked = () => {
    if (options.required && popupText.trim().length <= 0) {
      const err = {
        popupText: {
          required: true,
          errMsg: errorMessages?.required,
        },
      };
      const focusValue = ['popupText'];
      setErrList({ ...err });
      setTimeout(() => {
        setErrFocus([...focusValue]);
      }, 0);
    } else {
      const newValue = popupText.slice();
      if (
        length > 0 &&
        (lengthType === 'char'
          ? newValue.length > length
          : new TextEncoder().encode(newValue).length > length)
      ) {
        const focusValue = ['popupText'];
        const err = errList;
        setErrList({ ...err });
        setTimeout(() => {
          setErrFocus([...focusValue]);
        }, 0);
        return;
      }

      setValue(newValue);
      setIsErrorValue(false);
      options.onChange(newValue);
      setIsExpandPopup(false);
      // onExpandPopupCancelClicked();
    }
  };

  const onExpandPopupCancelClicked = () => {
    setIsExpandPopup(false);
    setPopupText('');
    setErrList({});
    setErrFocus([]);
    setIsPopupTextAutoFocus(false);
    if (typeof onExpandCanceled === 'function') {
      onExpandCanceled();
    }
  };

  const onClear = () => {
    setValue('');
    setIsErrorValue(false);
    options.onChange('');
    if (ref?.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (options.value.length <= 0) {
      setValue('');
    } else {
      setValue(options.value);
    }
  }, [options.value]);

  useEffect(() => {
    setIsErrorValue(isInvalid);
  }, [isInvalid]);

  useEffect(() => {
    if (!isExpandPopup) {
      setPopupText('');
      setErrList({});
      setErrFocus([]);
      setIsPopupTextAutoFocus(false);
    }
  }, [isExpandPopup]);

  useEffect(() => {
    if (autoFocus && ref && ref.current) {
      const { current } = ref;

      setTimeout(() => {
        if (current.value?.length > 0) {
          current.selectionStart = current.selectionEnd = current.value.length;
        }
        current.focus();
        if (options.type && scrollToView) {
          const opts = {
            block: 'center',
          };
          current.scrollIntoView(opts);
        }
      }, 100);
    }
  }, [autoFocus, ref]);

  useEffect(
    () => () => {
      setErrFocus([]);
      setIsPopupTextAutoFocus(false);
    },
    [errList]
  );

  useEffect(
    () => () => {
      setIsPopupTextUpdated(false);
    },
    [isExpandPopup]
  );

  return (
    <>
      <label
        className={`lbl-container ${className} ${
          isRemoveAll && !options.readOnly ? 'has-remove' : ''
        } ${!label ? 'no-label' : ''} ${isErrorValue ? 'error' : ''}`}
        htmlFor={htmlFor}
        data-testid={`${htmlFor}-block`}
        style={style}
      >
        <div className="lbl-wrapper">
          <div className="input-wrapper">
            {isRemoveAll && !options.readOnly && value && (
              <button className="btn-delete" onClick={onClear} title="Clear" />
            )}
            {['text', 'email', 'password', 'tel'].includes(options.type) ? (
              <>
                <input
                  type={
                    isPasswordShow && options.type === 'password'
                      ? 'text'
                      : options.type
                  }
                  className={`${options.className} ${
                    isErrorValue ||
                    (length > 0 &&
                      (lengthType === 'char'
                        ? value.length > length
                        : new TextEncoder().encode(value).length > length))
                      ? 'error'
                      : ''
                  }`}
                  id={options.id}
                  value={value}
                  onChange={onFieldChanged}
                  {...{
                    ref: options.ref ? options.ref : ref,
                    name: options.name,
                    placeholder: options.placeholder,
                    minLength: options.minLength,
                    maxLength: options.maxLength,
                    // required: options.required,
                    disabled: options.disabled,
                    readOnly: options.readOnly,
                    onClick: options.onClick,
                    onFocus: options.onFocus,
                    onBlur: options.onBlur,
                    onKeyDown: options.onKeyDown,
                    onKeyUp: options.onKeyUp,
                  }}
                  autoComplete="off"
                  data-testid={testId}
                />
                {options.type === 'password' && (
                  <i
                    className={`password_icon ${
                      isPasswordShow ? 'password_show' : 'password_hide'
                    }`}
                    onClick={() => {
                      setIsPasswordShow(!isPasswordShow);
                    }}
                  />
                )}
              </>
            ) : options.type === 'textarea' ? (
              <textarea
                id={options.id}
                className={`${options.className} ${
                  isErrorValue ||
                  (length > 0 &&
                    (lengthType === 'char'
                      ? value.length > length
                      : new TextEncoder().encode(value).length > length))
                    ? 'error'
                    : ''
                }`}
                value={value}
                onChange={onFieldChanged}
                {...{
                  ref: options.ref ? options.ref : ref,
                  name: options.name,
                  placeholder: options.placeholder,
                  minLength: options.minLength,
                  maxLength: options.maxLength,
                  // required: options.required,
                  disabled: options.disabled,
                  readOnly: options.readOnly,
                  style: options.style,
                  onClick:
                    onExpandClicked && !showExpandButton
                      ? () => onBtnExpandClicked()
                      : options.onClick,
                  onFocus: options.onFocus,
                  onBlur: options.onBlur,
                  onKeyDown: options.onKeyDown,
                  onKeyUp: options.onKeyUp,
                }}
                data-testid={testId}
              />
            ) : options.type === 'date' ? (
              <>
                <div className="text-center relative" ref={targetRef}>
                  <input
                    type="text"
                    className={`${options.className} ${
                      isErrorValue ||
                      (length > 0 &&
                        (lengthType === 'char'
                          ? value.length > length
                          : new TextEncoder().encode(value).length > length))
                        ? 'error'
                        : ''
                    }`}
                    id={options.id}
                    value={value}
                    {...{
                      ref: options.ref ? options.ref : ref,
                      name: options.name,
                      placeholder: options.placeholder,
                      minLength: options.minLength,
                      maxLength: options.maxLength,
                      // required: options.required,
                      disabled: options.disabled,
                      readOnly: options.readOnly,
                      onFocus: options.onFocus,
                      onBlur: options.onBlur,
                      onKeyDown: options.onKeyDown,
                      onKeyUp: options.onKeyUp,
                    }}
                    onClick={() => {
                      if (options.onClick) options.onClick();
                      setShowCalendar((prev) => !prev);
                    }}
                    autoComplete="off"
                    data-testid={testId}
                  />
                  <i className="calendar_icon" />

                  {showCalendar && (
                    <div className="calendar-wrapper">
                      <DatePicker
                        value={value}
                        onChange={(day) => {
                          options.onChange(day);
                          setShowCalendar(false);
                        }}
                        disabledDays={options.disabledDays}
                        calendarWidth={312}
                        className="input-wrapper"
                        showPreview
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {label ? (
            <span
              className={`lbl-text ${feedback?.length > 0 ? 'feedback' : ''} ${
                onExpandClicked ? 'expandable' : ''
              }`}
            >
              {label}
              {className.indexOf('lbl-black') < 0 && optional && (
                <span className="optional">
                  ({t('builder:sb-common-lbl-optional')})
                </span>
              )}
              {feedback?.length > 0 && (
                <Feedback
                  style={{
                    marginLeft: '8px',
                    marginTop: '0px',
                  }}
                  className={`${feedbackClassName || ''}`}
                  isDisabled={options.disabled}
                >
                  {feedback}
                </Feedback>
              )}
              {onExpandClicked && (
                <button
                  className="btn-expand-textarea"
                  title="Expand"
                  onClick={onBtnExpandClicked}
                />
              )}
              {subLabel && <div className="mt-1 font-normal">{subLabel}</div>}
            </span>
          ) : (
            <>
              {onExpandClicked &&
                showExpandButton &&
                !options.readOnly &&
                !isAcademyMode && (
                  <div
                    className={`lbl-text no-label ${
                      onExpandClicked ? 'expandable' : ''
                    }`}
                  >
                    <button
                      className="btn-expand-textarea"
                      title="Expand"
                      onClick={onBtnExpandClicked}
                    />
                  </div>
                )}
            </>
          )}
          {className.indexOf('lbl-black') > -1 && optional && (
            <div className="optional bold">
              ({t('builder:sb-common-lbl-optional')})
            </div>
          )}
        </div>
        {(length > 0 ||
          (isErrorValue && !options.disabled && errMsg?.length > 0)) && (
          <div className="lbl-extra-container">
            {isErrorValue && !options.disabled && errMsg?.length > 0 ? (
              <div className="lbl-error">{errMsg}</div>
            ) : (
              <div className="lbl-error" />
            )}
            {length > 0 && (
              <div
                data-testid={`${options.id}-count`}
                className={`lbl-count ${
                  (
                    lengthType === 'char'
                      ? value.length > length
                      : new TextEncoder().encode(value).length > length
                  )
                    ? 'error'
                    : ''
                }`}
              >
                {lengthType === 'char'
                  ? value.length
                  : new TextEncoder().encode(value).length}
                /{length} {lengthType === 'char' ? '' : 'bytes'}
              </div>
            )}
          </div>
        )}
        {isExpandPopup && (
          <ActionPopup
            className={`popup-textarea-expand ${
              label ? '' : popupLabel ? '' : 'no-label-has-title'
            } ${length > 0 ? 'has-count' : ''}`}
            size="LARGE"
            lblOK={t('builder:sb-textarea-expand-lbl-btn-ok')}
            lblCancel={t('builder:sb-textarea-expand-lbl-btn-cancel')}
            canICancel={!isPopupTextUpdated}
            onOKHandled={onExpandPopupOKClicked}
            onCancelHandled={onExpandPopupCancelClicked}
            title={popupTitle || ''}
          >
            <div
              className={`popup-expand-content ${
                popupLabel ? 'has-label' : ''
              } `}
            >
              <LabelInputWrapper
                t={t}
                key={`popup-${htmlFor}`}
                label={popupLabel || label}
                optional={optional}
                htmlFor={`popup-${htmlFor}`}
                className={className}
                feedback={feedback}
                isInvalid={errList?.popupText?.required}
                errMsg={errList?.popupText?.errMsg}
                length={length}
                lengthType={lengthType}
                formControlOptions={{
                  ...options,
                  onClick: () => {},
                  onChange: onPopupTextChanged,
                  readOnly: false,
                  style: {
                    height: '320px',
                    minHeight: 'auto',
                  },
                }}
                autoFocus={
                  isPopupTextAutoFocus || errFocus?.includes('popupText')
                }
              />
            </div>
          </ActionPopup>
        )}
        <style jsx>
          {`
            @import './src/sass/_vars.scss';
            @import './src/sass/_mixins.scss';

            .lbl-container {
              display: inline-block;
              vertical-align: top;
            }

            .lbl-black {
              .lbl-text {
                color: $black;
                font-weight: 600;
              }
            }

            .lbl-text {
              &.feedback {
                padding-right: 22px;
              }

              &.expandable {
                padding-right: 40px;
              }

              &.feedback.expandable {
                padding-right: 60px;
              }

              &.no-label {
                height: 28px;
                &.expandable {
                  height: 20px;
                }
              }
            }

            .no-label {
              input[type='text'],
              input[type='tel'] {
                margin-top: 0;
              }
            }

            .input-wrapper {
              position: relative;
            }

            .password_icon {
              cursor: pointer;
              width: 24px;
              height: 24px;
              display: inline-block;
              position: absolute;
              top: 16px;
              right: 12px;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              &.password_hide {
                background-image: url('#{$image_url}/icons/ic_password_hide.png');
              }
              &.password_show {
                background-image: url('#{$image_url}/icons/ic_password_show.svg');
              }
            }

            .calendar_icon {
              cursor: pointer;
              width: 16px;
              height: 16px;
              display: inline-block;
              position: absolute;
              top: 9px;
              right: 9px;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              background-image: url('#{$image_url}/icons/ic_calendar.png');
            }

            .calendar-wrapper {
              position: absolute;
              background-color: #ffffff;
              z-index: 9;
              text-align: left;
              width: 100%;
              top: 36px;
              border: 1px solid #cbd1d7;
              max-width: 310px;
              padding: 10px;
            }

            .btn-expand-textarea {
              width: 24px;
              height: 24px;
              display: inline-block;
              position: absolute;
              right: 0;
              bottom: -4px;
              background: url('#{$image_url}/bt_expand_txt_field/normal@2x.png')
                center center no-repeat;
              background-size: cover;
              border: none;
              overflow: visible;

              &:hover {
                background-image: url('#{$image_url}/bt_expand_txt_field/hover@2x.png');
              }
            }

            .popup-expand-content {
              padding-top: 32px;

              &.has-label {
                padding-top: 32px !important;
              }
            }

            .btn-delete {
              position: absolute;
              top: 8px;
              bottom: 0;
              right: 4px;
              width: 24px;
              height: 24px;
              margin: auto 0;
              background: transparent
                url('#{$image_url}/bt_close/normal@2x.png') center center / 24px
                24px no-repeat;
              border: none;
              z-index: 2;
              &:hover {
                background-image: url('#{$image_url}/bt_close/hover@2x.png');
                & + input {
                  border-color: $black;
                }
              }
            }

            .has-remove {
              input {
                padding-right: 28px;
              }
            }

            .lbl-extra-container {
              position: relative;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding-top: 4px;

              .lbl-error {
                flex: 1;
                margin-top: 0;
              }

              .lbl-count {
                margin-left: 16px;
                @include font(12px, 400, -0.3px, 16px, rgba($black, 0.4));

                &.error {
                  color: #ff3535;
                }
              }
            }
          `}
        </style>
        <style jsx global>
          {`
            @import './src/sass/_vars.scss';
            @import './src/sass/_mixins.scss';

            .builder-action-popup.popup-textarea-expand {
              .popup-footer {
                padding-top: 40px;
              }

              &.no-label-has-title {
                .popup-header {
                  padding-bottom: 8px;
                }

                .popup-expand-content {
                  padding-top: 0px;
                }
              }

              &.has-count {
                .popup-footer {
                  padding-top: 20px;
                }
              }
            }

            .calendar-wrapper {
              .input-wrapper {
                .range-calendar {
                  .rdp-head_row {
                    .rdp-head_cell {
                      max-width: 40px;
                      max-height: 40px;
                      min-width: 30px;
                      min-height: 30px;
                      height: 100%;
                      width: 100%;
                      aspect-ratio: 1 / 1;
                      padding: 8px 4px;
                    }
                  }

                  .rdp-tbody {
                    .rdp-row {
                      .rdp-cell {
                        max-width: 40px;
                        max-height: 40px;
                        min-width: 30px;
                        min-height: 30px;
                        height: 100%;
                        aspect-ratio: 1 / 1;

                        width: 100%;

                        .rdp-button {
                          max-height: 40px;
                          max-width: 40px;
                          min-width: 30px;
                          min-height: 30px;
                          height: 100%;
                          width: 100%;
                          aspect-ratio: 1 / 1;
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
        </style>
      </label>
    </>
  );
};

LabelInputWrapper.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subLabel: PropTypes.string,
  popupTitle: PropTypes.string,
  popupLabel: PropTypes.string,
  htmlFor: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  formControlOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  optional: PropTypes.bool,
  feedback: PropTypes.string,
  isInvalid: PropTypes.bool,
  autoFocus: PropTypes.bool,
  errMsg: PropTypes.string,
  isExpand: PropTypes.bool,
  onExpandClicked: PropTypes.func,
  errorMessages: PropTypes.oneOfType([PropTypes.object]),
  length: PropTypes.number,
  lengthType: PropTypes.string,
  testId: PropTypes.string,
  isBlockedEdit: PropTypes.bool,
};

LabelInputWrapper.defaultProps = {
  label: '',
  popupTitle: '',
  popupLabel: '',
  className: '',
  style: {},
  optional: false,
  feedback: '',
  isInvalid: false,
  errMsg: '',
  autoFocus: false,
  isExpand: false,
  onExpandClicked: null,
  errorMessages: {},
  length: 0,
  lengthType: 'char',
  testId: '',
};

export default withTranslation('builder')(LabelInputWrapper);
