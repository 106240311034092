import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Link from 'next/link';
import { withTranslation } from 'next-i18next';
import ToastMessage from '@components/common/ToastMessage';
import { toast, validateEmail } from '@js/helper';
import { API_URLS, YOUTUBE_MIND_AI_PLAYLIST } from '@js/constants';
import { useError } from '@context/UseError';
import ToastContainer from '@components/builder/ToastContainer';
import BuilderToastMessage from '@components/common/BuilderToastMessage';
import { useToast } from '@context/UseToast';

const GlobalFooter = ({ t, className, path }) => {
  const { toastList } = useToast();
  const EMAIL_MIN_LENGTH = 5;
  const EMAIL_MAX_LENGTH = 100;
  const [subEmail, setSubEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isInvalidForm, setIsInvalidForm] = useState(null);
  const inputRef = useRef();
  const { commonErrorWithoutAuth } = useError();
  const yearOfDate = new Date().getFullYear();

  // useEffect(() => {
  //   if (subEmail.length > 0) {
  //     if (validateEmail(subEmail)
  //       && subEmail.length >= EMAIL_MIN_LENGTH
  //       && subEmail.length <= EMAIL_MAX_LENGTH) {
  //       setIsSubmitDisabled(false);
  //     }
  //   } else {
  //     setIsSubmitDisabled(true);
  //   }
  // }, [subEmail]);

  const onNewsEmailChanged = (event) => {
    const { value, maxLength } = event.target;
    setSubEmail(value);
    setIsInvalidForm(null);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const url = API_URLS.NEWSLETTER_SUBSCRIPTION;

    const checkInvalid = () => {
      if (!subEmail.length) {
        setIsInvalidForm('NO_VALUE_EXIST');
        setTimeout(() => {
          if (inputRef && inputRef?.current) {
            inputRef.current.focus();
          }
        }, 0);
        return true;
      }

      if (!validateEmail(subEmail)) {
        setIsInvalidForm('INVALID_FORM');
        setTimeout(() => {
          if (inputRef && inputRef?.current) {
            inputRef.current.focus();
          }
        }, 0);
        return true;
      }
      return false;
    };

    if (checkInvalid()) {
    } else {
      axios({
        url,
        method: 'Post',
        data: {
          client_email: subEmail,
          is_subscribing: true,
        },
      })
        .then((response) => {
          const { data } = response;
          setSubEmail('');
          setIsSuccess(true);
        })
        .catch((error) => {
          commonErrorWithoutAuth(error);
        });
    }
  };

  useEffect(() => {
    setSubEmail('');
    setIsInvalidForm(null);
  }, [path]);

  return (
    <>
      <footer className={`mind-footer ${className}`} id="mind-footer">
        <ToastContainer
          id="global-toast"
          position={{
            bottom: '40px',
            right: '40px',
          }}
          list={toastList}
          ToastComponent={BuilderToastMessage}
        />
        <div className="px-6 sm:px-8 xl:px-0 container max-w-[1200px] mx-auto">
          <div className="footer-menu-block flex flex-col sm:flex-row items-start justify-between flex-wrap">
            <Link href="/" className="basis-full">
              <div className="footer-logo basis-full lg:basis-auto ml-[-6px] mb-4">
                <img
                  src="/images/img_logo_mind_expression@4x.png"
                  height="32"
                  alt=""
                />
              </div>
            </Link>
            <div className="order-2 sm:order-1 flex justify-between sm:flex-col w-full sm:w-auto">
              <ul className="font-semibold footer-menu-list">
                <li className="info-value company mb-4">
                  <a
                    href="https://about.mind.ai"
                    title="Mind AI"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mind AI
                  </a>
                </li>
                <li className="info-value terms">
                  <a
                    href="/terms-policies"
                    title="Terms & Policies"
                    target="_self"
                  >
                    Terms & Policies
                  </a>
                </li>
              </ul>
              <div className="footer-channels sm:mt-9">
                <ul>
                  <li>
                    <a
                      href={YOUTUBE_MIND_AI_PLAYLIST}
                      title="Youtube"
                      aria-label="Youtube"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/icons/socials/youtube_white.svg"
                        alt="Youtube"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/mind-ai-official/"
                      className="chan-link instagram"
                      title="Instagram"
                      aria-label="Instagram"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/icons/socials/linkedin_white.svg"
                        alt="Youtube"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-row  order-1 sm:order-2  footer-subscribe max-w-[420px] mb-[30px] sm:mb-0">
              <div className="sub-head">
                <div className="head-title">Subscribe to our newsletter</div>
                <div className="head-desc text-sm">
                  Get access to the latest Mind Expression news, enterprise
                  solutions, and resources.
                </div>
              </div>
              <div className="sub-form mt-4">
                <form id="frm-news" onSubmit={onSubmit} noValidate>
                  <div>
                    <div className="flex">
                      <input
                        ref={inputRef}
                        type="email"
                        id="news-email"
                        className={isInvalidForm && 'sub-form-invalid'}
                        name="newsEmail"
                        placeholder="Your work email"
                        maxLength={EMAIL_MAX_LENGTH}
                        minLength={EMAIL_MIN_LENGTH}
                        value={subEmail}
                        onChange={onNewsEmailChanged}
                      />
                      <input
                        type="submit"
                        id="news-submit"
                        className={`${
                          isInvalidForm && 'error'
                        } text-sm sm:text-[16px]`}
                        value="Subscribe"
                        title="Subscribe"
                      />
                    </div>

                    {isInvalidForm === 'NO_VALUE_EXIST' && (
                      <span className="invalid-form">
                        Please enter an email address.
                      </span>
                    )}
                    {isInvalidForm === 'INVALID_FORM' && (
                      <span className="invalid-form">
                        Please enter a valid email address.
                      </span>
                    )}
                  </div>
                </form>
                {isSuccess && (
                  <ToastMessage
                    type="normal"
                    message={
                      "Thank you for subscribing to our newsletter.\nWe'll keep you updated."
                    }
                    onClose={() => setIsSuccess(false)}
                  />
                )}
                {isError && (
                  <ToastMessage
                    type="error"
                    message="Busy... please try again later!"
                    onClose={() => setIsError(false)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="footer-license-block text-left sm:text-right mt-8">
            <div className="footer-license">
              Copyright © {yearOfDate}{' '}
              <a
                href="https://about.mind.ai"
                title="Mind AI"
                target="_blank"
                rel="noreferrer"
              >
                Mind AI
              </a>{' '}
              Inc.
            </div>
          </div>
        </div>
      </footer>
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          $btnWidth: 0;

          footer#mind-footer {
            min-height: 334px;
            color: #464d54;
            background-color: #f2f4f5;
            padding-top: 56px;
            padding-bottom: 64px;
            border-top: 1px solid rgba(235, 237, 239, 1);

            a {
              color: #464d54;

              @media (hover: hover) {
                &:hover {
                  color: rgba(#464d54, 0.6);
                }
              }
              &:active {
                color: rgba(#464d54, 0.6);
              }
            }

            input[type='email'],
            input[type='submit'] {
              height: 54px;
              line-height: 52px;
            }

            input[type='email'] {
              background-color: #ffffff;
              line-height: 30px;
              color: #555f69;
            }

            .info-value.company {
              background: url(/images/ic_new_tab_footer@2x.png) center right
                36px no-repeat;
              background-size: 24px 24px;
            }

            .footer-subscribe {
              display: inline-block;

              .head-title {
                @include font(16px, 700, -0.4px, 30px, #464d54);
                margin-bottom: 8px;
              }
              .head-desc {
                color: #2b2c2d;
              }

              .sub-form {
                > form {
                  display: flex;
                  flex-direction: column;
                }

                &-invalid {
                  border: 1px solid #ff5c5c;
                }

                .invalid-form {
                  display: block;
                  margin-top: 8px;
                  @include font(14px, 600, -0.4px, 16px, #ff5c5c);
                }
              }

              #news-email {
                background-color: transparent;
                font-size: 16px;
                display: inline-block;
                vertical-align: top;
                border-radius: 5px 0 0 5px;
                border-right: none;
                &:not(.sub-form-invalid):hover {
                  border-color: #cbd1d7;
                }
                @media all and (max-width: 768px) {
                  font-size: 14px;
                }

                &::placeholder {
                  font-size: 16px;
                  @media all and (max-width: 768px) {
                    font-size: 14px;
                  }
                }
              }
              #news-submit {
                background-color: transparent;
                font-size: 16px;
                display: inline-block;
                vertical-align: top;
                border-left: transparent;
                border-radius: 0 4px 4px 0;
                border-color: #cbd1d7;
                width: 136px;
                :hover {
                  color: $black;
                }
                &.error {
                  border-color: #ff5c5c;
                }
              }
              @media all and (max-width: 768px) {
                font-size: 14px;
              }
            }

            .footer-channels {
              li {
                display: inline-block;
                vertical-align: top;
                margin-right: 16px;

                &:last-child {
                  margin-right: 0;
                }

                a {
                  display: inline-block;
                  width: 44px;
                  height: 44px;
                  @media all and (max-width: 768px) {
                    width: 30px;
                    height: 30px;
                  }
                }
              }
            }

            .footer-links {
              position: relative;

              li {
                display: inline-block;
                vertical-align: top;
                margin-right: 32px;
                font-weight: 600;
                &:last-child {
                  margin-right: 0;
                }
              }
            }

            .footer-license {
              @include font(16px, 400, -0.3px, 19px, rgba(#464d54, 0.4));
              a {
                @include font(16px, 600, -0.3px, 19px, rgba(#464d54, 0.4));
              }
              @media all and (max-width: 768px) {
                font-size: 14px;
                a {
                  font-size: 14px;
                }
              }
            }
          }

          footer#mind-footer.docs-footer {
            .footer-subscribe {
              display: none;
            }

            .footer-links {
              margin-top: 0;
            }

            @media all and (min-width: 768px) {
              .footer-subscribe {
                display: none;
              }

              .footer-links {
                position: relative;
                top: auto;
                left: auto;
                bottom: auto;
                right: auto;
              }
            }
          }

          footer#mind-footer.start-footer {
            height: 182px;
            min-height: auto;
            padding-top: 40px;
            padding-bottom: 56px;

            .footer-menu-block,
            .footer-license-block {
              justify-content: center;
            }

            .footer-menu-list {
              display: flex;
              justify-content: center;
              width: 100%;
            }

            .footer-license {
              text-align: center;
            }

            .footer-logo,
            .footer-subscribe,
            .footer-channels,
            .footer-links {
              display: none;
              visible: hidden;
            }

            .info-value.company {
              display: none;
            }

            .footer-info {
              margin-top: 0;
              .info-label {
                display: none;
              }
            }
          }
        `}
      </style>
    </>
  );
};

GlobalFooter.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

GlobalFooter.defaultProps = {
  className: '',
};

export default withTranslation('common')(GlobalFooter);
