import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ACADEMY_MODE } from '@js/constants';
import { useAcademy } from '@context/UseAcademy';

const BuilderToastMessage = ({ toast, onClose }) => {
  const toastRef = useRef();
  const [isAni, setIsAni] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const ref = useRef();
  const [count, setCount] = useState(0);
  const { step, isAcademyMode } = useAcademy();

  useEffect(() => {
    let timer1 = null;
    let timer2 = null;
    let timer3 = null;
    if (toast && count > 0) {
      timer1 = setTimeout(() => {
        setIsAni(true);
        timer2 = setTimeout(() => {
          setIsAni(false);
          timer3 = setTimeout(() => {
            onClose();
          }, 1000);
        }, count);
      }, 100);
    }

    return () => {
      // Should clear all timers
      setIsAni(false);
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [toast, count]);

  useEffect(() => {
    if (ref?.current) {
      const { current } = ref;
      const { width, height } = current.getBoundingClientRect();
      const tempCount = Math.round((height - 24) / 16);
      setSize({
        width,
        height,
      });
      setCount((tempCount + 2) * 1000);
      if (toastRef?.current) {
        toastRef.current.style.bottom = `${height * -1}px`;
      }
    }

    return () => {
      setCount(0);
    };
  }, [ref]);

  useEffect(() => {
    if (isAni) {
      if (toastRef?.current) {
        toastRef.current.style.bottom = '0px';
      }
    } else if (size?.height) {
      toastRef.current.style.bottom = `${size?.height * -1}px`;
    }
  }, [isAni]);

  return (
    <>
      {toast && (
        <div key={toast.key}>
          <div
            className={`toast-feedback ${toast?.type || ''} ${
              isAni ? 'ani' : ''
            }`}
            style={{
              width: size.width,
              height: size.height,
            }}
          >
            <div ref={toastRef} className="box-toast" style={{}}>
              <div className="toast-header">
                {toast.type === 'error' && 'Error'}
                {toast.type === 'info' && 'Info'}
                {toast.type === 'confirm' && 'Success'}
              </div>
              <div className="toast-body">{toast.message}</div>
            </div>
          </div>
          <div className={`toast-feedback ${toast?.type || ''} hide-toast`}>
            <div ref={ref} className="">
              <div className="toast-header">
                {toast.type === 'error' && 'Error'}
                {toast.type === 'info' && 'Info'}
                {toast.type === 'confirm' && 'Success'}
              </div>
              <div className="toast-body">{toast.message}</div>
            </div>
          </div>
        </div>
      )}
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .toast-feedback {
            position: fixed;
            top: auto;
            bottom: ${isAcademyMode && step === ACADEMY_MODE.LETS_TEST_SUBJECT
              ? '140px'
              : '40px'};
            right: 40px;
            width: 320px;
            height: auto;
            z-index: 1001;
            overflow: hidden;

            & > div {
              position: absolute;
              right: 0;
              width: 328px;
              height: auto;
              min-height: 40px;
              background: #ffffff url('#{$image_url}/ic_toast/info.png') left
                14px top 14px no-repeat;
              background-size: 12px 12px;
              border: 1px solid $black;
              border-radius: 2px;
              //padding: 12px 40px;
              @include font(12px, 600, -0.3px, 16px, $black);
              text-align: left;
            }

            .box-toast {
              display: flex;
              flex-direction: column;
              transition: bottom 0.5s;
            }

            .toast-header {
              height: 31px;
              background-image: url('#{$image_url}/ic_toast/ic_toast_done@2x.png');
              background-size: 24px 24px;
              background-position: left 3px top 3px;
              background-repeat: no-repeat;
              color: #ffffff;
              padding-left: 29px;
              padding-top: 7px;
              font-weight: 700;
            }

            .toast-body {
              padding: 11px;
            }

            &.error {
              .toast-header {
                background-image: url('#{$image_url}/ic_toast/bt_error_white@2x.png');
                background-color: #ff3535;
              }
            }

            &.info {
              .toast-header {
                background-color: #4e6378;
              }
            }

            &.confirm {
              .toast-header {
                background-color: #66c146;
              }
            }

            &.ani {
              & > .box-toast {
                transition: bottom 0.5s;
              }
            }

            &.hide-toast {
              position: fixed;
              top: -99999px;
              left: -999999px;
              opacity: 0;
            }
          }
        `}
      </style>
    </>
  );
};

BuilderToastMessage.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

BuilderToastMessage.defaultProps = {
  children: '',
};

export default BuilderToastMessage;
