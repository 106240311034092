import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

const ContextMenu = (props) => {
  const { className, style, position, setClose, button, children } = props;
  const [isAni, setIsAni] = useState(false);
  const ref = useRef();

  const handleClickOutside = useCallback(
    (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        button.current &&
        !button.current.contains(event.target)
      ) {
        setClose(false);
      }
    },
    [ref, button, setClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        setIsAni(true);
      }, 0);
    }
  }, [ref]);

  return (
    <div className="context-wrapper">
      <div
        ref={ref}
        style={style}
        className={`context-menu ${className} ${position} ${
          isAni ? 'open' : ''
        }`}
      >
        {children}
      </div>
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .context-wrapper {
            position: relative;
          }

          .context-menu {
            position: absolute;
            right: 0;
            top: 15px;
            width: 158px;
            padding: 0;
            background: #ffffff;
            border: 1px solid #444546;
            opacity: 0;
            border-radius: 0;

            &:before {
              content: '';
              width: 0px;
              height: 0px;
              position: absolute;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 14px solid transparent;
              border-bottom: 14px solid #444546;
              right: 12px;
              top: -28px;
            }

            &:after {
              content: '';
              width: 0px;
              height: 0px;
              position: absolute;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 12px solid transparent;
              border-bottom: 12px solid #ffffff;
              right: 13px;
              top: -22px;
            }

            &.open {
              visibility: visible;
              opacity: 1;
              transition: opacity 0.5s;
            }

            &.right {
              top: 0px;
              bottom: auto;
              right: auto;
              left: calc(100% + 20px);

              &:before {
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-left: 14px solid transparent;
                border-right: 14px solid #444546;
                left: -28px;
                right: auto;
                top: 14px;
              }

              &:after {
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                border-left: 12px solid transparent;
                border-right: 12px solid #ffffff;
                left: -24px;
                right: auto;
                top: 15px;
              }
            }
          }

          .context-menu.mypage-avatar {
            top: -72px;
            width: 192px;
            padding: 16px 0;
          }

          .context-menu.cover {
            &:before,
            &:after {
              display: none;
            }

            // default top-right
            top: -32px;
            right: 0;
            width: auto;
            min-width: 75px;
            padding: 8px;
            border-radius: 2px;

            &.top-left {
              right: auto;
              left: 0px;
            }

            &.bottom-right {
              top: auto;
              bottom: 0px;
            }
          }

          .context-menu.no-arrow {
            &:before,
            &:after {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

ContextMenu.propTypes = {
  className: PropTypes.string,
  position: PropTypes.string,
  setClose: PropTypes.func.isRequired,
  button: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.objectOf(PropTypes.any),
};

ContextMenu.defaultProps = {
  className: '',
  position: 'bottom',
  children: null,
  style: {},
};

export default ContextMenu;
