import React, {
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';
import { useRouter } from 'next/router';
import axios from 'axios';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { useCookies } from 'react-cookie';
import { API_URLS } from '../constants';

const authContext = createContext();

export const useAuth = () => useContext(authContext);

const useProvidedAuth = () => {
  const [isUserCompleted, setIsUserCompleted] = useState(false);
  const [user, setUser] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [token, setToken] = useState(null);
  const { pathname } = useRouter();
  const [authError, setAuthError] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['m_e_t']);
  const option = {
    path: '/',
  };

  const authHeader = () => {
    const cook = new Cookies();
    const getToken = cook.get('m_e_t');

    if (authError) return;

    if (getToken) {
      if (token !== getToken) {
        setToken(getToken);
      }
      return {
        Authorization: `Token ${getToken}`,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      };
    }

    setAuthError(true);

    return null;

    /* return !authError ? (() => {
      document.location.replace('/start');
      window.alert(t('error-auth'));
    })() : null; */
  };

  const setUserToken = (userToken) => {
    if (userToken) {
      setCookie('m_e_t', userToken, option);
      setToken(userToken);
      // axios.defaults.headers.Authorization = `Token ${token}`;
    } else {
      setToken(null);
      removeCookie('m_e_t', option);
      removeCookie('m_e_t', { path: '/' });
      // delete axios.defaults.headers.Authorization;
    }
  };

  const signup = () => {};
  const signout = (callback) => {
    setUser(null);
    setSubscription(null);
    setUserToken(null);
    if (callback) {
      callback();
    }
  };

  const unsubscribe = () => {
    const options = {
      headers: authHeader(),
    };

    const fetch = async () => {
      try {
        const result = await axios.get(API_URLS.DEVELOPER, options);
        if (result.data) {
          const subscriptionResult = await axios.get(
            API_URLS.DEVELOPER_SUBSCRIPTION(result.data.developer_unique_key),
            options
          );

          setUser(result.data);
          setSubscription(subscriptionResult.data);
        } else {
          setUser(null);
          setSubscription(null);
          setUserToken(null);
        }
        setIsUserCompleted(true);
      } catch (e) {
        signout();
        setIsUserCompleted(true);
      }
    };

    fetch();
  };

  const authUpdate = () => {
    unsubscribe();
  };

  // Component did mount
  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const { m_e_t: getToken } = cookies;
    if (getToken) {
      setUserToken(getToken);
    } else {
      setIsUserCompleted(true);
    }
  }, []);

  // Effected sources [token,]
  useEffect(() => {
    if (token && !user) {
      unsubscribe();
    }
  }, [token, pathname, isUserCompleted]);

  return {
    user,
    subscription,
    isUserCompleted,
    authHeader,
    setUserToken,
    signout,
    authUpdate,
    authError,
    token,
  };
};

export const ProvideAuth = ({ children }) => {
  const auth = useProvidedAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
