import { withTranslation } from 'next-i18next';
import React from 'react';
import Feedback from '@components/common/Feedback';
import PropTypes from 'prop-types';

const LabelSquareCheckWrapper = ({
  t,
  id,
  name,
  className,
  label,
  isGroup,
  checked,
  isDisabled,
  onChange,
  inputClassName,
  feedback,
  fontColor = '#2b2c2d',
  fontWeight = 600,
}) => (
  <div className="check-square-wrapper">
    <label
      htmlFor={id}
      className={`${className}  ${isGroup ? 'group' : ''} ${
        isDisabled ? 'disabled' : ''
      }`}
    >
      <input
        type="checkbox"
        className={`square ${inputClassName}`}
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={isDisabled}
      />
      {label && <div className="check-label">{label}</div>}
      {feedback && (
        <Feedback
          style={{
            marginLeft: '9px',
            marginTop: '1px',
          }}
          isDisabled={isDisabled}
        >
          {feedback}
        </Feedback>
      )}
    </label>
    <style jsx global>
      {`
        @import './src/sass/_vars.scss';
        @import './src/sass/_mixins.scss';
        .check-square-wrapper {
          @include font(12px, ${fontWeight}, -0.3px, 17px, ${fontColor});

          label {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            &.group {
              .check-label {
                font-weight: 600;
              }
            }
          }

          .check-label {
            display: inline-block;
            vertical-align: top;
            flex: 1;
            flex-basis: calc(100% - 24px);
            overflow: hidden;
            white-space: pre-line;
            cursor: pointer;
          }

          input[type='checkbox'] {
            display: inline-block;
            vertical-align: middle;
            z-index: 0;
            margin-right: 8px;

            &:checked + .check-label {
              //font-weight: 600;
            }
          }

          .disabled {
            .check-label {
              opacity: 0.3 !important;
              cursor: default;
            }
          }
        }
      `}
    </style>
  </div>
);

LabelSquareCheckWrapper.propTypes = {
  isDisabled: PropTypes.bool,
  checked: PropTypes.bool,
  fontColor: PropTypes.string,
  fontWeight: PropTypes.number,
};

LabelSquareCheckWrapper.defaultProps = {
  isDisabled: false,
  checked: false,
};

export default withTranslation('builder')(LabelSquareCheckWrapper);
