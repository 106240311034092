import { Portal } from 'react-portal';
import React, { useEffect, useState } from 'react';

const ToastContainer = ({ id, list, ToastComponent }) => {
  const [localToastList, setLocalToastList] = useState([]);

  const onToastAlertClose = (item) => {
    const list = localToastList;
    const idx = list?.findIndex((li) => li.key === item.key);
    if (idx > -1) {
      list.splice(idx, 1);
      setLocalToastList([...list]);
    }
  };

  useEffect(() => {
    if (list) {
      setLocalToastList(list);
    }

    return () => {
      setLocalToastList([]);
    };
  }, [list]);

  return (
    <Portal key={`${id}-toast-container`} className="toast-container">
      {localToastList.map((toast) => (
        <ToastComponent
          key={toast.key}
          toast={toast}
          onClose={() => {
            onToastAlertClose(toast);
          }}
        />
      ))}
      <style jsx>
        {`
          .toast-container {
            position: fixed;
            bottom: 40px;
            right: 40px;
            width: 328px;
            height: auto;
            //overflow: hidden;
            z-index: 1000001;
            display: flex;
            flex-direction: column-reverse;
          }
        `}
      </style>
    </Portal>
  );
};

export default ToastContainer;
