import { createSelector, createSlice } from '@reduxjs/toolkit';

const sandboxSlice = createSlice({
  name: 'sandbox',
  initialState: {
    isPublished: null,
    isSettingUpdated: null,
    needPublish: null,
    updatedDate: null,
  },
  reducers: {
    setIsPublished: (state, action) => {
      state.isPublished = action.payload;
    },
    setIsSettingUpdated: (state, action) => {
      state.isSettingUpdated = action.payload;
    },
    setNeedPublish: (state, action) => {
      state.needPublish = action.payload;
    },
    setUpdatedDate: (state, action) => {
      state.updatedDate = action.payload;
    },
  },
});

export const {
  setIsPublished,
  setIsSettingUpdated,
  setNeedPublish,
  setUpdatedDate,
} = sandboxSlice.actions;

export const getIsPublished = (string) => async (dispatch) => {
  await dispatch(setIsPublished(string));
};

export const getIsSettingUpdated = (bool) => async (dispatch) => {
  await dispatch(setIsSettingUpdated(bool));
};

export const sandbox = createSelector(
  (state) => ({
    isPublished: state.sandbox.isPublished,
    isSettingUpdated: state.sandbox.isSettingUpdated,
    needPublish: state.sandbox.needPublish,
    updatedDate: state.sandbox.updatedDate,
  }),
  (state) => state
);

export default sandboxSlice.reducer;
