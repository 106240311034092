import React from 'react';
import Script from 'next/script';
import { useAuth } from '@context/UseAuth';

const GoogleAnalytics = () => {
  const { user, isUserCompleted } = useAuth();

  if (isUserCompleted && !/@mind.ai$/.test(user?.email)) {
    return (
      <>
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA}`}
        />

        <Script
          id="google-analytics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GA}');
          `,
          }}
        />
      </>
    );
  }

  return '';
};

export default GoogleAnalytics;
