import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import axios from 'axios';
import { WARNING_POPUP_TYPE, API_URLS, NAV_PAGE } from '@js/constants';
import { useAuth } from '@context/UseAuth';
import { withTranslation } from 'next-i18next';

import ContextMenu from '@components/common/ContextMenu';
import toFit from '@js/toFit';
import WarningPopup from '@components/common/WarningPopup';

import GlobalNavPages from '@components/GlobalNavPages';
import GlobalLinks from '@components/GlobalLinks';
import Head from 'next/head';

const NAV_BTN_STATUS = {
  NONE: '',
  CLOSE: 'CLOSE',
  BACK: 'BACK',
};

const GlobalHeader = ({ t, className }) => {
  const noAvatarUrl = '/images/bt_profile_contents@2x.png';
  const [isUserMenu, setIsUserMenu] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [isMenuAni, setIsMenuAni] = useState(false);
  const [isWarningLogout, setIsWarningLogout] = useState(false);
  const userMenuRef = useRef();
  const logoRef = useRef();
  const [currentNavView, setCurrentNavView] = useState(NAV_PAGE.GLOBAL);
  const [navBtnStatus, setNavBtnStatus] = useState(NAV_BTN_STATUS.NONE);
  const router = useRouter();
  const { pathname, asPath } = router;
  const { user, isUserCompleted, authHeader, signout } = useAuth();

  const optimizedResize = () => {
    if (isMenu) {
      setIsMenuAni(false);
    }
    if (isMenuAni) {
      setIsMenuAni(false);
    }
  };

  const getCurrentNavView = () => currentNavView;

  const onMenuClicked = () => {
    if (navBtnStatus === NAV_BTN_STATUS.BACK) {
      setCurrentNavView(NAV_PAGE.GLOBAL);
    } else if (!isMenuAni) {
      setIsMenuAni(true);
      if (pathname.includes('/docs')) {
        setCurrentNavView(NAV_PAGE.DOCS);
      } else {
        setCurrentNavView(NAV_PAGE.GLOBAL);
      }
    } else {
      setIsMenuAni(false);
    }
  };

  const onMenuKeydown = (e) => {
    if (e?.key !== 'Escape') return;

    if (navBtnStatus === NAV_BTN_STATUS.BACK) {
      setCurrentNavView(NAV_PAGE.GLOBAL);
    } else if (!isMenuAni) {
      setIsMenuAni(true);
      if (pathname.includes('/docs')) {
        setCurrentNavView(NAV_PAGE.DOCS);
      } else {
        setCurrentNavView(NAV_PAGE.GLOBAL);
      }
    } else {
      setIsMenuAni(false);
    }
  };

  const onUserClicked = () => {
    setIsUserMenu(!isUserMenu);
  };

  const onLogoutClicked = (e) => {
    e.preventDefault();
    setIsUserMenu(false);
    setIsWarningLogout(true);
  };

  const onMypageClicked = (e) => {
    e.preventDefault();
    setIsUserMenu(false);
    router.push('/my-page');
  };

  const onWarningLogoutOKClicked = () => {
    const options = {
      crossDomain: true,
      headers: authHeader(),
    };

    axios
      .get(API_URLS.LOGOUT, options)
      .then(() => {
        signout(() => {
          setTimeout(() => {
            document.location.replace('/');
          }, 0);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onWarningLogoutCancelClicked = () => {
    setIsWarningLogout(false);
  };

  const onNavContainerClicked = (e) => {
    const { classList } = e.target;
    if (classList.contains('nav-container') && isMenuAni) {
      // setIsMenu(false);
      setIsMenuAni(false);
    }
  };

  useEffect(() => {
    window.addEventListener(
      'resize',
      toFit(optimizedResize, { passive: true })
    );

    return () => {
      window.removeEventListener(
        'resize',
        toFit(optimizedResize, { passive: true })
      );
    };
  }, [optimizedResize]);

  useEffect(() => {
    setTimeout(() => {
      if (isMenuAni) {
        if (currentNavView === NAV_PAGE.GLOBAL) {
          setNavBtnStatus(NAV_BTN_STATUS.CLOSE);
        } else {
          setNavBtnStatus(NAV_BTN_STATUS.BACK);
        }
      } else {
        setNavBtnStatus(NAV_BTN_STATUS.NONE);
      }
    }, 0);
  }, [isMenuAni, currentNavView]);

  useEffect(() => {
    if (!isMenuAni) {
      setTimeout(() => {
        setIsMenu(false);
      }, 400);
    }
  }, [isMenuAni]);

  useEffect(() => {
    if (pathname) {
      // setIsMenu(false);
      setIsMenuAni(false);
      if (pathname.includes('/docs')) {
        setCurrentNavView(NAV_PAGE.DOCS);
      } else {
        setCurrentNavView(NAV_PAGE.GLOBAL);
      }
    }
  }, [pathname, asPath]);

  return (
    <>
      {/* <Head> */}
      {/*   {typeof window !== 'undefined' && process.env.NEXT_PUBLIC_HOTJAR_ID && ( */}
      {/*     <script */}
      {/*       dangerouslySetInnerHTML={{ */}
      {/*         __html: ` */}
      {/*                   (function(h,o,t,j,a,r){ */}
      {/*       h.hj = h.hj || function() { */}
      {/*         (h.hj.q = h.hj.q || []).push(arguments); */}
      {/*       }; */}
      {/*       h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6}; */}
      {/*       a=o.getElementsByTagName('head')[0]; */}
      {/*       r=o.createElement('script');r.async=1; */}
      {/*       r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; */}
      {/*       a.appendChild(r); */}
      {/*     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv='); */}
      {/*           `, */}
      {/*       }} */}
      {/*     /> */}
      {/*   )} */}
      {/* </Head> */}
      <header
        className={`mind-header ${className} ${isMenu ? 'open' : ''} ${
          isUserCompleted && user ? 'online' : ''
        }
        `}
        id="mind-header"
      >
        <div className="wrapper">
          <div className="header-left">
            <span
              role="button"
              tabIndex={0}
              className={`btn-nav ${
                navBtnStatus !== NAV_BTN_STATUS.NONE
                  ? navBtnStatus.toLowerCase()
                  : 'open'
              }`}
              id="btn-nav"
              title={
                navBtnStatus !== NAV_BTN_STATUS.NONE
                  ? t(`btn-${navBtnStatus.toLowerCase()}`)
                  : 'Navigation'
              }
              onClick={onMenuClicked}
              onKeyDown={onMenuKeydown}
            >
              <span />
              <span />
              <span />
            </span>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a
              ref={logoRef}
              id="logo"
              href="/"
              className="header-logo"
              title="Mind Expression"
              aria-label="Mind Expression"
            />
          </div>
          <div className="header-right">
            <>
              <div
                className={`nav-container ${isMenuAni ? 'ani' : ''}`}
                onClick={onNavContainerClicked}
                onKeyDown={onNavContainerClicked}
              >
                <div className="nav-wrapper">
                  <GlobalNavPages
                    isMenuAni={isMenuAni}
                    className={isMenuAni ? 'ani' : ''}
                    logoRef={logoRef}
                    t={t}
                    setCurrentNavView={setCurrentNavView}
                    getCurrentNavView={getCurrentNavView}
                  />
                </div>
              </div>
              {isMenu ? (
                <></>
              ) : (
                <div className="desktop">
                  <GlobalLinks t={t} isMenu={isMenu} />
                </div>
              )}
              {isUserCompleted ? (
                user ? (
                  <>
                    <a
                      href="/builder"
                      className="btn-builder"
                      id="btn-builder"
                      title="Go to Builder"
                    >
                      Go to Builder
                    </a>
                    <div className="mind-dropdown">
                      <div className="user-default" />
                      <div
                        role="button"
                        tabIndex={0}
                        aria-label="User Account"
                        className="btn-user"
                        id="btn-user"
                        title="User Account"
                        style={{
                          backgroundImage: user?.profile_image_url
                            ? `url(${user?.profile_image_url})`
                            : `url(${noAvatarUrl})`,
                        }}
                        onClick={onUserClicked}
                        onKeyDown={onUserClicked}
                        ref={userMenuRef}
                        data-testid="btn-user"
                      />
                      {isUserMenu && (
                        <ContextMenu
                          setClose={onUserClicked}
                          button={userMenuRef}
                        >
                          <ul>
                            <li className="menu-item item-mypage">
                              <a title="My Page" onClick={onMypageClicked}>
                                My Profile
                              </a>
                            </li>
                            <li className="menu-item item-logout">
                              <a title="Log Out" onClick={onLogoutClicked}>
                                Logout
                              </a>
                            </li>
                          </ul>
                        </ContextMenu>
                      )}
                    </div>
                  </>
                ) : (
                  <a
                    href="/start"
                    className="bg-white btn-get-started"
                    id="btn-start"
                    title="Register Now"
                  >
                    Register Now
                  </a>
                )
              ) : (
                <></>
              )}
            </>
          </div>
        </div>
      </header>
      {isWarningLogout && (
        <WarningPopup
          className="negative"
          type={WARNING_POPUP_TYPE.CONFIRM}
          title={t('header-logout-popup-title')}
          content=""
          lblOK="Log Out"
          lblCancel="Cancel"
          onOKHandled={onWarningLogoutOKClicked}
          onCancelHandled={onWarningLogoutCancelClicked}
        />
      )}
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .mind-header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 999;
            padding-top: 0px;
            height: $header_m_height;
            //box-shadow: 0 2px 4px 0 rgba(43, 44, 45, 0.1);
            background-color: $white;
            @media all and (min-width: 768px) {
              height: $header_d_height;
              padding-top: 0px;
            }

            .wrapper {
              position: relative;
              max-width: none;
              padding: 0 24px 0 24px;
              @media all and (max-width: 1365px) {
                padding: 0 24px 0 24px;
              }
              @media all and (max-width: 767px) {
                padding: 0 16px 0 16px;
              }
            }

            .header {
              &-left,
              &-right {
                display: inline-block;
                vertical-align: top;
                font-size: 0px;
              }
              &-left {
                padding: 16px 0;
                @media all and (min-width: 768px) {
                  padding: 20px 0;
                }
                @media all and (min-width: 1366px) {
                  padding: 20px 0;
                }
              }
              &-right {
                float: right;
                display: flex;
                align-items: center;
              }
            }

            .btn-nav {
              position: relative;
              display: inline-block;
              vertical-align: top;
              width: 32px;
              height: 32px;
              cursor: pointer;
              z-index: 11;
              margin-right: 0px;

              span {
                position: absolute;
                left: 8px;
                display: block;
                z-index: 11;
                width: 16px;
                height: 2px;
                background-color: $black;
                //margin-bottom: 7px;

                transition: all 0.5s;
                transform-origin: 0 0;

                &:first-child {
                  top: 7px;
                }

                &:nth-child(2) {
                  top: 15px;
                }

                &:last-child {
                  top: 23px;
                  width: 12px;
                  margin-bottom: 0px;
                }
              }

              @media (hover: hover) {
                &:hover {
                  span {
                    opacity: 0.4;
                  }
                }
              }

              &:active {
                span {
                  opacity: 0.4;
                }
              }

              @mixin commonNavStyle() {
                position: fixed;
                width: 32px;
                height: 32px;
                top: 16px;
                left: 16px;
                z-index: 11;

                span {
                  width: 18px;
                  transform-origin: center center;
                  &:last-child {
                    transform: scale(0, 0);
                  }
                }
              }

              &.close {
                @include commonNavStyle();

                span {
                  left: 7px;
                  &:first-child {
                    top: 15px;
                    width: 17px;
                    transform: rotate(45deg);
                  }

                  &:nth-child(2) {
                    top: 15px;
                    width: 17px;
                    transform: rotate(-45deg);
                  }
                }
              }

              &.back {
                @include commonNavStyle();
                margin-top: 0px;

                span {
                  left: 10px;
                  &:first-child {
                    top: 12px;
                    width: 10px;
                    transform: rotate(-45deg);
                  }

                  &:nth-child(2) {
                    top: 18px;
                    width: 10px;
                    transform: rotate(45deg);
                    transform-origin: center center;
                  }
                }
              }

              &.ani {
                position: fixed;
                width: 32px;
                height: 32px;
                top: 16px;
                left: $global_m_padding;
                z-index: 11;

                span {
                  background: #000000;
                  left: 8px;

                  &:first-child {
                    top: 16px;
                    width: 17px;
                    transform: rotate(45deg);
                    transform-origin: center center;
                  }

                  &:nth-child(2) {
                    top: 16px;
                    width: 17px;
                    transform: rotate(-45deg);
                    transform-origin: center center;
                  }

                  &:last-child {
                    top: 24px;
                    transform: scale(0, 0);
                    transform-origin: center center;
                  }
                }
              }
            }

            .btn-nav-back {
              background: $white url('#{$image_url}/bt_dropdown_big/normal.svg')
                center center no-repeat;
              background-size: cover;
              position: relative;
              display: inline-block;
              vertical-align: top;
              width: 32px;
              height: 32px;
              cursor: pointer;
              z-index: 11;
              transform: rotate(-90deg);
              margin: 0;
            }

            #logo {
              position: absolute;
              top: auto;
              left: 48px;
              display: inline-block;
              vertical-align: top;
              width: 32px;
              height: 32px;
              background: transparent
                url('#{$image_url}/img_symbol_logo_black@4x.png') center center
                no-repeat;
              background-size: cover;
              @media screen and (max-width: 1365px) {
                left: 72px;
              }
              @media screen and (max-width: 767px) {
                left: 60px;
              }
            }

            .nav-container {
              position: fixed;
              background: rgba(43, 44, 45, 0.7);
              top: 0px;
              left: 0;
              right: 100%;
              bottom: 0;
              overflow-y: auto;
              transition-delay: 0s;
              z-index: 10;

              .nav-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 248px;
                padding: 0px;
                background: #ffffff;
                overflow: hidden;

                transition: all 0.5s;
                transform: translateX(-100%) translateY(0);
                opacity: 0;
                transition-delay: 0.1s;
              }

              &.open {
                display: block;
              }

              &.ani {
                position: fixed;
                top: 0;
                bottom: 0;
                width: 100%;
                transition-delay: 0s;

                .nav-wrapper {
                  transition: all 0.5s;
                  transform: translate(0, 0);
                  opacity: 1;
                }
              }
            }

            .mind-dropdown {
              position: relative;
            }

            .user-default {
              display: inline-block;
              width: 40px;
              height: 40px;
              margin-right: 4px;
              border-radius: 100%;
              vertical-align: top;
              background-color: #ffffff;
              background-image: url('#{$image_url}/bt_profile_contents@2x.png');
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
              z-index: 0;
            }

            #btn-user {
              position: absolute;
              top: 0;
              right: 0px;
              display: inline-block;
              width: 40px;
              height: 40px;
              margin-right: 4px;
              border-radius: 100%;
              vertical-align: top;
              content: '';
              overflow: hidden;
              color: transparent;
              background-color: #ffffff;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
              cursor: pointer;
              z-index: 1;
              box-shadow: 0 0 0 2px #ffffff;

              &.no-avatar {
                background-image: url('#{$image_url}/bt_profile_header@2x.png');
              }
            }

            .context-wrapper {
              margin-right: 4px;
            }

            .menu-item {
              height: 44px;
              display: flex;
              padding-left: 22px;
              justify-content: start;
              align-items: center;

              a,
              label,
              button {
                border: none;
                text-align: left;
                display: inline-block;
                font-weight: 700;
                height: auto;
                cursor: pointer;
                @include font(16px, 600, -0.4px, 24px, inherit);

                &[disabled] {
                  color: rgba(43, 44, 45, 0.2);
                }
              }

              &:hover {
                background-color: #f4f4f4;
              }
            }

            .menu-item {
              font-size: 15px;
              a {
                padding-left: 35px;
                font-size: 15px;
                font-weight: 700;
              }

              &:first-child {
                a {
                  background: url('#{$image_url}/ic_profile/normal.svg') 0
                    center no-repeat;
                  background-size: 20px 20px;

                  @media (hover: hover) {
                    &:hover {
                      background-image: url('#{$image_url}/ic_profile/normal.svg');
                    }
                  }
                  &:active {
                    background-image: url('#{$image_url}/ic_profile/normal.svg');
                  }
                }
              }

              &:nth-child(2) {
                a {
                  background: url('#{$image_url}/ic_logout/normal.svg') 0 center
                    no-repeat;
                  background-size: 20px 20px;

                  @media (hover: hover) {
                    &:hover {
                      background-image: url('#{$image_url}/ic_logout/normal.svg');
                    }
                  }
                  &:active {
                    background-image: url('#{$image_url}/ic_logout/normal.svg');
                  }
                }
              }
            }

            .desktop {
              position: relative;
              display: none;
            }

            #btn-start,
            #btn-builder {
              @include font(16px, 700, -0.4px, 24px, $primary);
              display: block;
              text-align: left;
              border: 1px solid #18aa78;
              padding: 8px 16px;
              margin-top: 16px;
              margin-bottom: 16px;
              margin-right: 24px;
              border-radius: 2px;
              @media screen and (max-width: 1365px) {
                margin-top: 16px;
                margin-bottom: 16px;
              }
              @media screen and (max-width: 767px) {
                margin-top: 12px;
                margin-bottom: 12px;
              }

              @media (hover: hover) {
                &:hover {
                  color: $primary_hover;
                }
              }
              &:active {
                color: $primary_hover;
              }
            }

            //&.online {
            //  .wrapper {
            //    padding-right: 16px;
            //  }
            //}

            @media all and (min-width: $resolution_d_min) {
              height: $header_d_height;
              padding: 0;

              .wrapper {
                padding: 0 48px;
              }

              .btn-nav {
                display: none;
              }

              .nav-container {
                .nav-wrapper {
                  width: 240px;
                }

                &.ani {
                  .nav-wrapper {
                    width: 240px;
                  }
                }
              }

              $logo_width: 267px;
              #logo {
                position: relative;
                display: inline-block;
                vertical-align: top;
                left: auto;
                top: auto;
                width: 267px;
                height: 32px;
                background-image: url('#{$image_url}/img_logo_mind_expression@4x.png');
              }

              .nav-container,
              .nav-container.open {
                display: none;
              }

              .user-default,
              #btn-user {
                margin-right: 0px;
              }

              .context-wrapper {
                margin-right: 0px;
              }

              .desktop {
                display: inline-block;
                vertical-align: top;
                //margin-top: -4px;
                padding-right: 48px;
                // width: calc(100% - #{$logo_width});
              }

              #btn-builder {
                display: inline-block;
                vertical-align: top;
              }

              .mind-dropdown {
                display: inline-block;
                vertical-align: top;
                margin-left: 0px;
                //   margin-right: 48px;
                margin-top: -4px;
              }

              // &.online {
              //  .wrapper {
              //     padding-right: 32px;
              //   }
              // }
            }
          }

          .mind-header-light {
            background-color: #fafafa;
          }

          .mind-header-dark {
            background-color: #394551;

            .open {
              span {
                background-color: $white;
              }
            }

            #logo {
              background-image: url('#{$image_url}/img_symbol_logo_white@4x.png');
              @media all and (min-width: 1366px) {
                background-image: url('#{$image_url}/img_logo_mind_expression_white@4x.png');
              }
            }

            #btn-builder,
            #btn-start {
              color: #24bf75;
              border: 1px solid #24bf75;
              border-radius: 5px;
              background: transparent;
            }

            #btn-user {
              box-shadow: none;
            }
          }

          .mind-header-docs {
            border-bottom: 1px solid #f2f4f5;
          }
        `}
      </style>
    </>
  );
};

GlobalHeader.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  // userValue: PropTypes.objectOf(PropTypes.any),
};

GlobalHeader.defaultProps = {
  // userValue: null,
  className: '',
};

export default withTranslation('common')(GlobalHeader);
