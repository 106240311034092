import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { withTranslation } from 'next-i18next';
import { useBuilder } from '@context/UseBuilder';
import { URLS } from '@js/constants';
import ApiLimitPieChart from '@components/builder/ApiLimitPieChart';
import ChangePlanButton from '@components/builder/ChangePlanButton';
import { useAuth } from '@context/UseAuth';
import getViewPermissions from '@js/utils/getViewPermissions';
import TellUsPopup from '@components/landing/TellUsPopup';
import { builder, setShowTellUsPopup } from '@js/store/slices/builderSlice';
import { useDispatch, useSelector } from 'react-redux';
import BuilderGlobalLink from './BuilderGlobalLink';
import BuilderNavLink from './BuilderNavLink';

const BuilderHeader = ({ t }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const headerRef = useRef();
  const { subscription, user } = useAuth();
  const { pathname } = router;
  const { showTellUsPopup } = useSelector(builder);
  const { NAV_TYPE, navType } = useBuilder();
  const [flexBasis, setFlexBasis] = useState({
    flexBasis: '56px',
    width: '56px',
  });

  const viewPermissions = getViewPermissions(user.role.role_id);

  useEffect(() => {
    let basis;
    let space;
    switch (navType) {
      case NAV_TYPE.COLLAPSE:
        basis = {
          flexBasis: '56px',
          width: '56px',
        };
        space = '56px';
        break;
      case NAV_TYPE.HIDDEN:
        basis = {
          flexBasis: '0px',
          width: '0px',
        };
        space = '0px';
        break;
      default:
        basis = {
          flexBasis: '144px',
          width: '144px',
        };
        space = '144px';
        break;
    }

    if (headerRef?.current) {
      const parent = headerRef.current.parentNode;
      parent.style.paddingLeft = space;
    }

    setFlexBasis(basis);
  }, [navType, headerRef]);

  const isCollapsed = navType !== NAV_TYPE.EXPAND;

  return (
    <>
      {subscription.limit !== null && subscription.count >= subscription.limit && (
        <div className="h-10 flex items-center justify-center bg-[#ffebeb] fixed z-10 left-0 right-0 text-[#ff3535] font-semibold text-xs">
          <div className="inline-block mr-2">
            The API calls have reached the limit. Conversational AI may not work
            as expected.
          </div>
          {viewPermissions.changePlanButton && <ChangePlanButton />}
        </div>
      )}
      <header
        ref={headerRef}
        className={`builder-header ${navType?.toLowerCase()}`}
        id="builder-header"
        style={flexBasis}
      >
        <div className="builder-inner-header">
          <div className="header-logo">
            <a
              href="/"
              className="btn-logo link"
              title="Home"
              aria-label="Home"
              target="_blank"
            />
          </div>
          <nav className="nav-builder">
            <ul>
              {viewPermissions.homeMenu && (
                <li>
                  <BuilderNavLink
                    href="/home"
                    className="home"
                    title="Home"
                    content="Home"
                    target="_self"
                    isCollapsed={isCollapsed}
                    isActive={
                      pathname.includes('/home') || pathname === '/builder'
                    }
                  />
                </li>
              )}
              {viewPermissions.scopeMenu && (
                <li>
                  <BuilderNavLink
                    href="/scopes"
                    className="scope"
                    title="Scopes"
                    content="Scopes"
                    target="_self"
                    isCollapsed={isCollapsed}
                    isActive={pathname.includes('/scopes')}
                  />
                </li>
              )}
              {viewPermissions.knowledgeMenu && (
                <li>
                  <BuilderNavLink
                    href="/knowledge"
                    className="knowledge"
                    title="Knowledge"
                    content="Knowledge"
                    target="_self"
                    isCollapsed={isCollapsed}
                    isActive={
                      pathname.includes('/knowledge') ||
                      pathname === '/knowledge'
                    }
                  />
                </li>
              )}
              {viewPermissions.liveChatMenu && (
                <li>
                  <BuilderNavLink
                    href="/livechat"
                    className="livechat"
                    title="Live Chat"
                    content="Live Chat"
                    target="_self"
                    isCollapsed={isCollapsed}
                    isActive={
                      pathname.includes('/livechat') || pathname === '/livechat'
                    }
                  />
                </li>
              )}
              {viewPermissions.analyticsMenu && (
                <li>
                  <BuilderNavLink
                    href="/analytics"
                    className="analysis"
                    title="Analytics"
                    content="Analytics"
                    target="_self"
                    isActive={pathname.includes('/analytics')}
                    isCollapsed={isCollapsed}
                  />
                </li>
              )}

              <li>
                <BuilderNavLink
                  type="link"
                  className="mypage"
                  href="/my-page"
                  title="My Page"
                  target="_self"
                  content="My Page"
                  isActive={pathname.includes('/my-page')}
                  isCollapsed={isCollapsed}
                  isFullLink
                />
              </li>
            </ul>
          </nav>
          <div className="nav-global">
            <ul>
              <li className="border-b border-0 border-solid border-[#dce1e61a] pb-4">
                <BuilderGlobalLink
                  type="link"
                  className="docs"
                  href={URLS.DOCS}
                  title="Docs"
                  target="_blank"
                  content="Docs"
                  isCollapsed={isCollapsed}
                />
              </li>
              {subscription?.limit !== null && (
                <li
                  className={`flex items-center pt-1 ${
                    !isCollapsed ? 'pl-1' : ''
                  }`}
                >
                  <ApiLimitPieChart isCollapsed={isCollapsed} />
                </li>
              )}
              {viewPermissions.changePlanButton && (
                <li>
                  <ChangePlanButton isCollapsed={isCollapsed} fullWidth />
                </li>
              )}
            </ul>
          </div>
        </div>

        {showTellUsPopup && (
          <TellUsPopup onClose={() => dispatch(setShowTellUsPopup(false))} />
        )}
        <style jsx>
          {`
            @import './src/sass/_vars.scss';
            @import './src/sass/_mixins.scss';
            $transition: 0.3s;
            $builder-header-minHeight: 330px;
            .builder-header {
              position: fixed;
              left: 0;
              height: 100%;

              flex: 0;
              flex-basis: 56px;
              width: 56px;

              background: #394551;
              color: #ffffff;
              overflow: hidden;
              transition: all $transition;
              transition-delay: 0s;
              z-index: 100;

              .nav-link {
                @include font(14px, 600, -0.3px, 32px, $white);
              }

              .wrapper {
                padding: 0px;
              }
            }

            .builder-inner-header {
              position: relative;
              width: 100%;
              height: 100%;
              min-height: $builder-header-minHeight;
            }

            .header-logo {
              padding: 8px 8px 4px;
              vertical-align: top;
              .btn-logo {
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 128px;
                height: 48px;
                background: url('#{$image_url}/logo_mx/normal@2x.png') center
                  center no-repeat;
                background-size: cover;

                &.link {
                  &:hover {
                    background-image: url('#{$image_url}/logo_mx/hover@2x.png');
                  }
                }

                &:after {
                  position: absolute;
                  display: inline-block;
                  content: '';
                  left: 0;
                  right: 0;
                  bottom: -4px;
                  height: 1px;
                  background: #dce1e6;
                  opacity: 0.1;
                }
              }
            }

            .nav-builder {
              margin-top: 27px;
              padding: 0 8px;

              li {
                position: relative;
                margin-top: 16px;
                &:first-child {
                  margin-top: 0;
                }
              }
            }

            .nav-global {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0px;
              padding: 0 8px;

              li {
                position: relative;
                margin-bottom: 16px;
              }
            }

            .builder-header.hidden,
            .builder-header.collapse {
              transition: all $transition;
              .header-logo {
                padding: 8px 4px 4px;

                .btn-logo {
                  width: 48px;
                  height: 48px;
                  margin-left: 0px;
                  background-image: url('#{$image_url}/logo_symbol_mx/normal@2x.png');

                  &:hover {
                    background-image: url('#{$image_url}/logo_symbol_mx/hover@2x.png');
                  }

                  &:after {
                    left: 4px;
                    right: 4px;
                  }
                }
              }

              .nav-builder {
                padding: 0 12px;
              }

              .nav-global {
                padding: 0 12px;
              }
            }

            .builder-header.collapse {
              overflow: visible;
              visibility: visible;
            }
          `}
        </style>
        <style jsx global>
          {`
            @import './src/sass/_vars.scss';
            @import './src/sass/_mixins.scss';

            .builder-header {
              .nav-link {
                position: relative;
                display: inline-block;
                vertical-align: top;
                width: 100%;
                height: 32px;
                background-repeat: no-repeat;
                background-position: left top;
                background-size: 32px 32px;
                padding-left: 40px;
                cursor: pointer;
                white-space: nowrap;

                @keyframes left {
                  0% {
                    opacity: 0;
                    margin-left: -200px;
                  }

                  60% {
                    opacity: 0;
                  }

                  100% {
                    opacity: 1;
                    margin-left: 0px;
                  }
                }

                /*& > span {
            opacity: 0;
            margin-left: 200px;

            animation: left 0.5s;
            animation-delay: 0s;
            animation-fill-mode: forwards;
          }*/
              }

              .icn-blank {
                display: inline-block;
                width: 24px;
                height: 24px;
                vertical-align: top;
                margin-top: 4px;
                margin-left: 4px;
                background: url('#{$image_url}/ic_new_tab@2x.png') center center
                  no-repeat;
                background-size: 24px 24px;
              }

              .hover-label {
                position: absolute;
                top: 4px;
                left: 40px;
                // height: 24px;
                border: 1px solid $white;
                background: #25303a;
                border-radius: 2px;
                @include font(12px, 700, -0.3, 22px, $white);
                padding: 0 8px;
                width: auto;
                white-space: nowrap;
                z-index: 1;

                .icn-blank {
                  width: 22px;
                  height: 22px;
                  margin-top: 0px;
                }

                &.blank {
                  padding-right: 0px;
                }
              }
            }
          `}
        </style>
      </header>
    </>
  );
};

BuilderHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('builder')(BuilderHeader);
