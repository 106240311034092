import { createSlice, createSelector } from '@reduxjs/toolkit';

const conversationSlice = createSlice({
  name: 'conversation',
  initialState: {
    conversations: [],
    nsConversations: [],
    selectedConversation: null,
    editConversation: null,
    expectedView: null,
    nsExpectedView: null,
    nsIsCollapsed: true,
    changedTemplateViews: [],
    nsChangedTemplateViews: [],
  },
  reducers: {
    clearAllConversation: (state, action) => {
      state.conversations = [];
      state.nsConversations = [];
      // state.selectedConversation = null;
    },
    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
    addConversation: (state, action) => {
      state.conversations.push(action.payload);
    },
    deleteConversation: (state, action) => {
      // Reset to action button
      const { idx, type } = action.payload;
      const upIdx = state.conversations.findIndex((si) => si.idx === idx);
      const { item } = action.payload;
      state.conversations[upIdx] = {
        ...state.conversations[upIdx],
        type: 'SET_UP_ACTION',
        isTyping: false,
        action: 'SET_UP_ACTION',
        item,
      };

      // state.selectedConversation = null;
    },
    updateConversation: (state, action) => {
      const item = action.payload;
      const idx = state.conversations.findIndex((con) => con.idx === item.idx);
      state.conversations[idx] = {
        ...state.conversations[idx],
        ...item,
      };
      if (state.selectedConversation?.idx === item.idx) {
        state.selectedConversation = {
          ...state.selectedConversation,
          ...item,
        };
      }
    },
    addNsConversation: (state, action) => {
      state.nsConversations.push(action.payload);
      // state.selectedConversation = action.payload;
    },
    updateNsConversation: (state, action) => {
      const idx = state.nsConversations.findIndex(
        (ns) => ns.idx === action.payload.idx
      );
      state.nsConversations[idx] = action.payload;
    },
    deleteNsConversation: (state, action) => {
      const { idx } = action.payload;
      const delIdx = state.nsConversations.findIndex((si) => si.idx === idx);
      state.nsConversations.splice(delIdx, 1);
      if (state.selectedConversation?.idx === idx) {
        state.selectedConversation = null;
      }
    },
    clearNsConversation: (state, action) => {
      state.nsConversations = [];
    },
    clearConversation: (state, action) => {
      state.conversations = [];
    },
    setExpectedView: (state, action) => {
      state.expectedView = action.payload;
    },
    setNsExpectedView: (state, action) => {
      state.nsExpectedView = action.payload;
    },
    setEditConversation: (state, action) => {
      state.editConversation = action.payload;
    },
    setNsIsCollapsed: (state, action) => {
      state.nsIsCollapsed = action.payload;
    },
    addChangedTemplateViews: (state, action) => {
      const list = new Set(state.changedTemplateViews);
      list.add(action.payload);
      state.changedTemplateViews = [...list];
    },
    removeChangedTemplateViews: (state, action) => {
      const list = new Set(state.changedTemplateViews);
      list.delete(action.payload);
      state.changedTemplateViews = [...list];
    },
    clearChangeTemplateViews: (state, action) => {
      state.changedTemplateViews = [];
    },
    addNsChangedTemplateViews: (state, action) => {
      const list = new Set(state.nsChangedTemplateViews);
      list.add(action.payload);
      state.nsChangedTemplateViews = [...list];
    },
    removeNsChangedTemplateViews: (state, action) => {
      const list = new Set(state.nsChangedTemplateViews);
      list.delete(action.payload);
      state.nsChangedTemplateViews = [...list];
    },
    clearNsChangeTemplateViews: (state, action) => {
      state.nsChangedTemplateViews = [];
    },
  },
  extraReducers: {},
});

export const {
  clearAllConversation,
  setSelectedConversation,
  addConversation,
  deleteConversation,
  updateConversation,
  addNsConversation,
  updateNsConversation,
  deleteNsConversation,
  clearNsConversation,
  setExpectedView,
  setEditConversation,
  clearConversation,
  setNsExpectedView,
  setNsIsCollapsed,
  addChangedTemplateViews,
  removeChangedTemplateViews,
  clearChangeTemplateViews,
  addNsChangedTemplateViews,
  removeNsChangedTemplateViews,
  clearNsChangeTemplateViews,
} = conversationSlice.actions;

export const conversations = createSelector(
  (state) => ({
    selectedConversation: state.conversation.selectedConversation,
    editConversation: state.conversation.editConversation,
    conversations: state.conversation.conversations,
    nsConversations: state.conversation.nsConversations,
    expectedView: state.conversation.expectedView,
    nsExpectedView: state.conversation.nsExpectedView,
    nsIsCollapsed: state.conversation.nsIsCollapsed,
    changedTemplateViews: state.conversation.changedTemplateViews,
    nsChangedTemplateViews: state.conversation.nsChangedTemplateViews,
  }),
  (state) => state
);

export default conversationSlice.reducer;
