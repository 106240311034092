import { createSlice } from '@reduxjs/toolkit';

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    selectedDate : null,
    selectedStartDate : null,
    selectedEndDate : null,
    isReset: false,
    isCustomClicked : false,
  },
  reducers: {
    setSelectedDate : (state, action) => {
      state.selectedDate = action.payload;
    },
    setSelectedStartDate : (state, action) => {
      state.selectedStartDate = action.payload;
    },
    setSelectedEndDate : (state, action) => {
      state.selectedEndDate = action.payload;
    },
    setIsReset: (state, action) => {
      state.isReset = action.payload;
    },
    setIsCustomClicked: (state, action) => {
      state.isCustomClicked = action.payload;
    }
  }
})

export const {
  setSelectedDate, setIsReset, setIsCustomClicked, setSelectedStartDate, setSelectedEndDate
} = calendarSlice.actions;

export const getSelectedDate = (date) => async (dispatch) => {
  await dispatch(setSelectedDate(date));
}

export default calendarSlice.reducer;
