import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

const BuilderNotification = ({ toast, onClose }) => {
  const router = useRouter();
  const toastRef = useRef();
  const [isAni, setIsAni] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const ref = useRef();
  const [count, setCount] = useState(0);
  const KEEPING_TIME = 8000;

  useEffect(() => {
    let timer1 = null;
    let timer2 = null;
    let timer3 = null;
    if (toast && count > 0) {
      timer1 = setTimeout(() => {
        setIsAni(true);
        timer2 = setTimeout(() => {
          setIsAni(false);
          timer3 = setTimeout(() => {
            onClose();
          }, 1000);
        }, count);
      }, 100);
    }

    return () => {
      // Should clear all timers
      setIsAni(false);
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [toast, count]);

  useEffect(() => {
    if (ref?.current) {
      const { current } = ref;
      const { width, height } = current.getBoundingClientRect();
      const tempCount = Math.round((height - 24) / 16);
      setSize({
        width,
        height,
      });
      // setCount((tempCount + 2) * 1000);
      setCount(KEEPING_TIME);
      if (toastRef?.current) {
        toastRef.current.style.top = `${height * -1}px`;
      }
    }

    return () => {
      setCount(0);
    };
  }, [ref]);

  useEffect(() => {
    if (isAni) {
      if (toastRef?.current) {
        toastRef.current.style.top = '0px';
      }
    } else if (size?.height) {
      toastRef.current.style.top = `${size?.height * -1}px`;
    }
  }, [isAni]);

  return (
    <>
      {toast && (
        <div key={toast.key}>
          <div
            className={`toast-notification ${toast?.type || ''} ${
              isAni ? 'ani' : ''
            }`}
            style={{
              width: size.width,
              height: size.height,
            }}
          >
            <div
              ref={toastRef}
              className={`box-toast ${toast?.link ? 'has-link' : 'has-link'}`}
              style={{}}
            >
              {toast.message}
              {toast?.link && (
                <div className="toast-link">
                  <span
                    onClick={() => {
                      setCount(0);
                      onClose();
                      router.push(toast.link.pathname, toast.link.asPath, {
                        shallow: true,
                      });
                    }}
                  >
                    View
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={`toast-notification ${toast?.type || ''} hide-toast`}>
            <div
              ref={ref}
              className={`${toast?.link ? 'has-link' : 'has-link'}`}
            >
              {toast.message}
            </div>
          </div>
        </div>
      )}
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .toast-notification {
            position: fixed;
            bottom: auto;
            top: 40px;
            right: 40px;
            width: 320px;
            height: auto;
            z-index: 2;
            overflow: hidden;

            & > div {
              position: absolute;
              // bottom: -100px;
              // bottom: -500px;
              right: 0px;
              width: 328px;
              height: auto;
              min-height: 40px;
              background: #ffffff url('#{$image_url}/ic_notification.png') left
                7px top 7px no-repeat;
              background-size: 24px 24px;
              border: 1px solid $black;
              border-radius: 2px;
              padding: 12px 12px 12px 36px;
              @include font(12px, 700, -0.3px, 16px, $black);
              text-align: left;

              &.has-link {
                padding-right: 76px;
              }
            }

            & > .box-toast {
              transition: top 0.5s;
            }

            &.ani {
              & > .box-toast {
                transition: top 0.5s;
              }
            }

            &.hide-toast {
              position: fixed;
              top: -99999px;
              left: -999999px;
              opacity: 0;
            }

            .toast-link {
              position: absolute;
              top: 12px;
              right: 16px;
              bottom: 12px;

              &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: -16px;
                bottom: 0;
                width: 1px;
                background-color: #dce1e6;
              }

              span {
                @include font(12px, 700, -0.3px, 16px, #18aa78);
                cursor: pointer;
                &:hover {
                  color: #438467;
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

BuilderNotification.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

BuilderNotification.defaultProps = {
  children: '',
};

export default BuilderNotification;
