import { createSelector, createSlice } from '@reduxjs/toolkit';

const knowledgeSlice = createSlice({
  name: 'knowledge',
  initialState: {
    productAttributes: null,
    defaultAttributes: null,
  },
  reducers: {
    setProductAttributes: (state, action) => {
      state.productAttributes = action.payload;
    },
    setDefaultAttributes: (state, action) => {
      state.defaultAttributes = action.payload;
    },
  },
});

export const {
  setProductAttributes,
  setDefaultAttributes,
} = knowledgeSlice.actions;

export const knowledge = createSelector((state) => ({
  productAttributes: state.knowledge.productAttributes,
  defaultAttributes: state.knowledge.defaultAttributes,
}), (state) => state);

export default knowledgeSlice.reducer;
