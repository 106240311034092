/* eslint-disable */
'use strict';

import { URL_REGEX } from '@js/constants';

function addMultipleEventListener(element, events, handler) {
  let evts = events.split(' ');
  Array.prototype.forEach.call(evts, (e) =>
    element.addEventListener(e, handler)
  );
}

function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function validateUrl(url) {
  return URL_REGEX.test(url);
}

function toast(element) {
  element.style.display = 'block';
  setTimeout(() => {
    element.classList.add('is-visible');
    setTimeout(() => {
      element.classList.remove('is-visible');
      setTimeout(() => {
        element.style.display = 'none';
      }, 500);
    }, 2500);
  }, 10);
}

let cloneNode = (obj) => {
  return obj.cloneNode(true);
};

let cloneObject = (obj) => {
  return Object.assign({}, obj);
};

let createPopup = (content) => {
  let popup = document.createElement('div');
  popup.classList.add('popup-default');
  popup.classList.add('popup-team');
  popup.innerHTML =
    '<div class="popup-wrapper"><div class="popup-inner-wrapper"><div class="popup-content"><div class="btn-close" title="Close"></div> <div class="popup-body"></div></div></div></div>';
  let body = popup.querySelector('.popup-body');
  body.appendChild(content);

  let closePopup = () => {
    popup.classList.remove('on');
    document.body.removeChild(popup);
  };

  let outside = popup.querySelector('.popup-wrapper');
  outside.onclick = (evt) => {
    let target = evt.target;
    if (target.classList.contains('popup-inner-wrapper')) {
      closePopup();
    }
  };

  let btnClose = popup.querySelector('.btn-close');
  btnClose.onclick = () => {
    closePopup();
  };
  return popup;
};

function triggerVideoTouchStartEvent(id) {
  let videoIntroPlayer = videojs(document.querySelector(id));
  if (videoIntroPlayer) {
    videoIntroPlayer.on('touchstart', (e) => {
      if (e.target.nodeName === 'VIDEO') {
        if (videoIntroPlayer.paused()) {
          videoIntroPlayer.play();
        } else {
          videoIntroPlayer.pause();
        }
      }
    });
  }
}

let getLocale = () => {
  let locale = 'en';
  const htmlEl = document.querySelector('html');
  if (htmlEl) {
    locale = htmlEl.getAttribute('lang');
    if (locale) {
      return locale;
    }
  }
  return locale;
};

const getOffset = (ref, isCon) => {
  if (ref.current && isCon) {
    const { current } = ref;
    const itemWidth = current.clientWidth;
    const offsetTop = parseInt(
      current.getBoundingClientRect().top + window.scrollY + 2
    );
    const offsetLeft = parseInt(
      current.getBoundingClientRect().left + window.scrollX + itemWidth - 28
    );
    const offsetRight = 'auto';
    const offsetBottom = 'auto';

    return {
      top: offsetTop,
      left: offsetLeft,
      right: offsetRight,
      bottom: offsetBottom,
    };
  }

  return {
    top: 0,
    left: 0,
    right: 'auto',
    bottom: 'auto',
  };
};

const get24HourOptions = (limit = 24, gapMinutes = 30) => {
  const timeArray = Array.from(
    { length: Math.floor((limit * 60 - 0) / gapMinutes) + 1 },
    (_, index) => 0 + index * gapMinutes
  ).map((minutes) => {
    let hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    if (hours === 24) {
      hours = 0;
    }

    const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${
      mins === 0 ? '00' : mins
    }`;

    return {
      key: `time-${hours}-${mins}`,
      label: formattedTime,
      value: { hours, minutes: mins },
    };
  });

  return timeArray;
};

const getHourOptions = (limit) => {
  return Array.from(
    { length: Math.floor((limit - 0) / 1) + 1 },
    (_, index) => 0 + index * 1
  ).map((el) => ({
    key: `hour-${el}`,
    label: el < 10 ? `0${el}` : `${el}`,
    value: el,
  }));
};

const getMinuteOptions = (limit = 55, gap = 5) =>
  Array.from(
    { length: Math.floor((limit - 0) / gap) + 1 },
    (_, index) => 0 + index * gap
  ).map((el) => ({
    key: `minute-${el}`,
    label: el < 10 ? `0${el}` : `${el}`,
    value: el,
  }));

export {
  addMultipleEventListener,
  validateEmail,
  validateUrl,
  toast,
  cloneNode,
  cloneObject,
  createPopup,
  triggerVideoTouchStartEvent,
  getLocale,
  getOffset,
  get24HourOptions,
  getHourOptions,
  getMinuteOptions,
};
