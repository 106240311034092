import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useRouter } from 'next/router';
import rs from 'randomstring';
import { API_URLS, NAV_TYPE } from '../constants';

const builderContext = createContext();

const useProvidedBuilder = ({ headerTab }) => {
  const router = useRouter();
  const [isFirst, setIsFirst] = useState(true);
  const [navType, setNavType] = useState(headerTab); // HIDDEN, COLLAPSE, EXPAND
  const [mindTimezone, setMindTimezone] = useState([]);
  const [canIMove, setCanIMove] = useState(true);
  const [relationType, setRelationType] = useState(null);
  const [canIChangeCurrent, setCanIChangeCurrent] = useState(true);
  const [notificationList, setNotificationList] = useState([]);
  const [toastList, setToastList] = useState([]);
  const [toastAlertContent, setToastAlertContent] = useState(null);

  const pushRoute = (pathname, asPath, options) => {
    if (canIMove) {
      router.push(pathname, asPath, options);
    } else {
      /* publishAction('PAGE_LEAVE', () => {
        router.push(pathname, asPath, options);
        publishAction(null);
        setCanIMove(true);
      }); */
    }
  };

  const toastAlert = (type, message) => {
    // console.log(type, message);
    // setToastAlertContent(null);
    // Toast message should be popped up only one
    const list = []; // ...toastList
    list.push({
      key: rs.generate(),
      type,
      message,
    });
    setToastList(list);
  };

  const toastNotification = (message, link) => {
    /**
     * link should be
     * pathanme
     * asPath
     */
    const list = [];
    list.push({
      key: rs.generate(),
      message,
      link,
    });
    setNotificationList(list);
  };

  useEffect(() => {
    if (isFirst && mindTimezone && mindTimezone.length < 1) {
      axios
        .get(API_URLS.TIMEZONE)
        .then((res) => {
          const { data } = res;
          setMindTimezone(data);
        })
        .catch((error) => {
          setMindTimezone([]);
        });
      setIsFirst(false);
    }
  }, [mindTimezone]);

  return {
    NAV_TYPE,
    navType,
    setNavType,
    TIMEZONE: mindTimezone,
    canIMove,
    setCanIMove,
    pushRoute,
    relationType,
    setRelationType,
    canIChangeCurrent,
    setCanIChangeCurrent,
    toastAlert,
    toastAlertContent,
    setToastAlertContent,
    toastList,
    setToastList,
    notificationList,
    toastNotification,
  };
};

export const useBuilder = () => useContext(builderContext);

export const ProvideBuilder = ({ headerTab, children }) => (
  <builderContext.Provider
    value={useProvidedBuilder({
      headerTab,
    })}
  >
    {children}
  </builderContext.Provider>
);

ProvideBuilder.propTypes = {
  children: PropTypes.node.isRequired,
};
