import React from 'react';
import PercentageCircle from '@components/common/PercentageCircle';
import PropTypes from 'prop-types';
import { useAuth } from '@context/UseAuth';

const getBarColor = (percent) => {
  let barColor;
  if (percent < 40) {
    barColor = '#66C146;';
  }

  if (percent >= 40 && percent < 80) {
    barColor = '#F7B716;';
  }

  if (percent >= 80) {
    barColor = '#FF3535;';
  }

  return barColor;
};

const ApiLimitPieChart = ({ isCollapsed }) => {
  const { subscription } = useAuth();
  const percent = (subscription.count / subscription.limit) * 100;
  const barColor = getBarColor(percent);

  const limitNumber = subscription.limit / 1000;
  const limitNumberWithUnit =
    limitNumber > 999 ? `${limitNumber / 1000}M` : `${limitNumber}K`;

  const limitApiNumber = (
    <>
      {subscription.count.toLocaleString()}
      {subscription.count > 999999 ? <br /> : ''}/{limitNumberWithUnit}
    </>
  );

  return (
    <>
      <div className={`circle-container ${isCollapsed ? 'collapsed' : ''}`}>
        <PercentageCircle percent={percent} barColor={barColor} />
        <span className="hover-label text-xs font-semibold">
          {limitApiNumber}
          <div className="text-xxs text-[#cbd1d7] font-normal">API calls</div>
        </span>
      </div>

      {!isCollapsed && (
        <div className="ml-3 text-xs">
          {limitApiNumber}
          <div className="text-xxs text-[#cbd1d7]">API calls</div>
        </div>
      )}

      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .hover-label {
            display: none;
            top: -2px !important;
            left: 40px;
            padding: 6px !important;
            line-height: 16px;
          }

          .collapsed.circle-container:hover {
            .hover-label {
              display: block;
            }
          }
        `}
      </style>
    </>
  );
};

ApiLimitPieChart.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
};

export default ApiLimitPieChart;
