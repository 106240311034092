import { createSlice, createSelector } from '@reduxjs/toolkit';

const removeContextSlice = createSlice({
  name: 'removeContext',
  initialState: {
    selectedStep: null,
    selectedAction: null,
    startRef: null,
    destinationRef: null,
    destinationHistory: [],
    isDestinationUpdated: false,
  },
  reducers: {
    clearAllRef: (state, action) => {
      state.selectedStep = null;
      state.selectedAction = null;
      state.startRef = null;
      state.destinationRef = null;
      state.destinationStep = null;
      state.isDestinationUpdated = false;
      state.destinationHistory = [];
    },
    setSelectedStep: (state, action) => {
      state.selectedStep = action.payload;
    },
    setSelectedAction: (state, action) => {
      state.selectedAction = action.payload;
    },
    setStartRef: (state, action) => {
      state.startRef = action.payload;
    },
    setDestinationRef: (state, action) => {
      state.destinationRef = action.payload;
    },
    setDestinationStep: (state, action) => {
      state.destinationStep = action.payload;
    },
    setIsDestinationUpdated: (state, action) => {
      state.isDestinationUpdated = action.payload;
    },
    setDestinationHistory: (state, action) => {
      state.destinationHistory = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  clearAllRef,
  setSelectedStep,
  setSelectedAction,
  setStartRef,
  setDestinationRef,
  setDestinationStep,
  setIsDestinationUpdated,
  setDestinationHistory,
} = removeContextSlice.actions;

export const removeContext = createSelector(
  (state) => ({
    selectedStep: state.removeContext.selectedStep,
    selectedAction: state.removeContext.selectedAction,
    startRef: state.removeContext.startRef,
    destinationRef: state.removeContext.destinationRef,
    destinationStep: state.removeContext.destinationStep,
    isDestinationUpdated: state.removeContext.isDestinationUpdated,
    destinationHistory: state.removeContext.destinationHistory,
  }),
  (state) => state
);

export default removeContextSlice.reducer;
