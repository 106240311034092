import countries from '@js/json/countries.json';
// https://gist.github.com/ammarshah/f5c2624d767f91a7cbdc4e54db8dd0bf

export const validateCompanyWebsite = (value) =>
  /^(https?:\/\/|(?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,}(\/)?)$/.test(
    value
  );

export const countryOptions = countries.map((country) => ({
  key: `${country.code}_${country.label}`,
  value: country.label,
  label: country.label,
}));

export const expectedTransactionNumberOptions = [
  {
    key: 'transaction-1',
    value: '1 to 100,000',
    label: '1 to 100,000',
  },
  {
    key: 'transaction-2',
    value: '100,001 to 300,000',
    label: '100,001 to 300,000',
  },
  {
    key: 'transaction-3',
    value: '300,001 to 600,000',
    label: '300,001 to 600,000',
  },
  {
    key: 'transaction-4',
    value: '600,001 to 2,000,000',
    label: '600,001 to 2,000,000',
  },
  {
    key: 'transaction-5',
    value: '2,000,001 to 3,000,000',
    label: '2,000,001 to 3,000,000',
  },
  {
    key: 'transaction-6',
    value: '3,000,001 up',
    label: '3,000,001 up',
  },
];

export const teamSizeOptions = [
  {
    key: '1to6',
    value: '1 to 6',
    label: '1 to 6',
  },
  {
    key: '7to250',
    value: '7 to 250',
    label: '7 to 250',
  },
  {
    key: '251to500',
    value: '251 to 500',
    label: '251 to 500',
  },
  {
    key: '501to1000',
    value: '501 to 1,000',
    label: '501 to 1,000',
  },
  {
    key: '1000up',
    value: '1,001 up',
    label: '1,001 up',
  },
];

export const purposeOptions = [
  {
    key: 'synergistic-model',
    value: 'Synergistic Model with ML/DL',
    label: 'Synergistic Model with ML/DL',
  },
  {
    key: 'robotic-process',
    value: 'Robotic Process Automation (RPA)',
    label: 'Robotic Process Automation (RPA)',
  },
  {
    key: 'intelligent-process',
    value: 'Intelligent Process Automation (IPA)',
    label: 'Intelligent Process Automation (IPA)',
  },
  {
    key: 'conversational-al',
    value: 'Conversational Al (CAI)',
    label: 'Conversational Al (CAI)',
  },
  {
    key: 'other',
    value: 'Other',
    label: 'Other',
  },
];

export const COMPANY_INDUSTRY_OPTIONS = [
  {
    key: 'agriculture',
    value: 'Agriculture',
    label: 'Agriculture',
  },
  {
    key: 'automotive',
    value: 'Automotive',
    label: 'Automotive',
  },
  {
    key: 'education',
    value: 'Education',
    label: 'Education',
  },
  {
    key: 'energy',
    value: 'Energy',
    label: 'Energy',
  },
  {
    key: 'entertainment-and-media',
    value: 'Entertainment and Media',
    label: 'Entertainment and Media',
  },
  {
    key: 'environmental-services',
    value: 'Environmental Services',
    label: 'Environmental Services',
  },
  {
    key: 'finance',
    value: 'Finance',
    label: 'Finance',
  },
  {
    key: 'fitness-and-wellness',
    value: 'Fitness and Wellness',
    label: 'Fitness and Wellness',
  },
  {
    key: 'healthcare',
    value: 'Healthcare',
    label: 'Healthcare',
  },
  {
    key: 'hospitality-and-tourism',
    value: 'Hospitality and Tourism',
    label: 'Hospitality and Tourism',
  },
  {
    key: 'manufacturing',
    value: 'Manufacturing',
    label: 'Manufacturing',
  },
  {
    key: 'nonprofit-and-social-services',
    value: 'Nonprofit and Social Services',
    label: 'Nonprofit and Social Services',
  },
  {
    key: 'professional-services',
    value: 'Professional Services',
    label: 'Professional Services',
  },
  {
    key: 'real-estate',
    value: 'Real Estate',
    label: 'Real Estate',
  },
  {
    key: 'retail',
    value: 'Retail',
    label: 'Retail',
  },
  {
    key: 'technology',
    value: 'Technology',
    label: 'Technology',
  },
  {
    key: 'telecommunications',
    value: 'Telecommunications',
    label: 'Telecommunications',
  },
  {
    key: 'transportation-and-logistics',
    value: 'Transportation and Logistics',
    label: 'Transportation and Logistics',
  },
  {
    key: 'fashion-and-apparel',
    value: 'Fashion and Apparel',
    label: 'Fashion and Apparel',
  },
  {
    key: 'consumer-goods',
    value: 'Consumer Goods',
    label: 'Consumer Goods',
  },
  {
    key: 'other',
    value: 'Other',
    label: 'Other',
  },
];

export const knowMeFromOptions = [
  {
    key: 'search-engine',
    value: 'Search engine',
    label: 'Search engine',
  },
  {
    key: 'word-of-mouth',
    value: 'Word of mouth',
    label: 'Word of mouth',
  },
  {
    key: 'facebook',
    value: 'Facebook',
    label: 'Facebook',
  },
  {
    key: 'linkedIn',
    value: 'LinkedIn',
    label: 'LinkedIn',
  },
  {
    key: 'youtube',
    value: 'Youtube',
    label: 'Youtube',
  },
  {
    key: 'instagram',
    value: 'Instagram',
    label: 'Instagram',
  },
  {
    key: 'know-other',
    value: 'Other',
    label: 'Other',
  },
];

export const SUBSCRIPTION_PLAN = {
  FREE: 'free',
  ENTERPRISE: 'enterprise',
  PARTNER: 'partner',
};

export const DESIRED_PLAN_OPTIONS = {
  [SUBSCRIPTION_PLAN.FREE]: [
    {
      key: 'enterprise',
      value: 'Enterprise',
      label: 'Enterprise',
    },
    {
      key: 'partner',
      value: 'Certified Partner',
      label: 'Certified Partner',
    },
  ],
  [SUBSCRIPTION_PLAN.ENTERPRISE]: [
    {
      key: 'partner',
      value: 'Certified Partner',
      label: 'Certified Partner',
    },
  ],
  [SUBSCRIPTION_PLAN.PARTNER]: [
    {
      key: 'enterprise',
      value: 'Enterprise',
      label: 'Enterprise',
    },
  ],
};
