import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const CustomSpinner = ({ size }) => (
  <>
    <div
      className="spinner-circle"
      style={{
        width: size ? `${size}px` : '40px',
        height: size ? `${size}px` : '40px',
      }}
    >
      <CircularProgress size={size ? `${size}px` : '40px'} thickness={4} />
    </div>
    <style jsx global>
      {`
        .MuiCircularProgress-colorPrimary {
          color: #ffbd2e !important;
        }
      `}
    </style>
    <style jsx>
      {`
        @import './src/sass/_vars.scss';
        @import './src/sass/_mixins.scss';
        /*.spinner-circle {
          width: 40px;
          height: 40px;
        }*/
      `}
    </style>
  </>
);

export default CustomSpinner;
