import React, { useRef, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Scrollbars } from 'react-custom-scrollbars-with-mobile-fix';

const MenuLinks = ({ title, menus, subMenuTitle }) => {
  const rootRef = useRef();
  const navRef = useRef();
  const scrollRef = useRef();
  const router = useRouter();
  const { pathname } = router;
  const [isReady, setIsReady] = useState(false);

  const renderThumbVertical = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: '6px',
      opacity: '0.4',
      minWidth: '6px',
    };
    return (
      <div
        className="solutions-nav-thumb"
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  };

  useEffect(() => {
    if (Scrollbars) {
      setIsReady(true);
    }
    return () => {
      setIsReady(false);
    };
  }, [Scrollbars]);

  return (
    <div ref={rootRef} className="solutions-left-wrapper">
      {isReady && (
        <Scrollbars
          ref={scrollRef}
          autoHide
          className="solutions-nav-scroll"
          renderThumbVertical={(options) => renderThumbVertical(options)}
          mobile
        >
          <nav className="solutions-nav" ref={navRef}>
            <div className="solutions-link-head">
              <ul>
                <li>
                  <p
                    className="solution-nav-link no-sub font-extrabold text-[20px]"
                    title={title}
                    target="_self"
                  >
                    <span>{title}</span>
                  </p>
                </li>
                {subMenuTitle && (
                  <>
                    <li>
                      <a
                        className="solution-nav-link no-sub font-bold text-base"
                        href={subMenuTitle.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>{subMenuTitle.title}</span>
                      </a>
                    </li>
                    <li className="bar">
                      <div />
                    </li>
                  </>
                )}
              </ul>
            </div>
            <ul>
              {menus.map(
                (
                  {
                    title: menutitle,
                    href = '',
                    target = '_self',
                    heading = false,
                  },
                  index
                ) => (
                  <>
                    {index !== 0 && heading && (
                      <li className="bar">
                        <div />
                      </li>
                    )}

                    <li key={menutitle} id={menutitle}>
                      {heading ? (
                        <span className="inline-block mt-1 text-[#A9AFB5] text-sm p-3 pl-6 font-bold uppercase">
                          {menutitle}
                        </span>
                      ) : (
                        <Link href={href}>
                          <a
                            className={`no-sub ${
                              pathname.includes(href) ? 'active' : ''
                            }`}
                            menutitle={menutitle}
                            target={target}
                          >
                            <span menutitle={menutitle}>{menutitle}</span>
                          </a>
                        </Link>
                      )}
                    </li>
                  </>
                )
              )}
            </ul>
          </nav>
        </Scrollbars>
      )}

      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .solutions-left-wrapper {
            flex: 1;
            height: 100%;
            overflow: hidden;
          }

          .solutions-link-head {
            @include font(15px, 700, -0.3px, 20px, inherit);
          }

          .bar {
            padding: 8px 20px;
            div {
              height: 1px;
              background: rgba(#cbd1d7, 0.4);
            }
          }

          a.solution-nav-link {
            font-weight: 700;

            &.active {
              cursor: default;
              pointer-events: none;
            }
          }

          @media all and (min-width: $resolution_d_min) {
            nav {
              width: 240px;
              padding-top: 32px;
              background: #394551;
              overflow: visible;
            }

            .solutions-link-head {
              color: #ffffff;

              .bar {
                padding-left: 16px;
                padding-right: 16px;
                div {
                  background: rgba(#cbd1d7, 0.2);
                }
              }
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .solutions-nav-scroll {
            width: 100%;
            height: 100%;
            & > div:first-child {
              scroll-behavior: smooth;
              overscroll-behavior: contain;
            }
          }

          .solutions-nav-thumb {
            background-color: #000000 !important;
            @media all and (min-width: $resolution_d_min) {
              background-color: #ffffff !important;
            }
          }

          .solutions-nav {
            p {
              color: $black;
              position: relative;
              display: inline-block;
              vertical-align: top;
              width: 100%;
              height: auto;
              padding: 12px 8px 12px 24px;
              cursor: default;
            }
            a {
              @include font(15px, 700, -0.3px, 20px, $black);
              position: relative;
              display: inline-block;
              vertical-align: top;
              width: 100%;
              height: auto;
              padding: 12px 8px 12px 24px;
              cursor: pointer;

              span {
                position: relative;
                display: inline-block;
                text-align: left;
              }

              &:before {
                display: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0px;
                content: '';
                width: 4px;
                height: 24px;
                margin-top: auto;
                margin-bottom: auto;
                background: transparent;
              }

              @media (hover: hover) {
                &:hover {
                  &:before {
                    display: block;
                    background: $black;
                  }
                }
              }

              &:active {
                &:before {
                  display: block;
                  background: $black;
                }
              }

              &.active {
                cursor: default;
                pointer-events: none;
                &:before {
                  background: $point_1;
                }

                @media (hover: hover) {
                  &:hover {
                    &:before {
                      background: $point_1;
                    }
                  }
                }
                &:active {
                  &:before {
                    background: $point_1;
                  }
                }
              }

              &.no-sub {
                font-weight: 700;
                &.active {
                  background-color: transparent;
                  &:before {
                    display: block;
                  }
                }
              }

              &.has-sub {
                position: relative;
                transition: background-color 0.2s;
                transition-delay: 0.1s;
                font-weight: 700;

                .icn-sub {
                  display: block;
                  position: absolute;
                  top: 6px;
                  right: 8px;
                  content: '';
                  width: 32px;
                  height: 32px;
                  background: url('#{$image_url}/docs/bt_dropdown_small/black/normal@2x.png')
                    center center no-repeat;
                  background-size: cover;
                  transform: rotate(180deg);
                  transform-origin: center center;
                  transition: all 0.5s;
                }

                @media (hover: hover) {
                  &:hover {
                    background-color: #dce1e6;
                    .icn-sub {
                      // background-image: url("#{$image_url}/bt_dropdown_big/hover@2x.png");
                    }

                    &:before {
                      background-color: transparent;
                    }
                  }
                }

                &:active {
                  background-color: #dce1e6;

                  &:before {
                    background-color: transparent;
                  }
                }
              }
            }

            .sub-container {
              max-height: 0px;
              overflow: hidden;
              background: #f2f4f5;
              //transition: all .2s;
              //transform-origin: 0 0;
              opacity: 0;
              transition-delay: 0s;

              ul {
                padding: 8px 0px;
              }

              a {
                padding: 10px 30px 10px 32px;
                line-height: 24px;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: -0.3px;
                color: rgba($black, 0.4);

                &:before {
                  display: block;
                }

                @media (hover: hover) {
                  &:hover {
                    background-color: transparent;
                    color: $black;
                    &:before {
                      left: 0px;
                      background: #2b2c2d;
                    }
                  }
                }

                &:active {
                  background-color: transparent;
                  color: $black;
                  &:before {
                    left: 0px;
                    background: #2b2c2d;
                  }
                }

                &.active {
                  background-color: transparent;
                  color: $black;
                  @media (hover: hover) {
                    &:hover {
                      &:before {
                        background: $point_1;
                      }
                    }
                  }
                  &:active {
                    &:before {
                      background: $point_1;
                    }
                  }
                }
              }

              .between {
                position: relative;
                margin-top: 10px;
                padding: 0 24px;

                &:before {
                  display: block;
                  content: '';
                  position: absolute;
                  top: 0px;
                  left: 24px;
                  right: 24px;
                  height: 1px;
                  background: #cbd1d7;
                }

                .between-title {
                  padding: 10px 8px 0px 8px;
                  @include font(12px, 700, -0.02px, 24px, rgba(#677c92, 0.9));
                  text-transform: uppercase;
                }
              }
            }

            .open {
              .sub-container {
                max-height: 3000px;
                overflow: visible;
                //transition: all 0.5s;
                //transform-origin: 0 0;
                opacity: 1;
              }
              a {
                &.has-sub {
                  font-weight: 700;
                  background: #dce1e6;
                  transition: none;
                  .icn-sub {
                    transform: rotate(0deg);
                  }
                }
              }
            }

            @media all and (min-width: $resolution_d_min) {
              a {
                color: #ffffff;

                &.no-sub {
                  @media (hover: hover) {
                    &:hover {
                      //background-color: #4E6378
                      &:before {
                        background-color: #ffffff;
                      }
                    }
                  }
                  &:active {
                    &:before {
                      background-color: #ffffff;
                    }
                  }
                }

                &.has-sub {
                  font-weight: 700;
                  .icn-sub {
                    transform: rotate(0deg);
                    background-image: url('#{$image_url}/docs/bt_dropdown_small/white/normal@2x.png');
                  }

                  &.active {
                    background-color: #4e6378;
                  }

                  @media (hover: hover) {
                    &:hover {
                      background-color: #4e6378;
                    }
                  }
                  &:active {
                    background-color: #4e6378;
                  }
                }
              }

              .sub-container {
                background-color: #394551;
                ul {
                  padding: 16px 0;
                }

                a {
                  color: rgba(#ffffff, 0.6);
                  padding-right: 24px;

                  @media (hover: hover) {
                    &:hover {
                      color: #ffffff;
                      &:before {
                        background-color: #ffffff;
                      }
                    }
                  }
                  &:active {
                    color: #ffffff;
                    &:before {
                      background-color: #ffffff;
                    }
                  }

                  &.active {
                    color: #ffffff;
                    @media (hover: hover) {
                      &:hover {
                        &:before {
                          background-color: $point_1;
                        }
                      }
                    }
                    &:active {
                      &:before {
                        background-color: $point_1;
                      }
                    }
                  }
                }

                .between {
                  &:before {
                    background-color: rgba(#cbd1d7, 0.2);
                  }

                  .between-title {
                    color: #87a9cb;
                  }
                }
              }

              .open {
                .sub-container {
                  background-color: rgba(#4e6378, 0.3);
                }

                a.has-sub {
                  background-color: #4e6378;
                  .icn-sub {
                    transform: rotate(-180deg);
                  }
                }
              }
            }
          }
        `}
      </style>
    </div>
  );
};

export default MenuLinks;
