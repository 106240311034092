import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { useAuth } from '@context/UseAuth';
import { useTranslation } from 'next-i18next';

const AuthProtectedPage = ({ children }) => {
  const { t } = useTranslation('common');
  const { pathname } = useRouter();
  const { user, isUserCompleted, authError, setUserToken } = useAuth();

  useEffect(() => {
    if (isUserCompleted) {
      if (!user) {
        setUserToken(null);
        document.location.replace('/start');
      }

      if (user?.is_first_time_onboard) {
        document.location.replace('/start/tell-us-about-you');
      }
    }
  }, [isUserCompleted, user, pathname]);

  useEffect(() => {
    if (authError) {
      window.alert(t('common:error-auth'));
      setUserToken(null);
      document.location.replace('/start');
    }
  }, [authError]);

  return isUserCompleted && user && !user?.is_first_time_onboard ? (
    <>{children}</>
  ) : (
    <div />
  );
};

AuthProtectedPage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProtectedPage;
