import React from 'react';
import { WEEKDAYS_SHORT } from '@js/constants';
import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { ko, th, en } from 'date-fns/locale';

const DatePicker = ({
  value,
  onChange,
  disabledDays,
  calendarWidth,
  showPreview = false,
  className,
  readonly,
  canChangeMonth = true,
  mode,
  selected,
  modifiers,
  toMonth,
  fromMonth,
  fromDate,
  toDate,
  language = 'en',
  onDayMouseEnter = () => {},
  onMonthChange = () => {},
}) => {
  const availableLocale = {
    ko,
    th,
    en,
  };

  return (
    <div
      className={`calendar-wrapper ${className} ${readonly ? 'readonly' : ''} ${
        mode === 'range' ? 'range' : ''
      }`}
      onClick={(e) => {
        e.preventDefault();
      }}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    >
      {showPreview && (
        <div className="date-preview">
          <p>Selected Date</p>
          <h1>
            {mode === 'range'
              ? selected
                ? `${
                    selected.from ? format(selected.from, 'yyyy/MM/dd') : '-'
                  } ${
                    selected.to ? `- ${format(selected.to, 'yyyy/MM/dd')}` : ''
                  }`
                : '-'
              : value
              ? format(new Date(value), 'yyyy/MM/dd')
              : '-'}
          </h1>
        </div>
      )}
      <div className="flex justify-center">
        {readonly && <div className="z-10 absolute inset-0" />}
        <DayPicker
          showOutsideDays
          mode={mode || 'single'}
          selected={mode === 'range' ? selected : new Date(value)}
          onSelect={(day, modifiers) => {
            if (modifiers.disabled) {
              return;
            }
            onChange(day);
          }}
          className="range-calendar w-full"
          numberOfMonths={1}
          disabled={disabledDays}
          weekdaysShort={WEEKDAYS_SHORT}
          onMouseLeave={() => {}}
          canChangeMonth={false}
          toMonth={toMonth}
          modifiers={modifiers}
          fromMonth={fromMonth}
          onDayMouseEnter={onDayMouseEnter}
          fromDate={fromDate}
          toDate={toDate}
          defaultMonth={readonly ? new Date('2023/08/01') : undefined}
          formatters={
            language !== 'en'
              ? {}
              : {
                  formatWeekdayName: (dateObj) =>
                    dateObj.toString().slice(0, 1),
                  formatCaption: (dateObj) => format(dateObj, 'yyyy.M'),
                }
          }
          locale={availableLocale[language]}
          onMonthChange={onMonthChange}
        />
      </div>

      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .calendar-wrapper {
            padding-bottom: 20px;

            .date-preview {
              padding: 10px;
              border-bottom: 1px solid #ebedef;
              h1 {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
              }
              p {
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                color: #aaabab;
                margin-bottom: 4px;
              }
            }
          }
        `}
      </style>

      <style jsx global>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .calendar-wrapper {
            .range-calendar {
              font-family: Open sans;
              margin: 12px 0;

              .rdp-months {
                justify-content: center;
              }

              .rdp-month {
                width: 100%;

                .rdp-table {
                  width: 100%;
                }
              }

              .rdp-caption {
                position: relative;
                display: flex;
                justify-content: center;
                margin-bottom: 8px;

                .rdp-caption_label {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 22px; /* 183.333% */
                }

                .rdp-nav {
                  position: absolute;
                  width: 100%;
                  left: 0;
                  right: 0;
                  display: flex;
                  justify-content: space-between;

                  .rdp-nav_button {
                    width: 30px;
                    height: 30px;

                    &.rdp-nav_button_previous {
                      //left: 26px;
                      background: url(#{$image_url}/bt_back_to_page/normal@2x.png)
                        center center / cover;
                      transform: rotate(0deg);

                      svg {
                        display: none;
                      }

                      &:hover {
                        border: none;
                      }

                      &:disabled {
                        display: block;
                        background: url(#{$image_url}/bt_back_to_page/inactive@2x.png)
                          center center / cover;
                        transform: rotate(0deg);
                        cursor: default;
                        pointer-events: none;
                      }
                    }

                    &.rdp-nav_button_next {
                      //right: 26px;
                      background: url(#{$image_url}/bt_back_to_page/normal@2x.png)
                        center center / cover;
                      transform: rotate(-180deg);

                      svg {
                        display: none;
                      }

                      &:hover {
                        border: none;
                      }

                      &:disabled {
                        display: block;
                        background: url(#{$image_url}/bt_back_to_page/inactive@2x.png)
                          center center / cover;
                        transform: rotate(-180deg);
                        cursor: default;
                        pointer-events: none;
                      }
                    }
                  }
                }
              }

              .rdp-head_row {
                display: flex;
                justify-content: space-around;
                align-items: center;
                border-bottom: 1px solid rgb(235, 237, 239);
                .rdp-head_cell {
                  width: 32px;
                  height: 32px;
                  padding: 8px 4px;
                  font-weight: 600;
                }
              }

              .rdp-tbody {
                .rdp-row {
                  display: flex;

                  .rdp-cell {
                    &:first-child,
                    &:last-child {
                      .rdp-day {
                        opacity: 0.5;
                      }
                    }

                    &:has(> .rdp-day_outside) {
                      opacity: 0.25;
                      .rdp-day_outside {
                        opacity: 1;
                      }
                    }

                    &:has(> .rdp-day_range_middle),
                    &:has(> .rdp-day_selected) {
                      opacity: 1;
                    }

                    width: 32px;
                    height: 32px;
                    margin: 2px 0;
                    .rdp-day {
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 166.667% */

                      &:hover {
                        border: none;
                        background-color: #ebedef;
                      }
                    }

                    .rdp-day_today {
                      color: inherit;
                      font-weight: 700;
                    }

                    .rdp-day_selected {
                      background-color: #f7b716;
                      border: none;
                      color: #ffffff;
                      :hover {
                        background-color: #f7b716;
                      }
                    }

                    .rdp-day_range_start,
                    .rdp-day_range_end {
                      border-radius: 100%;

                      &:hover {
                        background-color: #f7b716;
                      }
                    }

                    .rdp-day_range_middle {
                      background: #fff7e3;
                      color: #2b2c2d;
                      &:hover {
                        background-color: #fff7e3;
                      }
                    }

                    .rdp-button {
                      height: 32px;
                      width: 32px;
                    }

                    .rdp-day_disabled {
                      color: rgba(43, 44, 45, 0.4);
                      pointer-events: none;
                    }

                    &:has(> .rdp-day_selected) {
                      position: relative;
                      border-radius: 0px;
                      color: $black !important;
                      z-index: 2 !important;

                      &:before {
                        content: ' ';
                        display: none;
                        position: absolute;
                        top: 0px;
                        bottom: 0px;
                        left: 0px;
                        width: 32px;
                        height: 32px;
                        background-color: #fff7e3;
                        z-index: -1;
                      }
                    }
                    &:has(> .rdp-day_range_start) {
                      &:before {
                        border-top-left-radius: 50%;
                        border-bottom-left-radius: 50%;
                      }
                    }
                    &:has(> .rdp-day_range_end) {
                      &:before {
                        border-top-right-radius: 50%;
                        border-bottom-right-radius: 50%;
                        width: 32px;
                      }
                    }
                  }
                }
              }
            }
            &.readonly {
              .range-calendar {
                .rdp-day_selected {
                  background-color: #4e6378 !important;
                }

                .rdp-day_range_middle {
                  background: #ebedef !important;
                  color: #2b2c2d !important;
                }

                .rdp-cell {
                  &:has(> .rdp-day_selected) {
                    &:before {
                      background-color: #ebedef !important;
                    }
                  }

                  .rdp-day {
                    pointer-events: none;
                  }
                }
              }
            }

            &.range {
              .range-calendar {
                .rdp-tbody {
                  .rdp-row {
                    .rdp-cell {
                      &:has(> .rdp-day_selected) {
                        &:before {
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
      </style>
    </div>
  );
};

export default DatePicker;
