import React, { useEffect, useState } from 'react';
import ScopePopupWrapper from '@components/builder/ScopePopupWrapper';
import LabelInputWrapper from '@components/common/LabelInputWrapper';
import DropdownSelect from '@components/common/DropdownSelect';
import emailList from '@js/json/emailvalidators.json';
import axios from 'axios';
import { API_URLS } from '@js/constants';
import { useAuth } from '@context/UseAuth';
import { useError } from '@context/UseError';
import { useToast } from '@context/UseToast';
import PageSpinner from '@components/common/PageSpinner';
import DropdownInput from '@components/common/DropdownInput';
import { validateEmail } from '@js/helper';
import {
  COMPANY_INDUSTRY_OPTIONS,
  countryOptions,
  DESIRED_PLAN_OPTIONS,
  expectedTransactionNumberOptions,
  knowMeFromOptions,
  purposeOptions,
  SUBSCRIPTION_PLAN,
  teamSizeOptions,
  validateCompanyWebsite,
} from '@js/constants/tellusform';

const TellUsPopup = ({ onClose }) => {
  const { authHeader, subscription } = useAuth();
  const { errorWithAuth } = useError();
  const { toastAlert } = useToast();
  const [errorList, setErrorList] = useState(null);
  const [errorFocus, setErrorFocus] = useState(null);
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [teamSize, setTeamSize] = useState(null);
  const [country, setCountry] = useState('');
  const [isExperienced, setIsExperienced] = useState('no');
  const [businessPaintPoints, setBusinessPaintPoints] = useState('');
  const [purpose, setPurpose] = useState(null);
  const [purposeOther, setPurposeOther] = useState('');
  const [showPurposeOther, setShowPurposeOther] = useState(false);
  const [desiredPlan, setDesiredPlan] = useState(null);
  const [companyIndustry, setCompanyIndustry] = useState(null);
  const [expectedTransactionNumber, setExpectedTransactionNumber] =
    useState(null);
  const [knowMeFrom, setKnowMeFrom] = useState(null);
  const [knowMeFromOther, setKnowMeFromOther] = useState('');
  const [showKnowMeFromOther, setShowKnowMeFromOther] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Reset focus state to be able to re-focusing
  useEffect(
    () => () => {
      setErrorFocus([]);
    },
    [errorList]
  );

  const validation = () => {
    const err = {
      plan: {
        required: true,
      },
      fullname: {
        required: true,
      },
      email: {
        required: true,
      },
      companyName: {
        required: true,
      },
      companyWebsite: {
        required: true,
      },
      teamSize: {
        required: true,
      },
      country: {
        required: true,
      },
      purpose: {
        required: true,
      },
      purposeOther: {
        required: false,
      },
      expectedTransactionNumber: {
        required: true,
      },
      knowMeFrom: {
        required: true,
      },
      knowMeFromOther: {
        required: false,
      },
      painPoints: {
        required: true,
      },
    };

    if (desiredPlan === 'free') {
      err.email.required = false;
      err.companyName.required = false;
      err.companyWebsite.required = false;
    }

    if (knowMeFrom?.value === 'Other') {
      err.knowMeFrom.required = false;
      err.knowMeFromOther.required = true;
    }

    if (purpose?.value === 'Other') {
      err.purpose.required = false;
      err.purposeOther.required = true;
    }

    let focusValue = '';

    if (desiredPlan) {
      err.plan.required = false;
    } else if (!focusValue) {
      focusValue = 'plan';
    }

    if (fullname) {
      err.fullname.required = false;
    } else if (!focusValue) {
      focusValue = 'fullname';
    }

    if (
      email &&
      validateEmail(email) &&
      !emailList.includes(email.substring(email.lastIndexOf('@') + 1))
    ) {
      err.email.required = false;
    } else if (!focusValue) {
      focusValue = 'email';
    }

    if (companyName) {
      err.companyName.required = false;
    } else if (!focusValue) {
      focusValue = 'companyName';
    }

    if (companyWebsite && validateCompanyWebsite(companyWebsite)) {
      err.companyWebsite.required = false;
    } else if (!focusValue) {
      focusValue = 'companyWebsite';
    }

    if (teamSize) {
      err.teamSize.required = false;
    } else if (!focusValue) {
      focusValue = 'teamSize';
    }

    if (country) {
      err.country.required = false;
    } else if (!focusValue) {
      focusValue = 'country';
    }

    if (purpose) {
      err.purpose.required = false;
    } else if (!focusValue) {
      focusValue = 'purpose';
    }

    if (purposeOther) {
      err.purposeOther.required = false;
    } else if (!focusValue) {
      focusValue = 'purposeOther';
    }

    if (expectedTransactionNumber) {
      err.expectedTransactionNumber.required = false;
    } else if (!focusValue) {
      focusValue = 'expectedTransactionNumber';
    }

    if (knowMeFrom?.value) {
      err.knowMeFrom.required = false;
    } else if (!focusValue) {
      focusValue = 'knowMeFrom';
    }

    if (knowMeFromOther) {
      err.knowMeFromOther.required = false;
    } else if (!focusValue) {
      focusValue = 'knowMeFromOther';
    }

    if (businessPaintPoints) {
      err.painPoints.required = false;
    } else if (!focusValue) {
      focusValue = 'painPoints';
    }

    const value = [focusValue];
    setErrorList(err);
    setErrorFocus([...value]);

    let isError = false;
    Object.entries(err).forEach(([key, errValue]) => {
      if (errValue.required) isError = true;
    });

    return !isError;
  };

  const handleFullnameChange = (value) => {
    setFullname(value);
    if (errorList) {
      const errors = errorList;
      errors.fullname.required = false;
      setErrorList(errors);
    }
  };

  const handleBusinessPainPointsChange = (value) => {
    setBusinessPaintPoints(value);
    if (errorList) {
      const errors = errorList;
      errors.painPoints.required = false;
      setErrorList(errors);
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    if (errorList) {
      const errors = errorList;
      errors.email.required = false;
      setErrorList(errors);
    }
  };

  const handleCompanyNameChange = (value) => {
    setCompanyName(value);
    if (errorList) {
      const errors = errorList;
      errors.companyName.required = false;
      setErrorList(errors);
    }
  };

  const handleCompanyWebsiteChange = (value) => {
    setCompanyWebsite(value);
    if (errorList) {
      const errors = errorList;
      errors.companyWebsite.required = false;
      setErrorList(errors);
    }
  };

  const handlePlanChange = (value) => {
    setDesiredPlan(value);
    if (errorList) {
      const errors = errorList;
      errors.plan.required = false;
      setErrorList(errors);
    }
  };

  const handleTeamSizeChange = (value) => {
    setTeamSize(value);
    if (errorList) {
      const errors = errorList;
      errors.teamSize.required = false;
      setErrorList(errors);
    }
  };

  const handleCountryChange = (value) => {
    setCountry(value);
    if (errorList) {
      const errors = errorList;
      errors.country.required = false;
      setErrorList(errors);
    }
  };

  const handleIsExperiencedChange = (value) => {
    setIsExperienced(value);
  };

  const handlePurposeChange = (value) => {
    if (value?.value === 'Other') {
      setShowPurposeOther(true);
    } else {
      setPurposeOther('');
      setShowPurposeOther(false);
    }
    setPurpose(value);
    if (errorList) {
      const errors = errorList;
      errors.purpose.required = false;
      setErrorList(errors);
    }
  };

  const handlePurposeOtherChange = (value) => {
    setPurposeOther(value.target.value);
    if (errorList) {
      const errors = errorList;
      errors.purposeOther.required = false;
      setErrorList(errors);
    }
  };

  const handleExpectedTransactionNumberChange = (value) => {
    setExpectedTransactionNumber(value);
    if (errorList) {
      const errors = errorList;
      errors.expectedTransactionNumber.required = false;
      setErrorList(errors);
    }
  };

  const handleKnowMeFromChange = (value) => {
    if (value?.value === 'Other') {
      setShowKnowMeFromOther(true);
    } else {
      setKnowMeFromOther('');
      setShowKnowMeFromOther(false);
    }
    setKnowMeFrom(value);
    if (errorList) {
      const errors = errorList;
      errors.knowMeFrom.required = false;
      setErrorList(errors);
    }
  };

  const handleKnowMeFromOtherChange = (value) => {
    setKnowMeFromOther(value.target.value);
    if (errorList) {
      const errors = errorList;
      errors.knowMeFromOther.required = false;
      setErrorList(errors);
    }
  };

  const handleCompanyIndustryChange = (value) => {
    setCompanyIndustry(value);
  };

  const handleSubmit = () => {
    const isFormValid = validation();

    if (!isFormValid) {
      return;
    }

    const data = {
      desired_plan: desiredPlan?.key, // choose one from ["free", "enterprise", "partner"]
      full_name: fullname,
      company_name: companyName,
      company_website: companyWebsite,
      company_industry: companyIndustry?.value ?? '',
      number_of_employees: teamSize.value,
      country,
      is_experienced: isExperienced, // "No" or "Yes"
      pain_points: businessPaintPoints,
      purpose_of_mind_expression: purposeOther || purpose.value,
      expected_number_of_transaction: expectedTransactionNumber.value,
      know_me_from: knowMeFromOther || knowMeFrom?.value,
    };

    if (desiredPlan !== SUBSCRIPTION_PLAN.FREE) {
      data.email = email;
    }

    if (subscription?.plan) {
      data.current_plan = subscription.plan;
    }

    setIsLoading(true);
    axios({
      method: 'post',
      url: API_URLS.SUBSCRIPTION_CONSULTING,
      data,
      headers: authHeader(),
    })
      .then(() => {
        onClose();
        toastAlert('confirm', `Thank you! We will reach out to you shortly.`);
      })
      .catch((e) => {
        errorWithAuth(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filteredOptions = DESIRED_PLAN_OPTIONS[subscription.plan];

  return (
    <ScopePopupWrapper
      className="popup-scope"
      title="Tell us about you"
      onClose={onClose}
      testId="tell-us-popup"
    >
      <div className="popup-body">
        <div>
          <ul>
            <li className="content-frm-row">
              <DropdownSelect
                id="plan"
                label="Change Plan"
                value={desiredPlan}
                options={filteredOptions}
                onChange={handlePlanChange}
                isInvalid={errorList?.plan?.required}
                errMsg="Please choose a selection."
                autoFocus={errorFocus?.includes('plan')}
              />
            </li>
            <li className="content-frm-row">
              <LabelInputWrapper
                label="Full Name"
                htmlFor="name"
                formControlOptions={{
                  type: 'text',
                  id: 'name',
                  className: 'input-text',
                  name: 'full_name',
                  minLength: 1,
                  maxLength: 100,
                  placeholder: 'Full name',
                  value: '',
                  onChange: handleFullnameChange,
                }}
                isInvalid={errorList?.fullname?.required}
                errMsg="Please enter a full name."
                autoFocus={errorFocus?.includes('fullname')}
              />
            </li>
            <li className="content-frm-row">
              {desiredPlan === 'free' ? (
                <>
                  <div className="lbl-container mb-2 leading-4">
                    <span className="lbl-text ">
                      Do you have any technical experience?
                    </span>
                  </div>
                  <div className="radio">
                    <div className="radio-body">
                      <div className="radio-values">
                        <label htmlFor="is-experienced-yes-1" className="mr-6">
                          <input
                            type="radio"
                            name="isExperienced"
                            id="is-experienced-yes-1"
                            className="round"
                            value="yes"
                            checked={isExperienced === 'yes'}
                            onChange={() => handleIsExperiencedChange('yes')}
                          />
                          <span>Yes</span>
                        </label>
                        <label htmlFor="is-experienced-no-1">
                          <input
                            type="radio"
                            name="isExperienced"
                            id="is-experienced-no-1"
                            className="round"
                            value="no"
                            checked={isExperienced === 'no'}
                            onChange={() => handleIsExperiencedChange('no')}
                          />
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <LabelInputWrapper
                  label="Business Email"
                  htmlFor="work-email"
                  formControlOptions={{
                    type: 'text',
                    id: 'work-email',
                    className: 'input-text',
                    name: 'email',
                    minLength: 1,
                    maxLength: 100,
                    placeholder: 'Email',
                    value: '',
                    onChange: handleEmailChange,
                  }}
                  isInvalid={errorList?.email?.required}
                  errMsg="Please enter a valid business email."
                  autoFocus={errorFocus?.includes('email')}
                />
              )}
            </li>
            <li className="content-frm-row">
              <LabelInputWrapper
                label="Company Name"
                htmlFor="company-name"
                formControlOptions={{
                  type: 'text',
                  id: 'company-name',
                  className: 'input-text',
                  name: 'company_name',
                  minLength: 1,
                  maxLength: 100,
                  placeholder: 'Company name',
                  value: companyName,
                  onChange: handleCompanyNameChange,
                }}
                isInvalid={errorList?.companyName?.required}
                errMsg="Please enter a company name."
                autoFocus={errorFocus?.includes('companyName')}
              />
            </li>
            <li className="content-frm-row">
              <LabelInputWrapper
                label="Company Website"
                htmlFor="company-website"
                formControlOptions={{
                  type: 'text',
                  id: 'company-website',
                  className: 'input-text',
                  name: 'company_website',
                  minLength: 1,
                  maxLength: 50,
                  placeholder: 'www.example.com',
                  value: companyWebsite,
                  onChange: handleCompanyWebsiteChange,
                }}
                isInvalid={errorList?.companyWebsite?.required}
                errMsg="Please enter a company website."
                autoFocus={errorFocus?.includes('companyWebsite')}
              />
            </li>
            <li className="content-frm-row">
              <div className="lbl-container mb-2 leading-4">
                <span className="lbl-text">
                  What is your company’s industry?{' '}
                  <span className="font-normal">(Optional)</span>
                </span>
              </div>
              <DropdownSelect
                id="company-industry"
                value={companyIndustry}
                options={COMPANY_INDUSTRY_OPTIONS}
                onChange={handleCompanyIndustryChange}
              />
            </li>
            <li className="content-frm-row">
              <DropdownSelect
                id="number-employees"
                label="Number of Employees"
                value={teamSize}
                options={teamSizeOptions}
                onChange={handleTeamSizeChange}
                isInvalid={errorList?.teamSize?.required}
                errMsg="Please choose a selection."
                autoFocus={errorFocus?.includes('teamSize')}
              />
            </li>
            <li className="content-frm-row">
              <DropdownInput
                id="country"
                label="Country"
                value={country}
                options={countryOptions}
                onClick={() => undefined}
                onChange={handleCountryChange}
                isInvalid={errorList?.country?.required}
                errMsg="Please enter a country."
                autoFocus={errorFocus?.includes('country')}
                inputProps={{
                  placeholder: 'Country',
                }}
                size="md"
              />
            </li>
            {desiredPlan !== 'free' && (
              <li className="content-frm-row">
                <div className="lbl-container mb-2 leading-4">
                  <span className="lbl-text ">
                    Previous experience or history as a Service Provider or
                    Reseller
                  </span>
                </div>
                <div className="radio">
                  <div className="radio-body">
                    <div className="radio-values">
                      <label htmlFor="is-experienced-yes" className="mr-6">
                        <input
                          type="radio"
                          name="isExperienced"
                          id="is-experienced-yes"
                          className="round"
                          value="yes"
                          checked={isExperienced === 'yes'}
                          onChange={() => handleIsExperiencedChange('yes')}
                        />
                        <span>Yes</span>
                      </label>
                      <label htmlFor="is-experienced-no">
                        <input
                          type="radio"
                          name="isExperienced"
                          id="is-experienced-no"
                          className="round"
                          value="no"
                          checked={isExperienced === 'no'}
                          onChange={() => handleIsExperiencedChange('no')}
                        />
                        <span>No</span>
                      </label>
                    </div>
                  </div>
                </div>
              </li>
            )}
            <li className="content-frm-row">
              <DropdownSelect
                id="purpose-me"
                label="How would you like to use Mind Expression?"
                value={purpose}
                options={purposeOptions}
                onChange={handlePurposeChange}
                isInvalid={errorList?.purpose?.required}
                errMsg="Please choose a selection."
                autoFocus={errorFocus?.includes('purpose')}
              />
              {showPurposeOther && (
                <>
                  <input
                    name="purpose-me-other"
                    className={`input-text mt-4 ${
                      errorList?.purposeOther?.required ? 'error' : ''
                    }`}
                    placeholder="Other"
                    onChange={handlePurposeOtherChange}
                  />
                  {errorList?.purposeOther?.required && (
                    <div className="lbl-error">Enter a value</div>
                  )}
                </>
              )}
            </li>
            <li className="content-frm-row">
              <LabelInputWrapper
                label="Describe your business pain points"
                htmlFor="pain_points"
                formControlOptions={{
                  type: 'text',
                  id: 'pain_points',
                  className: 'input-text',
                  name: 'pain_points',
                  minLength: 1,
                  maxLength: 500,
                  placeholder: 'Business pain points',
                  value: '',
                  onChange: handleBusinessPainPointsChange,
                }}
                isInvalid={errorList?.painPoints?.required}
                errMsg="Please let us know your business pain points."
                autoFocus={errorFocus?.includes('painPoints')}
              />
            </li>
            <li className="content-frm-row">
              <DropdownSelect
                id="number-transactions"
                label="Expected number of transactions"
                value={expectedTransactionNumber}
                options={expectedTransactionNumberOptions}
                onChange={handleExpectedTransactionNumberChange}
                isInvalid={errorList?.expectedTransactionNumber?.required}
                errMsg="Please choose a selection."
                autoFocus={errorFocus?.includes('expectedTransactionNumber')}
              />
            </li>
            <li className="content-frm-row">
              <DropdownSelect
                id="hear-about-us"
                label="How did you hear about us?"
                value={knowMeFrom}
                options={knowMeFromOptions}
                onChange={handleKnowMeFromChange}
                isInvalid={errorList?.knowMeFrom?.required}
                errMsg="Please choose a selection."
                autoFocus={errorFocus?.includes('knowMeFrom')}
              />
              {showKnowMeFromOther && (
                <>
                  <input
                    name="know-me-form-other"
                    className={`input-text mt-4 ${
                      errorList?.knowMeFromOther?.required ? 'error' : ''
                    }`}
                    placeholder="Other"
                    onChange={handleKnowMeFromOtherChange}
                  />
                  {errorList?.knowMeFromOther?.required && (
                    <div className="lbl-error">Enter a value</div>
                  )}
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="popup-footer">
        <button
          type="button"
          className="btn-secondary btn btn-md mr-4"
          onClick={onClose}
          title="Submit"
          data-testid="cancel-tell-us-form"
        >
          Cancel
        </button>
        <button
          type="button"
          tabIndex={0}
          className="primary btn btn-md"
          onClick={handleSubmit}
          title="Submit"
          data-testid="submit-tell-us-form"
        >
          Submit
        </button>
      </div>
      {isLoading && <PageSpinner />}
      <style jsx>
        {`
          @import './src/sass/vars.scss';
          @import './src/sass/mixins.scss';

          .popup-footer {
            justify-content: flex-end !important;
          }
          .radio {
            height: 20px;
          }
          .lbl-error {
            letter-spacing: -0.4px;
            line-height: 22px;
            font-size: 11px;
            color: #ff3535;
            margin-top: 4px;
            font-weight: 600;
          }

          .content-frm-row:not(:first-child) {
            margin-top: 16px;
          }

          :global(.popup-body) {
            overflow: auto;
          }

          input[type='radio'].round {
            appearance: none;
            width: 16px;
            height: 16px;
            background: #f2f4f5;
            border-radius: 16px;
            border: 1px solid #cbd1d7;
            position: relative;
            cursor: pointer;
            outline: none;
            z-index: 0;
            margin-top: 1px;

            &:checked {
              background: #f7b716;
              border-color: #f7b716;
              &:after {
                background: #ffffff;
              }

              & + span {
                font-weight: 600;
              }
            }

            &:after {
              position: absolute;
              content: '';
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: transparent;
              top: 3px;
              left: 3px;
            }

            & + span {
              margin-left: 8px;
              margin-top: 4px;
              @include font(12px, 400, -0.3, 16px, $black);
              display: inline-block;
              vertical-align: top;
              cursor: pointer;
            }

            &[readonly] {
              pointer-events: none !important;
            }

            &[disabled],
            &[disabled='disabled'],
            &[disabled='true'] {
              opacity: 0.2;
              cursor: default;
            }
          }
          .radio-values label {
            height: 10px;
          }
          :global(.lbl-container .lbl-error),
          :global(.dropdown-select .lbl-error) {
            font-size: 11px;
            color: #ff3535;
            margin-top: 4px;
            font-weight: 600;
            letter-spacing: -0.4px;
            line-height: 22px;
          }

          :global(.lbl-container .lbl-text) {
            font-size: 12px;
          }

          .input-text,
          :global(input[type='text']),
          :global(input[type='email']),
          :global(textarea) {
            font-family: $font_family;
            display: block;
            width: 100%;
            height: 40px;
            border-radius: 2px;
            border: solid 1px $grey;
            background-color: $white_grey;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: -0.3px;
            color: $black;
            padding: 0 12px;

            &.size-md {
              height: 32px;
            }

            &::placeholder {
              font-size: 14px;
              font-weight: 400;
              letter-spacing: -0.3px;
              color: $black;
              opacity: 0.4;
            }

            &:hover {
              border-color: $black;
              & + .lbl-text {
                opacity: 1;
              }
            }

            &:focus {
              border-color: $black;
              background-color: $white;

              & + .lbl-text {
                opacity: 1;
              }
            }

            &.error,
            &.invalid {
              border-color: $negative;
              background-color: #ffffff;
            }

            &[readonly],
            &[readonly]:focus {
              border-color: $grey;
              background-color: $white_grey;
              cursor: default;
            }

            &[readonly]:hover {
              border-color: $grey;
              cursor: default;
            }
          }
        `}
      </style>
    </ScopePopupWrapper>
  );
};

export default TellUsPopup;
