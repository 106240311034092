import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';

const Feedback = ({ className, children, style, isDisabled, maxWidth = '240' }) => {
  const ref = useRef();
  const bubbleRef = useRef();
  const [isHover, setIsHover] = useState(false);
  const [isAni, setIsAni] = useState(false);
  const [offset, setOffset] = useState({
    top: 0,
    left: 0,
    right: 'auto',
    bottom: 'auto',
    width: 'auto',
  });

  const getWidth = () =>
    Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );

  const getHeight = () =>
    Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );

  const onMouseEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDisabled) {
      setIsHover(true);
    }
  };

  const onMouseLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsHover(false);
  };

  const handleScroll = (event) => {
    setIsHover(false);
  };

  useEffect(() => {
    if (ref.current && isHover) {
      const gap = 28;
      const { current } = ref;
      const { current: bubbleCurrent } = bubbleRef;
      const bubbleWidth = bubbleCurrent.clientWidth + 5;
      const bubbleHeight = bubbleCurrent.clientHeight;
      const bWidth = getWidth();
      const contentWidth = bWidth;
      const contentHeight = getHeight();
      const rootScroll = document.querySelector('.builder-body');
      const rootPopup = document.querySelector('.popup-default');
      const popup = document.querySelector('.popup-default .popup-content');
      const isOnPopup = popup && popup.contains(current);
      // let offsetTop = current.getBoundingClientRect().top + window.scrollY + gap;
      // let offsetLeft = current.getBoundingClientRect().left + window.scrollX;
      let offsetTop = current.getBoundingClientRect().top + gap;
      let offsetLeft = current.getBoundingClientRect().left;
      let offsetRight = 'auto';
      let offsetBottom = 'auto';

      /* if (isOnPopup) {
        const popPos = popup.getBoundingClientRect();
        offsetTop = current.getBoundingClientRect().top;
        offsetLeft = current.getBoundingClientRect().left;
      } */

      if (offsetLeft + bubbleWidth >= contentWidth) {
        offsetRight = contentWidth - offsetLeft - 16;
        offsetLeft = 'auto';
      }

      if (offsetTop + bubbleHeight >= contentHeight) {
        offsetBottom =
          contentHeight - current.getBoundingClientRect().bottom + gap; // + (bubbleHeight / 2);
        offsetTop = 'auto';
      }

      setOffset({
        top: offsetTop,
        left: offsetLeft,
        right: offsetRight,
        bottom: offsetBottom,
        width: bubbleWidth,
      });
      // current.focus();
      setIsAni(true);
    } else {
      setIsAni(false);
    }
  }, [ref.current, isHover]);

  useEffect(() => {
    if (isHover) {
      window.addEventListener('scroll', handleScroll, true);
      window.addEventListener('resize', handleScroll, true);
    } else {
      window.removeEventListener('resize', handleScroll, true);
      window.removeEventListener('scroll', handleScroll, true);
    }
    return () => {
      window.removeEventListener('resize', handleScroll, true);
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [isHover]);

  return (
    <span
      // role="button"
      // tabIndex={0}
      ref={ref}
      style={{
        ...style,
        cursor: isDisabled ? 'default' : 'pointer',
        // opacity: (isDisabled) ? 0.4 : 1,
      }}
      className="shrink-0"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isHover && (
        <Portal>
          <span ref={bubbleRef} className="feedback-bubble hidden-bubble">
            <div>{children}</div>
          </span>
          <div
            className={`feedback-bubble ${className} ${
              offset.left === 'auto' ? 'right' : ''
            } ${offset.top === 'auto' ? 'bottom' : ''} ${isAni ? 'show' : ''}`}
            style={{
              left: offset.left,
              top: offset.top,
              right: offset.right,
              bottom: offset.bottom,
              width: offset.width,
            }}
          >
            <div>{children}</div>
            <div className="arrow" />
          </div>
        </Portal>
      )}
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          span {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: 16px;
            height: 16px;
            @include font(11px, 700, -0.1px, 12px, $white);
            background: url('#{$image_url}/bt_feedback@2x.png') center center
              no-repeat;
            background-size: cover;
            border-radius: 100%;
            cursor: pointer;
            overflow: visible;
          }

          .feedback-bubble {
            position: fixed;
            top: 0;
            left: 0;
            display: inline-block;
            background: #25303a;
            border-radius: 2px;
            @include font(12px, 600, -0.3px, 17px, $white !important);
            padding: 10px;
            width: auto;
            max-width: ${maxWidth}px;
            height: auto;
            z-index: 110000;
            text-align: left;
            word-break: keep-all;
            $du: 0.3s;
            opacity: 0;
            transition: opacity $du;

            &.show {
              opacity: 1;
              transition: opacity $du;
            }
          }

          .hidden-bubble {
            position: absolute;
            top: -99999px;
            left: -99999px;
            visibility: hidden;
            opacity: 0;
          }

          .arrow {
            position: absolute;
            top: 0px;
            left: 16px;
            transform: scale(-1, -1);
            transform-origin: center;

            &:before {
              border-left: 8px solid transparent;
              border-top: 9px solid #25303a;
              content: '';
              position: absolute;
            }

            &:after {
              border-left: 6px solid transparent;
              border-top: 6px solid #25303a;
              content: '';
              position: absolute;
              top: 1px;
              left: 2px;
            }
          }

          .feedback-bubble {
            &.right {
              .arrow {
                left: auto;
                right: 16px;
                transform: scale(1, -1);
              }
            }

            &.bottom {
              .arrow {
                top: auto;
                bottom: -9px;
                &:before {
                  border-bottom: 9px solid #25303a;
                  border-top: none;
                }
                &:after {
                  border-bottom: 6px solid #25303a;
                  border-top: none;
                }
              }
            }
          }

          .feedback-bubble.others {
            background: $black;

            .arrow {
              &:before,
              &:after {
                border-top-color: $black;
              }
            }

            &.bottom {
              &:before,
              &:after {
                border-bottom-color: $black;
              }
            }
          }
        `}
      </style>
    </span>
  );
};

Feedback.propTypes = {
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
};

Feedback.defaultProps = {
  style: {},
  isDisabled: false,
};

export default Feedback;
