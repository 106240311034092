import React, { useCallback, useEffect, useState } from 'react';
import { useBuilder } from '@context/UseBuilder';
import { useAuth } from '@context/UseAuth';
import {
  builder,
  addGlobalNotification,
  addBuilderTask,
  setForceCallGetCurrentTask,
  removeAllGlobalNotification,
  removeAllBuilderTasks,
} from '@js/store/slices/builderSlice';
import {
  knowledge,
  setProductAttributes,
  setDefaultAttributes,
} from '@js/store/slices/knowledgeSlice';
import ToastContainer from '@components/builder/ToastContainer';
import BuilderToastMessage from '@components/common/BuilderToastMessage';
import BuilderNotification from '@components/common/BuilderNotification';
import { useSelector, useDispatch } from 'react-redux';
import { API_URLS, GLOBAL_NOTIFICATION, IMPORT_STATUS } from '@js/constants';
import { useRouter } from 'next/router';
import axios from 'axios';
import { useError } from '@context/UseError';
import ChangePlanButton from '@components/builder/ChangePlanButton';
import { useCookies } from 'react-cookie';
import getViewPermissions from '@js/utils/getViewPermissions';

const IS_CLOSED_CHANGE_PLAN_WARNING = 'ME_is_closed_change_plan_warning';
const BuilderFooter = () => {
  const env = process.env.ENV || 'development';
  const { toastList, notificationList } = useBuilder();
  const [cookies, setCookie] = useCookies([IS_CLOSED_CHANGE_PLAN_WARNING]);
  const router = useRouter();
  const { pathname, asPath } = router;
  const dispatch = useDispatch();
  const { authHeader, isUserCompleted, token, subscription, user } = useAuth();
  const { errorWithAuth } = useError();
  const { toastAlert, toastNotification } = useBuilder();
  const { builderTasks, builderGlobalNotification, forceCallGetCurrentTask } =
    useSelector(builder);
  const { productAttributes, defaultAttributes } = useSelector(knowledge);

  const [currentTasks, setCurrentTasks] = useState([]);

  /**
   *    Websocket /ws/tasks/progress/<task_id>/
   *    Show progress of the given task. Once connected to the websocket, server will continuously send progress of the job.
   *    ex) {"detail": "SUCCESS"}
   *    If task does not exist, send 3000 and close the connection.
   *    If task is successfully done, send 3001 and close the connection.
   *    If task has failed, send 3002 and close the connection.
   *
   *    Status:
   *    QUEUED: Task is queued to be processed
   *    STARTED: Task was started by a worker.
   *    SUCCESS: Task successfully done
   *    FAILURE: Task failed
   */

  // toastAlert('confirm', 'The entity file was imported.')
  const viewPermissions = getViewPermissions(user.role.role_id);

  const makeBuilderTask = async (result) => {
    const { task_id, task_name, status } = result;
    if (
      builderGlobalNotification?.findIndex((glo) => glo.task_id === task_id) >
      -1
    )
      return;
    try {
      const taskResponse = await axios.get(`${API_URLS.TASKS}${task_id}/`, {
        headers: authHeader(),
      });
      const { data: taskData } = taskResponse;
      if (taskData) {
        const { status, task_kwargs, deleted } = taskData;
        // console.log(taskData);
        if (!deleted) {
          const { file_name, key } = task_kwargs;
          if (file_name && key) {
            const fileKey =
              key.split('.').length > 1 ? key.split('.')[1] : null;
            dispatch(
              addBuilderTask({
                task_id,
                type: GLOBAL_NOTIFICATION.KNOWLEDGE_IMPORT,
                name: file_name,
                file_key: fileKey,
              })
            );
          }
        }
      } else {
        throw new Error(`There is no the task ${task_id}.`);
      }
    } catch (e) {
      errorWithAuth(e);
    }
  };

  // if there's a task, get task detail and dispatch addGlobalNotification
  const getCurrentTasks = useCallback(() => {
    const url = `${API_URLS.TASKS}`;
    axios
      .get(url, {
        headers: authHeader(),
        params: {
          deleted: 0,
        },
      })
      .then(async (response) => {
        const { data } = response;
        const { count, results } = data;
        if (count < 1) {
          dispatch(removeAllGlobalNotification());
          return;
        }

        if (results[0]) {
          makeBuilderTask(results[0]);
        }

        /* results.forEach((result) => {
        const { task_id, task_name, status } = result;
        dispatch(addBuilderTask({
          taskId: task_id,
          type: GLOBAL_NOTIFICATION.KNOWLEDGE_IMPORT,
          name: task_name,
        }));
      }); */
      })
      .catch((e) => {
        console.log('Current task loading fail.');
        dispatch(removeAllGlobalNotification());
      });
  }, [isUserCompleted, pathname]);

  const makeGlobalNotification = (taskData) => {
    const { task_id, status, task_kwargs } = taskData;
    const { key, file_name: name } = task_kwargs;
    const fileKey = key.split('.').length > 1 ? key.split('.')[1] : null;
    let message = '';
    if (status === IMPORT_STATUS.SUCCESS) {
      message = 'Entity type import is complete.';
    }
    if (status === IMPORT_STATUS.FAILURE) {
      message = 'Failed to import entity type.';
    }

    const item = {
      task_id,
      type: GLOBAL_NOTIFICATION.KNOWLEDGE_IMPORT,
      status,
      message,
      content: {
        name,
        file_key: fileKey,
        link: {
          pathname: '/builder/knowledge?view=0&tab=0&page=1&search=',
          asPath: '/builder/knowledge?view=0&tab=0&page=1&search=',
        },
      },
    };
    return item;
  };

  const connectWS = useCallback(
    (task) => {
      const { origin, host, protocol } = window.location;
      const callProtocol = protocol.includes('http:') ? 'ws://' : 'wss://';
      const { task_id, type, name, file_key } = task;

      if (!task_id) return;

      // Native WebSocket - Working
      const ws = new WebSocket(
        `${callProtocol}${host}/ws/tasks/progress/${task_id}/`
      );

      ws.onopen = async (e) => {
        if (env === 'development') {
          console.log('Yeah! Connect!', task_id);
        }
      };

      ws.onmessage = (e) => {
        const { data } = e;
        const response = JSON.parse(data);
        if (
          response?.detail &&
          [IMPORT_STATUS.QUEUED, IMPORT_STATUS.STARTED].includes(
            response.detail
          )
        ) {
          const { detail } = response;
          const taskData = {
            status: detail,
            task_id,
            task_name: '',
            task_kwargs: {
              file_name: name,
              key: `task.${file_key}`,
              user_id: '',
            },
          };

          const item = makeGlobalNotification(taskData);

          if (item) {
            dispatch(addGlobalNotification(item));
          }
        }
      };

      ws.onclose = async (e) => {
        const { task_id } = task;
        if (env === 'development') {
          console.log(`Task ${task_id} Closed.`);
        }

        try {
          const taskResponse = await axios.get(`${API_URLS.TASKS}${task_id}/`, {
            headers: authHeader(),
          });
          const { data: taskData } = taskResponse;
          if (taskData) {
            const { status } = taskData;
            if (
              [IMPORT_STATUS.SUCCESS, IMPORT_STATUS.FAILURE].includes(status)
            ) {
              const item = makeGlobalNotification(taskData);
              dispatch(addGlobalNotification(item));
            } else {
              throw new Error('Still in progress after WS closed');
            }
          } else {
            throw new Error('No task detail at WS');
          }
        } catch (e) {
          console.log(e);
          const taskData = {
            status: IMPORT_STATUS.FAILURE,
            task_id,
            task_name: '',
            task_kwargs: {
              file_name: name,
              key: `task.${file_key}`,
              user_id: '',
            },
          };
          const item = makeGlobalNotification(taskData);
          dispatch(addGlobalNotification(item));
        }
        /* const { data } = e;
      let status = IMPORT_STATUS.FAILURE;
      if (data) {
        const response = JSON.parse(data);
        if (response?.detail && [IMPORT_STATUS.SUCCESS].includes(response.detail)) {
          status = IMPORT_STATUS.SUCCESS;
        }
      }
      const taskData = {
        status: status,
        task_id: task_id,
        task_name: '',
        task_kwargs: {
          file_name: name,
          key: `task.${file_key}`,
          user_id: '',
        },
      };
      const item = makeGlobalNotification(taskData);
      dispatch(addGlobalNotification(item)); */
      };
    },
    [builderGlobalNotification]
  );

  /* const getAttribute = async () => {
    try {
      const url = `${API_URLS.KNOWLEDGE}/entity_type/attributes/`;
      const response = axios.get(url, { headers: authHeader() });

    } catch (e) {

    }
  };
  */
  const getAttributes = async () => {
    if (productAttributes?.length >= 0 || defaultAttributes?.length >= 0)
      return;
    try {
      const productUrl = `${API_URLS.KNOWLEDGE}entity_type/attributes/`;
      const defaultUrl = `${API_URLS.KNOWLEDGE}entity_type/default_attributes/`;
      const productResponse = await axios.get(productUrl, {
        headers: authHeader(),
      });
      const defaultResponse = await axios.get(defaultUrl, {
        headers: authHeader(),
      });

      const { data: productData } = productResponse;
      const { data: defaultData } = defaultResponse;

      const prodResult = productData.map((data) => data.entity);
      const defResult = defaultData.map((data) => data.entity);

      dispatch(setProductAttributes(prodResult));
      dispatch(setDefaultAttributes(defResult));
    } catch (e) {
      dispatch(setProductAttributes([]));
      dispatch(setDefaultAttributes([]));
    }
  };

  useEffect(() => {
    if (builderTasks?.length) {
      // console.log(builderTasks);
      const task = builderTasks[0];
      // console.log('task_id', id);
      const { task_id, type, name } = task;
      if (type !== GLOBAL_NOTIFICATION.KNOWLEDGE_IMPORT) return;
      // if (builderGlobalNotification && builderGlobalNotification.findIndex((glo) => glo.task_id === taskId) > -1) return;
      setTimeout(() => {
        connectWS(task);
        setTimeout(() => {
          dispatch(removeAllBuilderTasks());
        }, 1);
      }, 0);
    }
  }, [builderTasks]);

  useEffect(() => {
    if (builderGlobalNotification?.length > 0) {
      const noti = builderGlobalNotification[0];
      const { type, status, key } = noti;
      if (type === GLOBAL_NOTIFICATION.KNOWLEDGE_IMPORT) {
        if ([IMPORT_STATUS.SUCCESS, IMPORT_STATUS.FAILURE].includes(status)) {
          const { message, content } = noti;
          const { link } = content;
          if (link && asPath !== link.asPath) {
            toastNotification(message, link || null);
          }
        }
      }
    }
  }, [builderGlobalNotification]);

  // On routing
  useEffect(() => {
    setTimeout(() => {
      getCurrentTasks();
    }, 100);

    if (pathname.indexOf('/knowledge') > -1) {
      getAttributes();
    }
  }, [pathname]);

  // Check task after action clicked
  useEffect(() => {
    if (forceCallGetCurrentTask?.length > 0) {
      dispatch(removeAllGlobalNotification([]));
      makeBuilderTask(forceCallGetCurrentTask[0]);
      dispatch(setForceCallGetCurrentTask([]));
    }
  }, [forceCallGetCurrentTask]);

  const handleChangePlanWarningClose = () => {
    setCookie(IS_CLOSED_CHANGE_PLAN_WARNING, true);
  };

  const showApiCallsWarning =
    !cookies[IS_CLOSED_CHANGE_PLAN_WARNING] &&
    subscription.limit !== null &&
    (subscription.count / subscription.limit) * 100 >= 80;

  return (
    <footer className="builder-footer" id="builder-footer">
      <ToastContainer
        id="global-toast"
        position={{
          bottom: '40px',
          right: '40px',
        }}
        list={toastList}
        ToastComponent={BuilderToastMessage}
      />
      <ToastContainer
        id="global-notification"
        position={{
          top: '40px',
          right: '40px',
        }}
        list={notificationList}
        ToastComponent={BuilderNotification}
      />

      {showApiCallsWarning && (
        <div
          className={`h-10 flex items-center justify-center bg-[#f2f4f5]
                fixed z-10 bottom-0 left-0 right-0 text-[#2b2c2d]
                border-t border-0 border-solid border-[#DCE1E6] font-semibold text-xs`}
        >
          <div className="inline-block mr-2">
            The API calls have almost reached the limit. Once the capacity gets
            full, the conversational AI may not work as expected.
          </div>
          <button
            className="btn-close"
            onClick={handleChangePlanWarningClose}
            type="button"
          />
          {viewPermissions.changePlanButton && <ChangePlanButton />}
        </div>
      )}

      <style jsx>
        {`
          .btn-close {
            position: absolute;
            top: 12px;
            right: 12px;
            width: 20px;
            height: 20px;
            background: url('/images/bt_close/normal@2x.png') center center
              no-repeat;
            background-size: cover;
            border: none;
            &:hover {
              background-image: url('/images/bt_close/hover@2x.png');
            }
          }
        `}
      </style>
    </footer>
  );
};

export default BuilderFooter;
