import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import rs from 'randomstring';
import Feedback from '@components/common/Feedback';
import { useTranslation } from 'next-i18next';
import DropdownDivider from '@components/common/DropdownSelect/DropdownDivider';
import DropdownItem from '@components/common/DropdownSelect/DropdownItem';
import { Portal } from 'react-portal';

const DropdownSelect = ({
  id,
  className,
  label,
  feedback,
  options = [],
  loadOptions,
  value,
  onChange,
  isDisabled,
  isInvalid,
  errMsg,
  readOnly,
  readOnlyAlert,
  autoFocus,
  customAction,
  OptionLabel,
  CustomLabel,
  SelectedOptionLabel,
  optionMultipleLine,
  onReset,
  placeholder,
  forceNotScrollEffectRefs,
  multiple,
  dropdownItemClass,
  optional = false,
  isItemAutofocus = true,
  isBlockedEdit = false,
  showFullLabel = false,
  isPortal = false,
  bottomScreenOffset = 0, // Use less space for checking if dropdown window would overlap the bottom of the screen, thus ensuring it appears above the input field.
}) => {
  const { t } = useTranslation();
  const rootRef = useRef();
  const menuRef = useRef();
  const hiddenMenuRef = useRef();
  const [textValue, setTextValue] = useState('');
  const [isAni, setIsAni] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [menuStyle, setMenuStyle] = useState({});
  const [focusValue, setFocusValue] = useState('');

  const getHeight = () =>
    Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );

  const onClick = () => {
    if (readOnly || isBlockedEdit) {
      readOnlyAlert();
    } else {
      if (!isOpen && typeof loadOptions === 'function') {
        loadOptions();
      }
      setIsOpen(!isOpen);
    }
  };

  const onItemClick = (e, option) => {
    if (!multiple) {
      onChange(option);
      setIsOpen(false);
    } else if (value?.findIndex((item) => item.value === option.value) !== -1) {
      onChange(value.filter((item) => item.key !== option.key));
    } else {
      onChange([...value, option]);
    }
  };

  useEffect(() => {
    if (options?.length > 0) {
      if (multiple) {
        if (value?.length > 0) {
          const idx = options.findIndex((op) => op.key === value[0].key);
          if (idx < 0) {
            onChange('');
            setTextValue('');
            setFocusValue('');
          } else {
            setTextValue(options[idx]);
            setFocusValue(options[idx]);
          }
        } else {
          setTextValue('');
          setFocusValue('');
        }
      } else if (value?.key) {
        const idx = options.findIndex((op) => op.key === value.key);
        if (idx < 0) {
          onChange('');
          setTextValue('');
          setFocusValue('');
        } else {
          setTextValue(options[idx]);
          setFocusValue(options[idx]);
        }
      } else {
        onChange(value);
        setTextValue(value);
        setFocusValue(value);
      }

      if (!value) {
        if (menuRef?.current) {
          const { current } = menuRef;
          current.scrollTop = 0;
        }
      }
    } else {
      onChange(null);
      setTextValue('');
      setFocusValue('');
    }
  }, [value, options]);

  useEffect(() => {
    if (rootRef.current && isOpen) {
      const { current } = rootRef;
      const { current: menuCurrent } = menuRef;
      const { current: hiddenCurrent } = hiddenMenuRef;
      // var bodyRect = document.body.getBoundingClientRect(),
      const hiddenHeight = hiddenCurrent.getBoundingClientRect().height;
      const contentHeight = getHeight() - bottomScreenOffset;
      const { width } = current.getBoundingClientRect();
      const { height } = current.getBoundingClientRect();
      const offsetTop = current.getBoundingClientRect().top + height - 1;
      const offsetLeft = current.getBoundingClientRect().left + window.scrollX;
      const offsetRight = 'auto';
      const offsetBottom = 'auto';
      const menuHeight = hiddenHeight; // menuCurrent.getBoundingClientRect().height;
      let newOffsetTop = offsetTop;
      const newOffsetBottom = offsetBottom;
      let newOffsetLeft = offsetLeft;
      const newOffsetRight = offsetRight;

      if (newOffsetTop + menuHeight >= contentHeight) {
        newOffsetTop = offsetTop - menuHeight - height + 2;
      }

      const popup = document.querySelector('.popup-default .popup-content');
      const isOnPopup = popup && popup.contains(current);

      if (isOnPopup) {
        const pos = popup.getBoundingClientRect();
        newOffsetTop = offsetTop - pos.top;
        newOffsetLeft = offsetLeft - pos.left;

        if (offsetTop + menuHeight >= contentHeight - 32) {
          newOffsetTop = newOffsetTop - menuHeight - height + 2;
        }
      }

      setMenuStyle({
        width,
        top: newOffsetTop,
        left: newOffsetLeft,
        right: newOffsetRight,
        bottom: newOffsetBottom,
      });
    }
  }, [rootRef, menuRef, isOpen, onReset]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        rootRef.current &&
        !rootRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    },
    [menuRef, rootRef, setIsOpen]
  );

  const handleResize = useCallback(
    (event) => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  const handleScroll = useCallback(
    (e) => {
      if (isOpen && e.target !== menuRef?.current) {
        let isEffect = false;
        if (forceNotScrollEffectRefs?.length > 0) {
          forceNotScrollEffectRefs.map((elem) => {
            const { current } = elem;
            if (current?.contains(e.target)) {
              isEffect = true;
            }
          });
        }
        if (isEffect) return;
        setIsOpen(false);
      }
    },
    [isOpen, menuRef]
  );

  const handleFocusSelection = useCallback(
    (event) => {
      if (isOpen && menuRef) {
        event.preventDefault();
        event.stopPropagation();
        let idx = -1;
        const { current } = menuRef;
        const { keyCode } = event;
        if ([38, 40].includes(keyCode)) {
          if (focusValue?.key) {
            const currentIndex = options.findIndex(
              (opt) => opt.key === focusValue.key
            );
            idx = currentIndex;
          }
          switch (keyCode) {
            case 40:
              idx += 1;
              break;
            case 38:
              idx -= 1;
              break;
            default:
              break;
          }
          if (idx < 0) {
            idx = options.length - 1;
          }
          if (idx >= options.length) {
            idx = 0;
          }
          setFocusValue(options[idx]);
        } else if ([13].includes(keyCode)) {
          if (focusValue) {
            setTextValue(focusValue);
          }
          setIsOpen(false);
        }
      }
    },
    [isOpen, menuRef, focusValue]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside, true);
      document.addEventListener('keydown', handleFocusSelection, true);
      window.addEventListener('resize', handleResize, true);
      window.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      window.removeEventListener('resize', handleResize, true);
      window.removeEventListener('scroll', handleScroll, true);
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('keydown', handleFocusSelection, true);
    };
  }, [
    isOpen,
    handleClickOutside,
    handleFocusSelection,
    handleClickOutside,
    handleResize,
  ]);

  useEffect(() => {
    if (isOpen) {
      if (textValue) {
        setFocusValue(textValue);
      }
      setIsAni(true);
    } else {
      setIsAni(false);
    }

    return () => {
      setFocusValue('');
    };
  }, [isOpen]);

  useEffect(() => {
    if (autoFocus && rootRef?.current) {
      rootRef.current.focus();
    }
    return () => {};
  }, [autoFocus, rootRef]);

  return (
    <label className="lbl-container" htmlFor={id}>
      <div className="lbl-wrapper">
        <div
          className={`lbl-content ${label ? 'has-label' : ''} ${
            textValue && onReset ? 'has-value' : ''
          }`}
        >
          <div
            id={id}
            role="button"
            tabIndex={0}
            ref={rootRef}
            className={`dropdown-select ${className || ''} ${
              isOpen ? 'open' : ''
            } ${isInvalid ? 'invalid' : ''}`}
            disabled={isDisabled}
            onClick={onClick}
            onKeyDown={onClick}
          >
            <div className={`select-text ${!textValue ? 'no-value' : ''}`}>
              {textValue ? (
                <>
                  {SelectedOptionLabel ? (
                    <SelectedOptionLabel option={textValue} />
                  ) : (
                    <div className="flex items-center justify-between">
                      <span className="truncate">{textValue.label}</span>
                      {multiple && value.length > 1 && (
                        <span className="bg-[#DCE1E6] px-1 py-0.5 leading-normal	">
                          +{value.length - 1}
                        </span>
                      )}
                    </div>
                  )}
                </>
              ) : (
                placeholder || 'No Selection'
              )}
            </div>
            <span className="select-indicator" />
            {isOpen &&
              (isPortal ? (
                <Portal>
                  <div
                    ref={menuRef}
                    className={`dropdown-menu ${className || ''} ${
                      isAni ? 'show' : ''
                    }`}
                    style={{ ...menuStyle }}
                    onClick={(e) => {
                      if (
                        e.target.classList.contains('dropdown-divider') ||
                        e.target.classList.contains('dropdown-menu')
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                  >
                    {options.length > 0 ? (
                      <ul>
                        {options.map((option) => (
                          <Fragment key={`${option.key}-${rs.generate()}`}>
                            {option.key === 'divider' ? (
                              <DropdownDivider option={option} />
                            ) : (
                              <DropdownItem
                                key={option.key}
                                option={option}
                                isFocused={option.key === focusValue?.key}
                                isSelected={option.key === textValue?.key}
                                isItemAutofocus={isItemAutofocus}
                                onItemClick={onItemClick}
                                customAction={customAction}
                                {...{
                                  OptionLabel: OptionLabel || null,
                                  CustomLabel: CustomLabel || null,
                                }}
                                optionMultipleLine={optionMultipleLine}
                                multiple={multiple}
                                value={value}
                                className={dropdownItemClass}
                                showFullLabel={showFullLabel}
                              />
                            )}
                          </Fragment>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                        <DropdownItem
                          className="no-value"
                          option={{
                            key: rs.generate(),
                            label: placeholder || 'No options',
                            value: null,
                          }}
                          isSelected={false}
                          onItemClick={() => setIsOpen(false)}
                          multiple={multiple}
                          value={value}
                          showFullLabel={showFullLabel}
                        />
                      </ul>
                    )}
                  </div>
                  <div
                    ref={hiddenMenuRef}
                    className={`dropdown-menu ${className || ''}`}
                    style={{
                      position: 'absolute',
                      top: '-999999px',
                      left: '-99999px',
                      opacity: 0,
                    }}
                  >
                    {options.length > 0 ? (
                      <ul>
                        {options.map((option) => (
                          <Fragment
                            key={`hidden-${option.key}-${rs.generate()}`}
                          >
                            {option.key === 'divider' ? (
                              <DropdownDivider option={option} />
                            ) : (
                              <DropdownItem
                                option={option}
                                customAction={customAction}
                                {...{
                                  OptionLabel: OptionLabel || null,
                                  CustomLabel: CustomLabel || null,
                                }}
                                optionMultipleLine={optionMultipleLine}
                                onItemClick={() => false}
                                multiple={multiple}
                                value={value}
                                showFullLabel={showFullLabel}
                              />
                            )}
                          </Fragment>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                        <DropdownItem
                          className="no-value"
                          option={{
                            key: rs.generate(),
                            label: placeholder || 'No options',
                            value: null,
                          }}
                          isSelected={false}
                          onItemClick={() => false}
                          multiple={multiple}
                          value={value}
                          showFullLabel={showFullLabel}
                        />
                      </ul>
                    )}
                  </div>
                </Portal>
              ) : (
                <>
                  <div
                    ref={menuRef}
                    className={`dropdown-menu ${className || ''} ${
                      isAni ? 'show' : ''
                    }`}
                    style={{ ...menuStyle }}
                    onClick={(e) => {
                      if (
                        e.target.classList.contains('dropdown-divider') ||
                        e.target.classList.contains('dropdown-menu')
                      ) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                  >
                    {options.length > 0 ? (
                      <ul>
                        {options.map((option) => (
                          <Fragment key={`${option.key}-${rs.generate()}`}>
                            {option.key === 'divider' ? (
                              <DropdownDivider option={option} />
                            ) : (
                              <DropdownItem
                                key={option.key}
                                option={option}
                                isFocused={option.key === focusValue?.key}
                                isSelected={option.key === textValue?.key}
                                isItemAutofocus={isItemAutofocus}
                                onItemClick={onItemClick}
                                customAction={customAction}
                                {...{
                                  OptionLabel: OptionLabel || null,
                                  CustomLabel: CustomLabel || null,
                                }}
                                optionMultipleLine={optionMultipleLine}
                                multiple={multiple}
                                value={value}
                                className={dropdownItemClass}
                                showFullLabel={showFullLabel}
                              />
                            )}
                          </Fragment>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                        <DropdownItem
                          className="no-value"
                          option={{
                            key: rs.generate(),
                            label: placeholder || 'No options',
                            value: null,
                          }}
                          isSelected={false}
                          onItemClick={() => setIsOpen(false)}
                          multiple={multiple}
                          value={value}
                          showFullLabel={showFullLabel}
                        />
                      </ul>
                    )}
                  </div>
                  <div
                    ref={hiddenMenuRef}
                    className={`dropdown-menu ${className || ''}`}
                    style={{
                      position: 'absolute',
                      top: '-999999px',
                      left: '-99999px',
                      opacity: 0,
                    }}
                  >
                    {options.length > 0 ? (
                      <ul>
                        {options.map((option) => (
                          <Fragment
                            key={`hidden-${option.key}-${rs.generate()}`}
                          >
                            {option.key === 'divider' ? (
                              <DropdownDivider option={option} />
                            ) : (
                              <DropdownItem
                                option={option}
                                customAction={customAction}
                                {...{
                                  OptionLabel: OptionLabel || null,
                                  CustomLabel: CustomLabel || null,
                                }}
                                optionMultipleLine={optionMultipleLine}
                                onItemClick={() => false}
                                multiple={multiple}
                                value={value}
                                showFullLabel={showFullLabel}
                              />
                            )}
                          </Fragment>
                        ))}
                      </ul>
                    ) : (
                      <ul>
                        <DropdownItem
                          className="no-value"
                          option={{
                            key: rs.generate(),
                            label: placeholder || 'No options',
                            value: null,
                          }}
                          isSelected={false}
                          onItemClick={() => false}
                          multiple={multiple}
                          value={value}
                          showFullLabel={showFullLabel}
                        />
                      </ul>
                    )}
                  </div>
                </>
              ))}
          </div>
          {textValue && onReset && (
            <button
              type="button"
              className="btn-reset"
              title={`Reset ${label}`}
              aria-label={`Reset ${label}`}
              onClick={onReset}
            />
          )}
        </div>
        {label && (
          <div
            className={`lbl-text ${feedback ? 'feedback' : ''} ${
              isDisabled ? 'disabled' : ''
            }`}
          >
            {label}
            {optional && (
              <span className="optional">
                ({t('builder:sb-common-lbl-optional')})
              </span>
            )}
            {feedback && (
              <Feedback
                style={{
                  marginLeft: '10px',
                }}
                isDisabled={isDisabled}
              >
                {feedback}
              </Feedback>
            )}
          </div>
        )}
      </div>
      {isInvalid && errMsg && <div className="lbl-error">{errMsg}</div>}
      <style jsx global>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .dropdown-select {
            &[disabled] {
              & + .lbl-text {
                opacity: 0.4;
              }
            }
          }

          .dropdown-menu.small {
            .menu-item {
              line-height: normal;
              margin-bottom: 1px;
              padding: 5px 4px;
              font-size: 12px;
            }
          }

          .dropdown-menu.x-small {
            .menu-item {
              line-height: 26px;
              margin-bottom: 1px;
              padding: 0 4px;
              font-size: 12px;
            }
          }

          .dropdown-menu {
            .menu-item {
              & + .dropdown-divider {
                padding-top: 12px;
              }
            }
          }
        `}
      </style>
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .lbl-content {
            &.has-label {
              margin-top: 8px;
            }

            &.has-value {
              position: relative;
              padding-right: 32px;
              button {
                position: absolute;
                right: 0px;
                top: 4px;
              }
              &.has-label {
                button {
                  top: 4px;
                }
              }
            }
          }

          .lbl-text {
            position: relative;

            &.disabled {
              opacity: 1;
            }
          }

          .dropdown-select {
            position: relative;
            height: 40px;
            border: 1px solid
              ${readOnly ? 'rgba(203, 209, 215, .4)' : '#cbd1d7'};
            background: ${readOnly ? 'rgba(242, 244, 245, .4)' : '#f2f4f5'};
            border-radius: 2px;
            cursor: ${readOnly ? 'initial' : 'pointer'};
            @include font(14px, 600, -0.3px, 38px, $black);

            &:hover {
              border-color: ${readOnly ? 'rgba(203, 209, 215, .4)' : '#2b2c2d'};
            }

            &.white {
              background: ${readOnly ? 'rgba(242, 244, 245, .4)' : '#fff'};

              &[disabled] {
                opacity: 1 !important;
                background-color: #ffffff;
                border-color: rgba(#cbd1d7, 0.4);

                .select-indicator {
                  opacity: 0.3 !important;
                }
              }
            }

            &.off-white {
              background: #f2f4f5;

              &[disabled] {
                opacity: 1 !important;
                background-color: rgba(#f2f4f5, 0.4);
                border: 1px solid #cbd1d7;

                .select-indicator {
                  opacity: 0.3 !important;
                }
              }
            }

            &[disabled] {
              cursor: default;
              opacity: 0.4 !important;
              pointer-events: none;
              // color: rgba($black, 0.4);

              * {
                pointer-events: none;
              }

              .select-indicator {
                opacity: 0.4 !important;
              }

              &:hover {
                border-color: #cbd1d7;
                background-color: #f2f4f5;
              }
            }

            &.invalid {
              border-color: #ff3535;
              background-color: #ffffff;

              &[disabled] {
                border-color: #cbd1d7;
                background: #f2f4f5;
                &:hover {
                  border-color: #cbd1d7;
                }
              }
            }
          }

          .select-text {
            padding: 0 36px 0 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .no-value {
            font-weight: 400 !important;
          }

          .select-indicator {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin-top: auto;
            margin-bottom: auto;
            display: inline-block;
            vertical-align: top;
            width: 32px;
            height: 32px;
            background: url('#{$image_url}/bt_dropdown/enabled@2x.png') center
              center no-repeat;
            background-size: cover;
            transition: transform 0.5s;
            transform-origin: center;
            transform: rotate(0deg);
            z-index: 0;
            cursor: ${readOnly ? 'initial' : 'pointer'};
            opacity: ${readOnly ? '.4' : '1'};
            border: none;
          }

          .dropdown-menu {
            position: fixed;
            cursor: default;
            top: 0;
            left: 0;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2b2c2d;
            padding: 8px 6px;
            @include font(14px, 600, -0.3px, 28px, $black);
            max-height: 192px;
            overflow-y: auto;
            z-index: 9999;

            $du: 0.3s;
            opacity: 0;
            transition: opacity $du;

            &.show {
              opacity: 1;
              transition: opacity $du;
            }
          }

          .dropdown-select.small {
            height: 32px;
            line-height: 30px;
            font-size: 12px;
            .select-text {
              padding: 0 36px 0 8px;
            }

            .select-indicator {
              width: 24px;
              height: 24px;
              right: 3px;
              background: url('#{$image_url}/bt_builder_dropdown_small/normal@2x.png')
                center center no-repeat;
              background-size: cover;
            }
          }

          .dropdown-select.x-small {
            height: 28px;
            line-height: 26px;
            font-size: 12px;
            .select-text {
              padding: 0 36px 0 8px;
            }

            .select-indicator {
              width: 24px;
              height: 24px;
              right: 3px;
              background: url('#{$image_url}/bt_builder_dropdown_small/normal@2x.png')
                center center no-repeat;
              background-size: cover;
            }
          }

          .open {
            &.dropdown-select {
              z-index: 1000;
              border-color: #2b2c2d;
              background: #ffffff;
            }
            .select-indicator {
              transform: rotate(180deg);
            }
            .dropdown-menu {
              z-index: 99;
            }
          }

          .btn-reset {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: #ffffff url('#{$image_url}/bt_reset/normal@2x.png')
              center center no-repeat;
            background-size: cover;
            border: none;
            margin-left: 8px;

            &:hover {
              background-image: url('#{$image_url}/bt_reset/hover@2x.png');
            }
          }
        `}
      </style>
    </label>
  );
};

DropdownSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  feedback: PropTypes.string,
  options: PropTypes.array,
  loadOptions: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  errMsg: PropTypes.string,
  readOnly: PropTypes.bool,
  readOnlyAlert: PropTypes.func,
  autoFocus: PropTypes.bool,
  customAction: PropTypes.func,
  OptionLabel: PropTypes.elementType,
  CustomLabel: PropTypes.elementType,
  SelectedOptionLabel: PropTypes.elementType,
  optionMultipleLine: PropTypes.bool,
  onReset: PropTypes.func,
  placeholder: PropTypes.string,
  forceNotScrollEffectRefs: PropTypes.array,
  multiple: PropTypes.bool,
  optional: PropTypes.bool,
  isItemAutofocus: PropTypes.bool,
  isBlockedEdit: PropTypes.bool,
  showFullLabel: PropTypes.bool,
};

DropdownSelect.defaultProps = {
  id: '',
  className: '',
  isDisabled: false,
  value: null,
  isInvalid: false,
  readOnly: false,
  errMsg: '',
  readOnlyAlert: () => {},
  autoFocus: false,
  customAction: null,
  OptionLabel: null,
  CustomLabel: null,
  SelectedOptionLabel: null,
  optionMultipleLine: false,
  isItemAutofocus: true,
};

export default DropdownSelect;
