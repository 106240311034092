import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import Feedback from '@components/common/Feedback';

const DropdownItem = ({
  option,
  isFocused,
  isSelected,
  onItemClick,
}) => {
  const ref = useRef();

  const options = {
    behavior: 'auto',
    block: 'center',
  };

  const handleClick = (e) => {
    onItemClick(e, option);
  };

  useEffect(() => {
    if (ref && (isSelected || isFocused)) {
      const { current: refCurrent } = ref;
      if (refCurrent) {
        refCurrent.scrollIntoView(options, 0);
      }
    }
  }, [ref, isSelected, isFocused]);

  return (
    <li
      ref={ref}
      className={`menu-item ${isSelected ? 'selected' : ''} ${
        isFocused ? 'focused' : ''
      }`}
      onClick={handleClick}
    >
      {option.label}
      <style jsx>
        {`
          .menu-item {
            line-height: 32px;
            margin-bottom: 4px;
            border-radius: 2px;
            padding: 0 6px;
            cursor: pointer;

            &:last-child {
              margin-bottom: 0;
            }

            &:first-child {
              margin-top: 0;
            }

            &:hover {
              background: #ebedef;
            }

            &.focused {
              background: #ebedef;
            }

            &.selected {
              $color: rgba(#ffbd2e, 0.2);
              background-color: $color;
              &.focused {
                background-color: $color;
              }
            }
          }
        `}
      </style>
    </li>
  );
};

DropdownItem.propTypes = {
  option: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

const DropdownInput = ({
  id,
  className,
  options,
  loadOptions,
  value,
  onChange,
  isDisabled,
  label,
  feedback,
  inputProps,
  optional,
  isInvalid,
  errMsg,
  onClick,
  isEditing,
  autoFocus,
  OptionLabel,
  size = 'small',
  isBlockedEdit = false, // Only block editing but UI is the same with normal input state
}) => {
  const rootRef = useRef();
  const menuRef = useRef();
  const inputRef = useRef();
  const [textValue, setTextValue] = useState(value);
  const [isAni, setIsAni] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [menuStyle, setMenuStyle] = useState({});
  const [filtered, setFiltered] = useState(options);
  const [focusValue, setFocusValue] = useState('');

  const getHeight = () =>
    Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );

  const onItemClick = (e, option) => {
    e.preventDefault();
    setTextValue(option.value);
    onChange(option.value);
    setIsOpen(false);
  };

  const dropdownPosition = () => {
    if (rootRef && menuRef && isOpen && isEditing) {
      const { current } = rootRef;
      const { current: menuCurrent } = menuRef;

      if (current && menuCurrent) {
        const contentHeight = getHeight();
        const { width } = current.getBoundingClientRect();
        const { height } = current.getBoundingClientRect();
        const offsetTop = current.getBoundingClientRect().top + height - 1;
        const offsetLeft =
          current.getBoundingClientRect().left + window.scrollX;
        const offsetRight = 'auto';
        const offsetBottom = 'auto';
        const menuHeight = menuCurrent.getBoundingClientRect().height;
        let newOffsetTop = offsetTop;
        let newOffsetBottom = offsetBottom;
        let newOffsetLeft = offsetLeft;
        const newOffsetRight = offsetRight;

        if (newOffsetTop + menuHeight >= contentHeight - 56) {
          newOffsetTop = offsetTop - menuHeight - height + 2;
          newOffsetTop = 'auto';
          newOffsetBottom = contentHeight - offsetTop + height - 2;
        }

        const popup = document.querySelector('.popup-default .popup-content');
        const isOnPopup = popup && popup.contains(current);

        if (isOnPopup) {
          const pos = popup.getBoundingClientRect();
          newOffsetTop = offsetTop - pos.top;
          newOffsetLeft = offsetLeft - pos.left;

          if (offsetTop + menuHeight >= contentHeight - 32) {
            newOffsetTop = newOffsetTop - menuHeight - height + 2;
          }
        }

        setMenuStyle({
          width,
          top: newOffsetTop,
          left: newOffsetLeft,
          right: newOffsetRight,
          bottom: newOffsetBottom,
        });
      }
    }
  };

  useEffect(() => {
    if (options && options.length >= 0) {
      setTextValue(value);
      setFocusValue(value);
      const list = options.filter((opt) =>
        opt.value.toLowerCase().includes(value.toLowerCase())
      );
      setFiltered(list);
    }
  }, [value, options]);

  useEffect(() => {
    if (rootRef && menuRef && isOpen && isEditing) {
      dropdownPosition();
    }
  }, [rootRef, menuRef, isOpen, isEditing, filtered]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        rootRef.current &&
        !rootRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        inputRef.current.blur();
      }
    },
    [menuRef, rootRef, inputRef, setIsOpen]
  );

  const handleResize = useCallback(
    (event) => {
      if (isOpen) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  const handleScroll = useCallback(
    (e) => {
      if (isOpen && e.target !== menuRef?.current) {
        // if (isFocus) {
        //   dropdownPosition();
        // } else {
        setIsOpen(false);
        // }
      }
    },
    [isOpen, menuRef]
  );

  const handleFocusSelection = useCallback(
    (event) => {
      if (isOpen && isEditing && menuRef) {
        let idx = -1;
        const { current } = menuRef;
        const { keyCode } = event;
        // console.log(keyCode);
        if ([38, 40].includes(keyCode)) {
          event.preventDefault();
          const children = current.children[0].childNodes; // ul > li
          if (focusValue) {
            const currentIndex = filtered.findIndex(
              (opt) => opt.key === focusValue.key
            );
            idx = currentIndex;
          }
          switch (keyCode) {
            case 40:
              idx += 1;
              break;
            case 38:
              idx -= 1;
              break;
            default:
              break;
          }
          if (idx < 0) {
            idx = filtered.length - 1;
          }
          if (idx >= filtered.length) {
            idx = 0;
          }
          setFocusValue(filtered[idx]);
        } else if ([13].includes(keyCode)) {
          // const findValue = textValue || focusValue?.value;
          const findValue = focusValue?.value;
          const fIdx = filtered.findIndex((opt) => opt.value === findValue);
          if (fIdx > -1) {
            onItemClick(event, filtered[fIdx]);
          }
          // inputRef.current.blur();
          setIsOpen(false);
        } else if ([27].includes(keyCode)) {
          inputRef.current.blur();
          setIsOpen(false);
        }
      }
    },
    [isOpen, isEditing, menuRef, inputRef, filtered, focusValue]
  );

  const onTextChanged = (e) => {
    if (!isBlockedEdit) {
      const { value } = e.target;
      setTextValue(value);
      onChange(value);
      if (!isOpen) {
        setIsOpen(true);
      }
    }
  };

  const onFocus = (e) => {
    if (isEditing && !isInvalid) {
      // (e.relatedTarget === null || e.relatedTarget === inputRef)
      const openFunc = (delay) => {
        setTimeout(() => {
          setIsOpen(true);
        }, delay);
      };
      if (inputRef?.current) {
        setTimeout(() => {
          // inputRef.current.scrollIntoView({
          //   behavior: 'smooth',
          //   block: 'center',
          // });
          openFunc(0);
        }, 150);
      } else {
        openFunc(150);
      }
    }
  };

  const onBlur = (e) => {
    if (!(menuRef.current && !menuRef.current.contains(e.target))) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (typeof loadOptions === 'function') {
        loadOptions();
      }
    }
    return () => {};
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && isEditing) {
      document.addEventListener('mousedown', handleClickOutside, true);
      document.addEventListener('keydown', handleFocusSelection, true);
      window.addEventListener('resize', handleResize, true);
      window.addEventListener('scroll', handleScroll, true);
    }

    return () => {
      window.removeEventListener('resize', handleResize, true);
      window.removeEventListener('scroll', handleScroll, true);
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('keydown', handleFocusSelection, true);
    };
  }, [
    isOpen,
    isEditing,
    handleClickOutside,
    handleFocusSelection,
    handleScroll,
  ]);

  useEffect(() => {
    if (isOpen && isEditing) {
      setIsAni(true);
      let list = options;
      if (textValue) {
        list = options.filter((opt) =>
          opt.value.toLowerCase().includes(textValue.toLowerCase())
        );
        setFiltered(list);
      } else {
        setFiltered(options);
      }
    } else {
      setIsAni(false);
    }

    return () => {
      setFocusValue('');
    };
  }, [isOpen, isEditing, textValue]);

  useEffect(() => {
    if (autoFocus && inputRef?.current) {
      inputRef.current.focus();
    }
    return () => {};
  }, [autoFocus, inputRef]);

  return (
    <div
      role="button"
      className={`dropdown-select ${className || ''} ${
        label ? 'dropdown-label' : ''
      } ${isOpen ? 'open' : ''} ${filtered.length > 0 ? 'has-filtered' : ''} ${
        isInvalid ? 'invalid' : ''
      }`}
      disabled={isDisabled}
    >
      {label && (
        <label htmlFor={id} className="lbl-text">
          {label}
          {optional && <span className="optional">(Optional)</span>}
          {feedback && feedback.length > 0 && (
            <Feedback
              style={{
                marginLeft: '10px',
              }}
            >
              {feedback}
            </Feedback>
          )}
        </label>
      )}
      <div ref={rootRef}>
        <input
          tabIndex={0}
          type="text"
          style={{ zIndex: 1 }}
          ref={inputRef}
          value={textValue}
          onChange={onTextChanged}
          disabled={isDisabled}
          onFocus={onFocus}
          onBlur={onBlur}
          id={id}
          autoComplete="off"
          onClick={(e) => {
            onClick(e, () => {
              setTimeout(() => {
                if (!isOpen && !isInvalid) {
                  setIsOpen(true);
                }
              }, 0);
            });
          }}
          {...inputProps}
          className={`${size} ${inputProps?.className ?? ''} ${
            className.includes('key-bracelet') ? 'key-bracelet' : ''
          } ${className.includes('key-reference') ? 'key-reference' : ''} ${
            isInvalid ? 'invalid' : ''
          }`}
          autoFocus={autoFocus}
        />
      </div>
      {isInvalid && errMsg && <div className="lbl-error">{errMsg}</div>}
      {isOpen && filtered && (
        <>
          <div
            ref={menuRef}
            className={`dropdown-menu ${className || ''} ${
              filtered.length <= 0 ? 'no-options' : ''
            } ${isAni ? 'show' : ''}`}
            style={{ ...menuStyle }}
            onClick={(e) => {
              if (e.target.classList.contains('dropdown-menu')) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            <ul>
              {filtered.map((option) => (
                <Fragment key={`reset-${option.key}`}>
                  {OptionLabel ? (
                    <OptionLabel
                      key={`reset-${option.key}`}
                      option={option}
                      isFocused={focusValue && option.key === focusValue.key}
                      isSelected={textValue && option.value === textValue}
                      onItemClick={onItemClick}
                    />
                  ) : (
                    <DropdownItem
                      key={`reset-${option.key}`}
                      option={option}
                      isFocused={focusValue && option.key === focusValue.key}
                      isSelected={textValue && option.value === textValue}
                      onItemClick={onItemClick}
                    />
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        </>
      )}
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .dropdown-select {
            position: relative;
            //height: 40px;
            border-radius: 2px;
            cursor: pointer;
            @include font(14px, 600, -0.3px, 38px, $black);

            &[disabled] {
              cursor: default;
              opacity: 0.4 !important;
              pointer-events: none;

              * {
                pointer-events: none;
              }

              .lbl-text {
                opacity: 0.4 !important;
              }

              &:hover {
                border-color: #cbd1d7;
                background-color: #f2f4f5;
              }
            }

            &.invalid {
              border-color: #ff3535;
              background-color: #ffffff;
              &:hover {
                //border-color: #2B2C2D;
              }

              &[disabled] {
                border-color: #cbd1d7;
                background: #f2f4f5;
                &:hover {
                  border-color: #cbd1d7;
                }
              }
            }
          }

          .dropdown-label {
            padding-top: 24px;
            .optional {
              font-weight: 400;
              margin-left: 4px;
            }
          }

          .lbl-text {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding-left: 2px;
            vertical-align: top;
            @include font(12px, 700, -0.3px, 17px, rgba($black, 0.4));
          }

          .select-text {
            padding: 0 36px 0 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .dropdown-menu {
            position: fixed;
            top: 0;
            left: 0;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2b2c2d;
            padding: 8px 6px;
            @include font(14px, 600, -0.3px, 28px, $black);
            max-height: 180px;
            overflow-y: auto;
            z-index: 99;

            $du: 0.3s;
            opacity: 0;
            transition: opacity $du;

            &.show {
              opacity: 1;
              transition: opacity $du;
            }

            &.no-options {
              padding: 0px;
              border: none;
            }
          }

          .open {
            &.dropdown-select {
              z-index: 1;
              border-color: #2b2c2d;
              background: #ffffff;
            }
            &.has-filtered {
              input[type='text'] {
                border-color: $black;
              }
            }
            .dropdown-menu {
              z-index: 1;
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .dropdown-select.small {
            //height: 32px;
            //line-height: 30px;
            font-size: 12px;
            .select-text {
              padding: 0 36px 0 8px;
            }
          }

          .dropdown-menu.small {
            padding: 6px 8px;
            .menu-item {
              line-height: 28px;
              margin-top: 4px;
              margin-bottom: 1px;
              padding: 0 4px;
              font-size: 12px;
              &:last-child {
                margin-bottom: 0;
              }

              &:first-child {
                margin-top: 0;
              }
            }
          }
        `}
      </style>
    </div>
  );
};

DropdownInput.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isEditing: PropTypes.bool,
  errMsg: PropTypes.string,
  isBlockedEdit: PropTypes.bool,
};

DropdownInput.defaultProps = {
  className: '',
  isDisabled: false,
  value: '',
  isInvalid: false,
  isEditing: true,
  errMsg: '',
};

export default DropdownInput;
