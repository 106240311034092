import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';

const BuilderGlobalLink = ({
  type,
  href,
  className,
  title,
  target,
  content,
  isCollapsed,
}) => {
  const ref = useRef();
  const router = useRouter();
  const { pathname } = router;
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = (event) => {
    if (ref.current && ref.current.contains(event.target)) {
      setIsHover(true);
    }
  };

  const handleMouseOut = (event) => {
    setIsHover(false);
  };

  useEffect(
    () => () => {
      setIsHover(false);
    },
    [pathname]
  );

  return (
    <>
      {type === 'link' ? (
        <Link href={href}>
          <a
            ref={ref}
            className={`nav-link ${className} ${isHover ? 'hover' : ''} ${
              isCollapsed ? 'collapse' : ''
            }`}
            title={`${isCollapsed ? '' : title}`}
            aria-label={title}
            target={target}
            onMouseOver={handleMouseEnter}
            onMouseOut={handleMouseOut}
            onTouchEnd={handleMouseOut}
          >
            <span>{content}</span>
            {target === '_blank' && <span className="icn-blank" />}
          </a>
        </Link>
      ) : (
        <span
          ref={ref}
          className={`nav-link ${className} ${isHover ? 'hover' : ''} ${
            isCollapsed ? 'collapse' : ''
          }`}
          type="button"
          title={`${isCollapsed ? '' : title}`}
          aria-label={title}
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseOut}
          onTouchEnd={handleMouseOut}
        >
          <span>{content}</span>
        </span>
      )}
      <span className={`hover-label ${target === '_blank' ? 'blank' : ''}`}>
        <span>{content}</span>
        {target === '_blank' && <span className="icn-blank" />}
      </span>
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .nav-link {
            @include font(14px, 600, -0.3px, 32px, $white);
            border-radius: 2px;

            & + .hover-label {
              display: none;
            }

            &.hover {
              background-color: rgba(#8e97a6, 0.2);
            }

            &.docs {
              background-image: url('#{$image_url}/bt_docs/normal@2x.png');
            }

            &.notification {
              background-image: url('#{$image_url}/bt_notification/normal@2x.png');
            }

            &.mypage {
              background-image: url('#{$image_url}/bt_my_page_white/normal@2x.png');
            }

            &.collapse {
              visibility: visible;
              & > span {
                display: none;
              }

              &.hover {
                background-color: transparent;
                & + .hover-label {
                  display: block;
                }
                &.docs {
                  background-image: url('#{$image_url}/bt_docs/hover@2x.png');
                }
                &.notification {
                  background-image: url('#{$image_url}/bt_notification/hover@2x.png');
                }
                &.mypage {
                  background-image: url('#{$image_url}/bt_my_page_white/hover@2x.png');
                }
              }
            }
          }
        `}
      </style>
    </>
  );
};

BuilderGlobalLink.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  target: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.any]).isRequired,
  isCollapsed: PropTypes.bool.isRequired,
};

BuilderGlobalLink.defaultProps = {
  type: 'link',
  href: '',
  className: '',
  target: '_self',
};

export default BuilderGlobalLink;
