import React from 'react';
import PropTypes from 'prop-types';
import { setShowTellUsPopup } from '@js/store/slices/builderSlice';
import { useDispatch } from 'react-redux';

const ChangePlanButton = ({ isCollapsed = false, fullWidth = false }) => {
  const dispatch = useDispatch();

  return (
    <>
      <button
        onClick={() => dispatch(setShowTellUsPopup(true))}
        className={`btn btn-outlined ${fullWidth ? '' : 'w-auto'} ${
          isCollapsed ? 'isCollapsed' : ''
        }`}
      >
        {isCollapsed ? '' : 'Change Plan'}
      </button>
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          .isCollapsed {
            background: #ffffff url('#{$image_url}/bt_small_change/normal.svg')
              center center no-repeat;
            background-size: 24px 24px;
          }
        `}
      </style>
    </>
  );
};

ChangePlanButton.propTypes = {
  isCollapsed: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default ChangePlanButton;
