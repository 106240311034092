import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useAuth } from '@context/UseAuth';
import CONFIG from 'env_config';

const errorContext = createContext();

export const ProvideError = ({ children }) => {
  // eslint-disable-next-line no-use-before-define
  const error = useProvidedError();
  return (
    <errorContext.Provider value={error}>{children}</errorContext.Provider>
  );
};

ProvideError.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useError = () => useContext(errorContext);

const useProvidedError = () => {
  const { authError } = useAuth();
  const { t } = useTranslation('common');

  const errorWithAuth = (error, callback) => {
    const { response, request, message } = error;
    if (response) {
      const { status, data: exceptionData } = response;
      if (status === 401) {
        // auth issue
        if (!authError) {
          window.alert(t('error-auth'));
        }
        document.location.replace('/start');
        return;
      }
      if (status === 400) {
        const { detail } = exceptionData;
        if (detail) {
          if (typeof detail === 'string') {
            if (!CONFIG.DEV) {
              window.alert(t('common:error-common'));
            } else {
              window.alert(detail);
            }
          } else if (typeof detail === 'object') {
            console.log(exceptionData);
          }
        } else {
          const errors = Object.keys(exceptionData);
          const str = errors
            .map((jo) => `${jo} : ${exceptionData[jo]}`)
            .join('\n');
          const text = `Errors.\n${str}`;
          if (!CONFIG.DEV) {
            window.alert(t('common:error-common'));
          } else {
            window.alert(text);
          }
        }
      } else if (status === 500 && exceptionData?.detail) {
        const text = `Errors.\n${exceptionData.detail}`;
        if (!CONFIG.DEV) {
          window.alert(t('common:error-common'));
        } else {
          window.alert(text);
        }
      } else if (exceptionData?.detail) {
        const text = `Errors.\n${exceptionData.detail}`;
        if (!CONFIG.DEV) {
          window.alert(t('common:error-common'));
        } else {
          window.alert(text);
        }
      } else {
        window.alert(t('common:error-common'));
      }
    } else if (request) {
      window.alert(t('common:error-common'));
      if (CONFIG.DEV) {
        console.log(request);
      }
    } else if (!CONFIG.DEV) {
      window.alert(t('common:error-common'));
    } else {
      window.alert(message);
    }

    if (typeof callback === 'function') {
      callback();
    }
  };

  const commonErrorWithoutAuth = (error, callback) => {
    const { response, request, message } = error;
    if (!CONFIG.DEV) {
      window.alert(t('common:error-common'));
    } else if (response) {
      if (!CONFIG.DEV) {
        window.alert(t('common:error-common'));
      } else {
        const { status, data: exceptionData } = response;
        window.alert(JSON.stringify(exceptionData));
      }
    } else {
      window.alert(t('common:error-common'));
    }

    if (typeof callback === 'function') {
      callback();
    }
  };

  return {
    errorWithAuth,
    commonErrorWithoutAuth,
  };
};
