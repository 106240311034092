import React from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';

const Layout = ({ children }) => (
  <>
    {children}
    <style jsx global>
      {`
        @import './src/sass/layout.scss';
      `}
    </style>
  </>
);

const Empty = () => <></>;

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.array,
  ]),
  pageTitle: PropTypes.string,
};

Layout.defaultProps = {
  children: Empty,
  pageTitle: '',
};

export default withCookies(Layout);
