import React, { Fragment, useRef, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import PropTypes from 'prop-types';
import DocsLinkList from '@js/docs/links';
import { Scrollbars } from 'react-custom-scrollbars-with-mobile-fix';

const DocsLink = ({
  id,
  title,
  children,
  subs,
  depth,
  rootRef,
  expandedItem,
  setExpandedItem,
  isMenuAni,
}) => {
  const linkRef = useRef();
  const parent = id.split('/');
  const { pathname, asPath } = useRouter();
  const [isActive, setIsActive] = useState(false);
  const [isExpand, setIsExpand] = useState(false); // useState((asPath).includes(parent[0]) && (subs.length > 0));

  const ref = useRef();

  const onClick = (e) => {
    if (subs.length > 0) {
      setIsExpand(!isExpand);
      /* if ((!expandedItem || expandedItem !== id)) {
        e.preventDefault();
        // setExpandedItem(id);
        setIsExpand(true);
      } else {
        //setExpandedItem(null);
        setIsExpand(false);
      } */
      return false;
    }
  };

  useEffect(() => {
    if (expandedItem === id) {
      setIsExpand(true);
    } else {
      setIsExpand(false);
    }

    return () => {
      setIsExpand(false);
    };
  }, [expandedItem]);

  useEffect(() => {
    if (asPath) {
      const newAsPath = asPath.replace(/#(\S*)/g, '');
      if (newAsPath === `/docs/${id}` || newAsPath === `/docs/${id}/`) {
        setIsActive(true);
        if (subs?.length > 0) {
          setExpandedItem(id);
        }
      } else {
        setIsActive(false);
        if (subs?.length > 0 && newAsPath?.includes(`/docs/${id}`)) {
          setExpandedItem(id);
        } else if (isExpand) {
          setIsExpand(false);
        }
      }
    }

    return () => {
      setIsActive(false);
    };
  }, [asPath, isMenuAni]);

  useEffect(() => {
    if (isActive && ref?.current && rootRef?.current) {
      if (ref.current.classList.contains('exp-link')) return;

      setTimeout(() => {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= 0) return;
        const { current } = rootRef;
        const h = current.getClientHeight();
        const value = ref.current.offsetTop;
        current.scrollTop(value - h / 2 + rect.height / 2);
      }, 1);
    }
  }, [isActive, ref, rootRef]);

  return (
    <li
      id={id}
      ref={ref}
      className={`${subs.length > 0 ? 'exp-link' : ''} ${
        isExpand ? 'open' : ''
      }`}
    >
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
        `}
      </style>
      {subs.length > 0 ? (
        <>
          <a
            className={`${subs.length > 0 ? 'has-sub' : ''}  ${
              isActive ? 'active' : ''
            }`}
            title={title}
            onClick={onClick}
            onKeyDown={onClick}
          >
            <span title={title}>{title}</span>
            {subs.length > 0 ? <span className="icn-sub" /> : <></>}
          </a>
          <div className="sub-container">
            <ul>
              {subs.map(
                ({ key: subKey, id: subId, title: subTitle }, index) => (
                  <Fragment key={subKey}>
                    {subKey.includes('between') ? (
                      <li key={subKey} className="between">
                        <div className="between-title">{subTitle}</div>
                      </li>
                    ) : (
                      <DocsLink
                        key={`sub-link-${subKey}`}
                        rootRef={rootRef}
                        id={
                          subId && subId !== 'overview'
                            ? `${id}/${subId}`
                            : `${id}`
                        }
                        title={subTitle}
                        expandedItem={expandedItem}
                        setExpandedItem={setExpandedItem}
                      />
                    )}
                  </Fragment>
                )
              )}
            </ul>
          </div>
        </>
      ) : (
        // <Link href="/docs/[...slug]" as={`/docs/${id}`}> // for the refresh in same route
        <Link href={`/docs/${id}`} shallow={false}>
          <a
            ref={linkRef}
            className={`${subs.length > 0 ? 'has-sub' : 'no-sub'} ${
              isActive ? 'active' : ''
            }`}
            title={title}
            onClick={onClick}
            onKeyDown={onClick}
            target="_self"
          >
            <span title={title}>{title}</span>
            {subs.length > 0 ? <span className="icn-sub" /> : <></>}
          </a>
        </Link>
      )}
    </li>
  );
};

DocsLink.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.objectOf(PropTypes.any),
  subs: PropTypes.arrayOf(PropTypes.any),
};

DocsLink.defaultProps = {
  children: <></>,
  subs: [],
};

const DocumentLinks = ({ isMenuAni }) => {
  const docsLinks = DocsLinkList;
  const rootRef = useRef();
  const navRef = useRef();
  const scrollRef = useRef();
  const router = useRouter();
  const { pathname, asPath } = router;
  const [expandedItem, setExpandedItem] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const renderThumbVertical = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: '6px',
      // backgroundColor: 'black',
      opacity: '0.4',
      minWidth: '6px',
    };
    return (
      <div
        className="docs-nav-thumb"
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  };

  useEffect(
    () => () => {
      setExpandedItem(null);
    },
    [asPath]
  );

  useEffect(() => {
    if (Scrollbars) {
      setIsReady(true);
    }
    return () => {
      setIsReady(false);
    };
  }, [Scrollbars]);

  return (
    <div ref={rootRef} className="docs-left-wrapper">
      {isReady && (
        <Scrollbars
          ref={scrollRef}
          autoHide
          className="docs-nav-scroll"
          renderThumbVertical={(options) => renderThumbVertical(options)}
          mobile
        >
          <nav className="docs-nav" ref={navRef}>
            <div className="docs-link-head">
              <ul>
                <li>
                  <Link href="/docs" shallow={false}>
                    <a
                      className={`doc-nav-link no-sub ${
                        pathname === '/docs' ? 'active' : ''
                      }`}
                      title="Documentation"
                      target="_self"
                    >
                      <span>Documentation</span>
                    </a>
                  </Link>
                </li>
                <li className="bar">
                  <div />
                </li>
              </ul>
            </div>
            <ul>
              {docsLinks.map(({ key, id, title, subs }, index) => (
                <DocsLink
                  key={`${key}`}
                  isMenuAni={isMenuAni}
                  rootRef={scrollRef}
                  id={`${id || key}`}
                  title={title}
                  subs={subs}
                  expandedItem={expandedItem}
                  setExpandedItem={setExpandedItem}
                />
              ))}
            </ul>
          </nav>
        </Scrollbars>
      )}
      {/* <OverlayScrollbarsComponent
        ref={scrollRef}
        className={'docs-nav-scroll'}
        options={{
          ...SCROLL_OPTIONS,
          overflowBehavior: {
            x : 'hidden',
            y : 'scroll'
          },
          callbacks: {}
        }}
      >
        <nav className='docs-nav' ref={navRef}>
          <div className="docs-link-head">
            <ul>
              <li>
                <Link href="/docs" shallow={false}>
                  <a className={`doc-nav-link no-sub ${(pathname === ('/docs')) ? 'active' : ''}`} title="Documentation" target="_self">
                    <span>Documentation</span>
                  </a>
                </Link>
              </li>
              <li className={'bar'}><div /></li>
            </ul>
          </div>
          <ul>
            {docsLinks.map(({ key, id, title, subs, }, index) => (
              <DocsLink key={`${key}`} rootRef={scrollRef} id={`${id}`} title={title} subs={subs} expandedItem={expandedItem} setExpandedItem={setExpandedItem} />
            ))}
          </ul>
        </nav>
      </OverlayScrollbarsComponent> */}
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .docs-left-wrapper {
            flex: 1;
            height: 100%;
            overflow: hidden;
          }

          nav {
          }

          .docs-link-head {
            @include font(14px, 700, -0.3px, 20px, inherit);

            .bar {
              padding: 8px 20px;
              div {
                height: 1px;
                background: rgba(#cbd1d7, 0.4);
              }
            }
          }

          a.doc-nav-link {
            font-weight: 700;

            &.active {
              cursor: default;
              pointer-events: none;
            }
          }

          @media all and (min-width: $resolution_d_min) {
            .docs-left-wrapper {
              //height: 100%;
              //overflow: auto;
            }

            nav {
              width: 240px;
              padding-top: 32px;
              background: #394551;
              overflow: visible;
            }

            .docs-link-head {
              color: #ffffff;

              .bar {
                padding-left: 16px;
                padding-right: 16px;
                div {
                  background: rgba(#cbd1d7, 0.2);
                }
              }
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';

          .docs-nav-scroll {
            width: 100%;
            height: 100%;
            & > div:first-child {
              scroll-behavior: smooth;
              overscroll-behavior: contain;
            }
          }

          .docs-nav-thumb {
            background-color: #000000 !important;
            @media all and (min-width: $resolution_d_min) {
              background-color: #ffffff !important;
            }
          }

          .docs-nav {
            a {
              position: relative;
              display: inline-block;
              vertical-align: top;
              width: 100%;
              height: auto;
              padding: 12px 8px 12px 24px;
              cursor: pointer;
              @include font(14px, 400, -0.4px, 20px, $black);
              // text-align: left;

              span {
                position: relative;
                display: inline-block;
                text-align: left;
              }

              &:before {
                display: none;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0px;
                content: '';
                width: 4px;
                height: 24px;
                margin-top: auto;
                margin-bottom: auto;
                background: transparent;
              }

              @media (hover: hover) {
                &:hover {
                  &:before {
                    display: block;
                    background: $black;
                  }
                }
              }

              &:active {
                &:before {
                  display: block;
                  background: $black;
                }
              }

              &.active {
                cursor: default;
                pointer-events: none;
                &:before {
                  background: $point_1;
                }

                @media (hover: hover) {
                  &:hover {
                    &:before {
                      background: $point_1;
                    }
                  }
                }
                &:active {
                  &:before {
                    background: $point_1;
                  }
                }
              }

              &.no-sub {
                font-weight: 700;
                &.active {
                  background-color: transparent;
                  &:before {
                    display: block;
                  }
                }
              }

              &.has-sub {
                position: relative;
                transition: background-color 0.2s;
                transition-delay: 0.1s;
                font-weight: 700;

                .icn-sub {
                  display: block;
                  position: absolute;
                  top: 6px;
                  right: 8px;
                  content: '';
                  width: 32px;
                  height: 32px;
                  background: url('#{$image_url}/docs/bt_dropdown_small/black/normal@2x.png')
                    center center no-repeat;
                  background-size: cover;
                  transform: rotate(180deg);
                  transform-origin: center center;
                  transition: all 0.5s;
                }

                @media (hover: hover) {
                  &:hover {
                    background-color: #dce1e6;

                    &:before {
                      background-color: transparent;
                    }
                  }
                }

                &:active {
                  background-color: #dce1e6;

                  &:before {
                    background-color: transparent;
                  }
                }
              }
            }

            .sub-container {
              max-height: 0px;
              overflow: hidden;
              background: #f2f4f5;
              //transition: all .2s;
              //transform-origin: 0 0;
              opacity: 0;
              transition-delay: 0s;

              ul {
                padding: 8px 0px;
              }

              a {
                padding: 10px 30px 10px 32px;
                line-height: 24px;
                font-size: 14px;
                letter-spacing: -0.3px;
                font-weight: 600;
                color: rgba($black, 0.4);

                &:before {
                  display: block;
                }

                @media (hover: hover) {
                  &:hover {
                    background-color: transparent;
                    color: $black;
                    &:before {
                      left: 0px;
                      background: #2b2c2d;
                    }
                  }
                }

                &:active {
                  background-color: transparent;
                  color: $black;
                  &:before {
                    left: 0px;
                    background: #2b2c2d;
                  }
                }

                &.active {
                  background-color: transparent;
                  color: $black;
                  @media (hover: hover) {
                    &:hover {
                      &:before {
                        background: $point_1;
                      }
                    }
                  }
                  &:active {
                    &:before {
                      background: $point_1;
                    }
                  }
                }
              }

              .between {
                position: relative;
                margin-top: 10px;
                padding: 0 24px;

                &:before {
                  display: block;
                  content: '';
                  position: absolute;
                  top: 0px;
                  left: 24px;
                  right: 24px;
                  height: 1px;
                  background: #cbd1d7;
                }

                .between-title {
                  padding: 10px 8px 0px 8px;
                  @include font(12px, 700, -0.02px, 24px, rgba(#677c92, 0.9));
                  text-transform: uppercase;
                }
              }
            }

            .open {
              .sub-container {
                max-height: 3000px;
                overflow: visible;
                //transition: all 0.5s;
                //transform-origin: 0 0;
                opacity: 1;
              }
              a {
                &.has-sub {
                  font-weight: 700;
                  background: #dce1e6;
                  transition: none;
                  .icn-sub {
                    transform: rotate(0deg);
                  }
                }
              }
            }

            @media all and (min-width: $resolution_d_min) {
              a {
                color: #ffffff;

                &.no-sub {
                  @media (hover: hover) {
                    &:hover {
                      //background-color: #4E6378
                      &:before {
                        background-color: #ffffff;
                      }
                    }
                  }
                  &:active {
                    &:before {
                      background-color: #ffffff;
                    }
                  }
                }

                &.has-sub {
                  font-weight: 700;
                  .icn-sub {
                    transform: rotate(0deg);
                    background-image: url('#{$image_url}/docs/bt_dropdown_small/white/normal@2x.png');
                  }

                  &.active {
                    background-color: #4e6378;
                  }

                  @media (hover: hover) {
                    &:hover {
                      background-color: #4e6378;
                    }
                  }
                  &:active {
                    background-color: #4e6378;
                  }
                }
              }

              .sub-container {
                background-color: #394551;
                ul {
                  padding: 16px 0;
                }

                a {
                  color: rgba(#ffffff, 0.6);
                  padding-right: 24px;

                  @media (hover: hover) {
                    &:hover {
                      color: #ffffff;
                      &:before {
                        background-color: #ffffff;
                      }
                    }
                  }
                  &:active {
                    color: #ffffff;
                    &:before {
                      background-color: #ffffff;
                    }
                  }

                  &.active {
                    color: #ffffff;
                    @media (hover: hover) {
                      &:hover {
                        &:before {
                          background-color: $point_1;
                        }
                      }
                    }
                    &:active {
                      &:before {
                        background-color: $point_1;
                      }
                    }
                  }
                }

                .between {
                  &:before {
                    background-color: rgba(#cbd1d7, 0.2);
                  }

                  .between-title {
                    color: #87a9cb;
                  }
                }
              }

              .open {
                .sub-container {
                  background-color: rgba(#4e6378, 0.3);
                }

                a.has-sub {
                  background-color: #4e6378;
                  .icn-sub {
                    transform: rotate(-180deg);
                  }
                }
              }
            }
          }
        `}
      </style>
    </div>
  );
};

export default DocumentLinks;
