import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';

const AcademyContext = createContext();

const defaultConfig = {
  focusElement: null,
  handleNextClick: undefined,
  step: null,
  showContinueBlock: false,
  modifyFocusArea: {
    left: 0,
    top: 0,
    w: 0,
    h: 0,
  },
  modifyTooltipPosition: {
    left: 0,
    top: 0,
  },
  tooltipPosition: 'right', // left, right
};

const ContextValue = () => {
  const [isAcademyMode, setIsAcademyMode] = useState(false);
  const [config, setConfig] = useState(defaultConfig);
  const [isResize, setIsResize] = useState(false);

  const setAcademyConfig = (value) => {
    setConfig({
      ...defaultConfig,
      ...value,
    });
  };

  const resizeFocusArea = () => {
    setIsResize(true);
  };

  return {
    isAcademyMode,
    setIsAcademyMode,
    step: config.step,
    config,
    setAcademyConfig,
    isResize,
    setIsResize,
    resizeFocusArea,
  };
};

export const useAcademy = () => useContext(AcademyContext);

export const AcademyProvider = ({ children }) => (
  <AcademyContext.Provider value={ContextValue()}>
    {children}
  </AcademyContext.Provider>
);

AcademyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
