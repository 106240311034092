import React, { Component, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ToastMessage = ({ type, message, onClose }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && ref.current !== undefined) {
      ref.current.classList.add('ani');
      setTimeout(() => {
        ref.current.classList.remove('ani');
        setTimeout(() => {
          onClose();
        }, 500);
      }, 3000);
    }
  }, [ref]);

  return (
    <>
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .mind-toast-message {
            position: fixed;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;
            padding: 0 16px;

            z-index: 120000;
            opacity: 0;
            overflow: hidden;
            transform: translateY(50%);
            transition: opacity 500ms ease-in-out;

            .toast-msg-wrapper {
              min-width: 288px;
              max-width: 414px;

              background-color: #ffffff;
              border: 1px solid #ffffff;
              border-radius: 2px;
              box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);

              margin: 0 auto 43px auto;
              padding: 13px 24px;
              text-align: center;

              @include font(16px, 600, -0.4px, 26px, $black);
            }

            &.error {
              .toast-msg-wrapper {
                color: $negative;
              }
            }

            &.ani {
              opacity: 1;
              transition: opacity 500ms ease-in-out;
            }

            @media all and (min-width: $resolution_d_min) {
              .toast-msg-wrapper {
                //min-width: 588px;
                //max-width: 588px;
                //padding: 22px 24px;

                span {
                  display: inline-block;
                  vertical-align: top;
                }
              }
            }
          }
        `}
      </style>
      <style jsx global>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .mind-toast-message.error {
            a {
              color: $negative;

              @media (hover: hover) {
                &:hover {
                  color: $negative;
                }
              }

              &:active {
                color: $negative;
              }
            }
          }
        `}
      </style>
      <div ref={ref} className={`mind-toast-message ${type}`}>
        <div className="toast-msg-wrapper">
          <span>{message}</span>
        </div>
      </div>
    </>
  );
};

ToastMessage.propTypes = {
  type: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onClose: PropTypes.func.isRequired,
};

ToastMessage.defaultProps = {
  type: 'normal',
};

export default ToastMessage;
