import { ROLES } from '@js/constants';

export default function getViewPermissions(role) {
  return {
    // page /my-page
    myPage: {
      manageMenu: [ROLES.ADMIN].includes(role),

      // UserPreferenceBox
      accountInfo: [ROLES.ADMIN].includes(role),
    },

    // Menus
    homeMenu: [ROLES.ADMIN, ROLES.AI_DESIGNER].includes(role),
    scopeMenu: [ROLES.ADMIN, ROLES.ANALYST, ROLES.AI_DESIGNER].includes(role),
    liveChatMenu: [ROLES.ADMIN, ROLES.HUMAN_AGENT].includes(role),
    analyticsMenu: [ROLES.ADMIN, ROLES.ANALYST].includes(role),
    knowledgeMenu: [ROLES.ADMIN, ROLES.AI_DESIGNER].includes(role),
    changePlanButton: [ROLES.ADMIN].includes(role),
  };
}
