import React from 'react';
import CustomSpinner from '@components/common/CustomSpinner';
import { Portal } from 'react-portal';

const PageSpinner = () => (
  <Portal>
    <div className="page-spinner">
      <div className="spinner-container">
        <CustomSpinner />
      </div>
      <style jsx>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .page-spinner {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100vw;
            height: 100vh;
            z-index: 100000;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            background-color: rgba(#2b2c2d, 0.5);

            .spinner-container {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              min-width: $resolution_d_min;
              min-height: $builder_min_height;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        `}
      </style>
    </div>
  </Portal>
);

export default PageSpinner;
