const DocsLinkList = [{
  key: '10',
  id: 'why-mind-expression',
  title: 'Why Mind Expression',
  index: '/docs/why-mind-expression',
}, {
  key: '0',
  id: 'quickstarts',
  title: 'Quickstarts',
  index: '/quickstarts',
  subs: [{
    key: '0-1',
    id: 'overview',
    title: 'Overview',
  }, {
    key: '0-2',
    id: 'create-scope',
    title: 'Create Scope',
  }, {
    key: '0-3',
    id: 'add-subject',
    title: 'Add Subject',
  }, {
    key: '0-4',
    id: 'test',
    title: 'Test',
  }, {
    key: '0-5',
    id: 'audit',
    title: 'Audit',
  }],
}, {
  key: '1',
  id: 'concepts',
  title: 'Concepts',
  index: '/concepts',
  subs: [{
    key: '1-1',
    id: 'overview',
    title: 'Overview',
  }, {
    key: '1-2',
    id: 'scopes',
    title: 'Scopes',
  }, {
    key: '1-3',
    id: 'subjects',
    title: 'Subjects',
  }, {
    key: '1-4',
    id: 'components',
    title: 'Components',
  }, {
    key: '1-5',
    id: 'sandbox',
    title: 'Sandbox',
  }, {
    key: '1-6',
    id: 'conversation-history',
    title: 'Conversation history',
  }, {
    key: '1-7',
    id: 'webhooks-escalation',
    title: 'Webhooks and escalation',
  }, {
    key: '1-8',
    id: 'knowledge',
    title: 'Knowledge',
  }, {
    key: '1-9',
    id: 'analytics',
    title: 'Analytics',
  }],
}, {
  key: '2',
  id: 'how-to-guides',
  title: 'How-to Guides',
  index: '/how-to-guides',
  subs: [{
    key: '2-0',
    id: 'overview',
    title: 'Overview',
  }, {
    key: '2-1',
    id: 'back-up-import-restore-scopes',
    title: 'Back up, import and restore Scopes',
  }, {
    key: '2-2',
    id: 'set-up-webhooks-escalation',
    title: 'Set up webhooks and escalation',
  }, {
    key: '2-3',
    id: 'manage-global-keys',
    title: 'Manage global keys',
  }, {
    key: '2-4',
    id: 'customize-messages',
    title: 'Customize messages',
  }, {
    key: '2-5',
    id: 'manage-entities',
    title: 'Manage entities',
  }, {
    key: '2-6',
    id: 'steps',
    title: 'Steps',
  }, {
    key: '2-7',
    id: 'normal-components',
    title: 'Normal Components',
  }, {
    key: '2-8',
    id: 'diagnostic-components',
    title: 'Diagnostic Components',
  }, {
    key: '2-9',
    id: 'completion-actions',
    title: 'Completion Actions',
  }, {
    key: 'between-2-9-2-10',
    title: 'BUILD Q&A Subjects',
  }, {
    key: '2-10',
    id: 'qna-online-payment',
    title: 'Case 1. Online Payment',
  }, {
    key: '2-11',
    id: 'qna-data-plans',
    title: 'Case 2. Data Plans',
  }, {
    key: 'between-2-11-2-12',
    title: 'BUILD Info Search Subjects',
  }, {
    key: '2-12',
    id: 'info-search-data-usage',
    title: 'Case 1. Data Usage',
  }, {
    key: '2-13',
    id: 'info-search-payment-history',
    title: 'Case 2. Payment History',
  }, {
    key: 'between-2-13-2-14',
    title: 'BUILD Query Freestyle Subjects',
  }, {
    key: '2-14',
    id: 'query-freestyle-data-purchase',
    title: 'Case 1. Data Purchase',
  }, {
    key: '2-15',
    id: 'query-freestyle-change-mobile-plan',
    title: 'Case 2. Change Mobile Plan',
  }, {
    key: 'between-2-15-2-16',
    title: 'BUILD Diagnosis Subjects',
  }, {
    key: '2-16',
    id: 'diagnosis-mobile-problem',
    title: 'Case 1. Mobile Problem',
  }, {
    key: '2-17',
    id: 'diagnosis-internet-problem',
    title: 'Case 2. Internet Problem',
  }, {
    key: '2-18',
    id: 'diagnosis-tv-problem',
    title: 'Case 3. TV Problem',
  }, {
    key: 'between-2-18-2-19',
    title: '',
  }, {
    key: '2-19',
    id: 'test-debug',
    title: 'Test and debug',
  }, {
    key: '2-20',
    id: 'audit-ai-activities',
    title: 'Audit AI Activities',
  }],
}, {
  key: '3',
  id: 'reference',
  title: 'Reference',
  index: '/reference',
  subs: [{
    key: '3-1',
    id: 'glossary',
    title: 'Glossary',
  }, {
    key: '3-2',
    id: 'api-docs',
    title: 'API docs',
  }],
}];

export default DocsLinkList;
