import React, { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import rs from 'randomstring';

const docsContext = createContext();

const useProvidedDocs = () => {
  const [toastList, setToastList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [toastAlertContent, setToastAlertContent] = useState(null);

  const toastAlert = (type, message) => {
    // console.log(type, message);
    // setToastAlertContent(null);
    // Toast message should be popped up only one
    const list = []; // ...toastList
    list.push({
      key: rs.generate(),
      type,
      message,
    });
    setToastList(list);
  };

  const toastNotification = (message, link) => {
    /**
     * link should be
     * pathanme
     * asPath
     */
    const list = [];
    list.push({
      key: rs.generate(),
      message,
      link,
    });
    setNotificationList(list);
  };

  return {
    toastAlert,
    toastAlertContent,
    setToastAlertContent,
    toastList,
    setToastList,
    toastNotification,
    notificationList,
  };
};

export const useToast = () => useContext(docsContext);

export const ProvideToast = ({ children }) => (
  <docsContext.Provider value={useProvidedDocs({})}>
    {children}
  </docsContext.Provider>
);

ProvideToast.propTypes = {
  children: PropTypes.node.isRequired,
};
