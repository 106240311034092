import React from 'react';
import PropTypes from 'prop-types';

const PercentageCircle = ({
  percent,
  barColor = '#66c146;',
  bgColor = '#394551',
  size = 30,
  showPercentageNumber = false,
}) => (
  <div className={`c100 p${Math.round(percent)}`}>
    {showPercentageNumber && <span>{Math.round(percent)}%</span>}
    <div className="slice">
      <div className="bar" />
      <div className="fill" />
    </div>
    <style jsx>
      {`
        .rect-auto {
          clip: rect(auto, auto, auto, auto);
        }
        @for $i from 51 through 100 {
          .c100.p#{$i} .slice {
            clip: rect(auto, auto, auto, auto);
          }
        }

        .pie,
        .c100 .bar {
          position: absolute;
          border: 0.14em solid ${barColor};
          width: 0.72em;
          height: 0.72em;
          clip: rect(0em, 0.5em, 1em, 0em);
          border-radius: 50%;
          transform: rotate(0deg);
        }

        @for $i from 51 through 100 {
          .c100.p#{$i} .fill {
            position: absolute;
            border: 0.14em solid ${barColor};
            width: 0.72em;
            height: 0.72em;
            clip: rect(0em, 0.5em, 1em, 0em);
            border-radius: 50%;
            transform: rotate(0deg);
          }
        }

        .c100:after {
          position: absolute;
          top: 0.14em;
          left: 0.14em;
          display: block;
          content: ' ';
          border-radius: 50%;
          background-color: ${bgColor};
          width: 0.72em;
          height: 0.72em;
        }

        .pie-fill {
          transform: rotate(180deg);
        }

        @for $i from 51 through 100 {
          .c100.p#{$i} .bar:after,
          .c100.p#{$i} .fill {
            transform: rotate(180deg);
          }
        }

        .c100 {
          position: relative;
          font-size: ${size}px;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          background-color: rgba(78, 99, 120, 0.8);
        }
        .c100 *,
        .c100 *:before,
        .c100 *:after {
          box-sizing: content-box;
        }
        .c100.center {
          float: none;
          margin: 0 auto;
        }

        .c100 > span {
          position: absolute;
          width: 100%;
          z-index: 1;
          left: 0;
          top: 0;
          width: 5em;
          line-height: 5em;
          font-size: 0.2em;
          color: #ccc;
          display: block;
          text-align: center;
          white-space: nowrap;
        }
        .c100 .slice {
          position: absolute;
          width: 1em;
          height: 1em;
          clip: rect(0em, 1em, 1em, 0.5em);
        }
        @for $i from 1 through 100 {
          .c100.p#{$i} .bar {
            transform: rotate($i * 3.6deg);
          }
        }

        .c100:hover {
          cursor: default;
        }
      `}
    </style>
  </div>
);

PercentageCircle.propTypes = {
  percent: PropTypes.number.isRequired,
  barColor: PropTypes.string,
  bgColor: PropTypes.string,
  size: PropTypes.number,
  showPercentageNumber: PropTypes.bool,
};

export default PercentageCircle;
