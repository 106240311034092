import { HoneycombWebSDK } from '@honeycombio/opentelemetry-web';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { useEffect } from 'react';

const configDefaults = {
  ignoreNetworkEvents: true,
  // propagateTraceHeaderCorsUrls: [
  // /.+/g, // Regex to match your backend URLs. Update to the domains you wish to include.
  // ]
};
export default function Observability() {
  if (!process.env.NEXT_PUBLIC_HONEYCOMB_API_KEY) {
    // If the SDK is already initialized, skip the initialization
    return null;
  }
  useEffect(() => {
    try {
      const sdk = new HoneycombWebSDK({
        // endpoint: "https://api.eu1.honeycomb.io/v1/traces", // Send to EU instance of Honeycomb. Defaults to sending to US instance.
        debug: true, // Set to false for production environment.
        apiKey: process.env.NEXT_PUBLIC_HONEYCOMB_API_KEY, // Replace with your Honeycomb Ingest API Key.
        serviceName: 'me-frontend', // Replace with your application name. Honeycomb uses this string to find your dataset when we receive your data. When no matching dataset exists, we create a new one with this name if your API Key has the appropriate permissions.
        instrumentations: [
          getWebAutoInstrumentations({
            // Loads custom configuration for xml-http-request instrumentation.
            '@opentelemetry/instrumentation-xml-http-request': configDefaults,
            '@opentelemetry/instrumentation-fetch': configDefaults,
            '@opentelemetry/instrumentation-document-load': configDefaults,
          }),
        ],
      });
      sdk.start();
    } catch (e) {
      console.error('Error initializing Honeycomb SDK:', e);
      return null;
    }
  }, []);
  return null;
}
