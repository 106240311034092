import { createSlice, createSelector } from '@reduxjs/toolkit';

export const UPDATE_CONDITION_ACTION = 'UPDATE_CONDITION_ACTION';

const conditionSlice = createSlice({
  name: 'condition',
  initialState: {
    subject: null,
    keyList: [],
    needGetKeyList: null,
    currentCondition: null,
    rootCondition: [],
    children: [],
    isParentSelected: false,
    selectedCondition: null,
    selectedConditionNode: null,
    selectedConditionGrandSiblings: [],
    selectedConditionSiblings: [],
    selectedConditionChildren: [],
    steps: [],
    selectedStep: null,
    notifyUpdateAction: null,
    updatedName: null,
    updatedComponent: null,
    history: [],
    resetComponent: null,
    hoveredComponent: null,
    showActionPanel: false,
    showComponentSettingsPanel: false,
    showStepPanel: true,
    showComponentListPanel: true,
    showLeftPanel: false,
  },
  reducers: {
    clearSubject: (state, action) => {
      state.subject = null;
    },
    setSubject: (state, action) => {
      state.subject = action.payload;
    },
    clearAllConditions: (state, action) => {
      state.currentCondition = null;
      state.rootCondition = [];
      state.children = [];
      state.isParentSelected = null;
      state.selectedCondition = null;
      state.selectedConditionNode = null;
      state.selectedConditionSiblings = [];
      state.selectedConditionChildren = [];
      state.selectedConditionGrandSiblings = [];
    },
    setCurrentCondition: (state, action) => {
      const problem = action.payload;
      if (problem) {
        state.currentCondition = problem;
        if (state.rootCondition.length <= 0) {
          state.rootCondition = [problem];
        } else {
          const idx = state.rootCondition.findIndex(
            (root) =>
              root.url_key === problem.url_key ||
              root.parent.url_key === problem.parent.url_key
          );
          if (idx >= 0) {
            const copied = state.rootCondition;
            copied.splice(idx, state.rootCondition.length - idx);
            state.rootCondition = copied;
          }
          state.rootCondition.push(problem);
        }
      } else {
        state.currentCondition = null;
      }
    },
    setRootCondition: (state, action) => {
      state.rootCondition = action.payload;
    },
    deleteCondition: (state, action) => {
      const { is_namespace, url_key, parent } = action.payload;

      if (state.selectedCondition?.url_key === url_key) {
        state.selectedCondition = null;
      }

      if (is_namespace && state.currentCondition.parent.url_key === url_key) {
        const lastIdx = state.rootCondition.length - 1;
        state.rootCondition.splice(lastIdx, 1);
        if (state.rootCondition[lastIdx - 1]) {
          state.selectedCondition = state.rootCondition[lastIdx - 1];
          state.currentCondition = state.rootCondition[lastIdx - 1];
        } else {
          state.currentCondition = null;
        }
      }

      const idx = state.rootCondition?.findIndex(
        (ro) => ro.url_key === url_key
      );
      if (idx > -1 && state.rootCondition[idx - 1]) {
        state.rootCondition.splice(idx, 1);
        if (state.rootCondition[idx - 1]) {
          state.selectedCondition = state.rootCondition[idx - 1];
          state.currentCondition = state.rootCondition[idx - 1];
        } else {
          state.currentCondition = null;
        }
      } else if (idx === 0) {
        state.rootCondition = [];
        state.currentCondition = null;
      }
    },
    setChildren: (state, action) => {
      state.children = action.payload;
    },
    setSelectedCondition: (state, action) => {
      state.selectedConditionSiblings = [];
      state.selectedConditionChildren = [];
      state.selectedConditionGrandSiblings = [];
      state.selectedCondition = action.payload;
    },
    setIsParentSelected: (state, action) => {
      state.isParentSelected = action.payload;
    },
    setSelectedConditionNode: (state, action) => {
      state.selectedConditionNode = action.payload;
    },
    updateNamespace: (state, action) => {
      const { url_key } = action.payload;
      const idx = state.rootCondition.findIndex(
        (root) => root.parent && root.parent.url_key === url_key
      );
      if (idx > -1) {
        state.rootCondition[idx].parent = {
          ...state.rootCondition[idx].parent,
          ...action.payload,
        };
      }

      const childIdx = state.children.findIndex(
        (child) => child.parent && child.parent.url_key === url_key
      );
      if (childIdx > -1) {
        state.children[childIdx].parent = {
          ...state.children[childIdx].parent,
          ...action.payload,
        };
      }

      if (
        state.currentCondition.parent &&
        state.currentCondition.parent.url_key === url_key
      ) {
        state.currentCondition.parent = {
          ...state.currentCondition.parent,
          ...action.payload,
        };
      }
    },
    updateProblem: (state, action) => {
      const { url_key } = action.payload;

      const idx = state.rootCondition.findIndex(
        (root) => root.url_key === url_key
      );
      if (idx > -1) {
        state.rootCondition[idx] = {
          ...state.rootCondition[idx],
          ...action.payload,
        };
      }

      if (state.children) {
        const idx3 = state.children.findIndex(
          (child) => child.url_key === url_key
        );
        if (idx3 > -1) {
          state.children[idx3] = {
            ...state.children[idx3],
            ...action.payload,
          };
        }
      }

      if (state.currentCondition.url_key === url_key) {
        state.currentCondition = {
          ...state.currentCondition,
          ...action.payload,
        };
      }

      if (state.selectedCondition.url_key === url_key) {
        state.selectedCondition = {
          ...state.selectedCondition,
          ...action.payload,
        };
      }

      const idx4 = state.steps.findIndex(
        (step) => step.url_key === `STEP-${url_key}`
      );
      if (idx4 > -1) {
        const { problem } = state.steps[idx4];
        state.steps[idx4] = {
          ...state.steps[idx4],
          problem: {
            ...problem,
            ...action.payload,
          },
        };
      }

      if (
        state.selectedStep?.problem &&
        state.selectedStep.url_key === `STEP-${url_key}`
      ) {
        const { problem } = state.selectedStep;
        state.selectedStep = {
          ...state.selectedStep,
          problem: {
            ...problem,
            ...action.payload,
          },
        };
      }
    },
    setSelectedStep: (state, action) => {
      state.selectedStep = action.payload;
    },
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    updateStep: (state, action) => {
      const { url_key, problem } = action.payload;
      const idx = state.steps.findIndex((s) => s.url_key === url_key);
      if (idx > -1) {
        state.steps[idx] = {
          ...state.steps[idx],
          step_name: problem.step_name,
          priority: problem.priority,
          problem,
        };

        if (state.selectedStep?.url_key === url_key) {
          state.selectedStep = state.steps[idx];
        }
      }
    },
    setSelectedConditionSiblings: (state, action) => {
      state.selectedConditionSiblings = action.payload;
    },
    setSelectedConditionChildren: (state, action) => {
      state.selectedConditionChildren = action.payload;
    },
    setNotifyUpdateAction: (state, action) => {
      state.notifyUpdateAction = action.payload;
    },
    setUpdatedName: (state, action) => {
      state.updatedName = action.payload;
    },
    setNeedGetKeyList: (state, action) => {
      state.needGetKeyList = action.payload;
    },
    setUpdatedComponent: (state, action) => {
      state.updatedComponent = action.payload;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setResetComponent: (state, action) => {
      state.resetComponent = action.payload;
    },
    setHoveredComponent: (state, action) => {
      state.hoveredComponent = action.payload;
    },
    setShowActionPanel: (state, action) => {
      state.showActionPanel = action.payload;
    },
    setShowComponentSettingsPanel: (state, action) => {
      state.showComponentSettingsPanel = action.payload;
    },
    setShowStepPanel: (state, action) => {
      state.showStepPanel = action.payload;
    },
    setShowComponentListPanel: (state, action) => {
      state.showComponentListPanel = action.payload;
    },
    setShowLeftPanel: (state, action) => {
      state.showLeftPanel = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  clearAllConditions,
  clearSubject,
  setSubject,
  setCurrentCondition,
  setRootCondition,
  deleteCondition,
  setChildren,
  setSelectedCondition,
  setSelectedConditionNode,
  updateNamespace,
  updateProblem,
  setSelectedStep,
  setSteps,
  updateStep,
  setSelectedConditionSiblings,
  setSelectedConditionChildren,
  setNotifyUpdateAction,
  setUpdatedName,
  setNeedGetKeyList,
  setUpdatedComponent,
  setHistory,
  setResetComponent,
  setHoveredComponent,
  setShowActionPanel,
  setShowComponentSettingsPanel,
  setShowStepPanel,
  setIsParentSelected,
  setShowComponentListPanel,
  setShowLeftPanel,
} = conditionSlice.actions;

export const conditions = createSelector(
  (state) => ({
    subject: state.condition.subject,
    keyList: state.condition.keyList,
    selectedStep: state.condition.selectedStep,
    currentCondition: state.condition.currentCondition,
    rootCondition: state.condition.rootCondition,
    children: state.condition.children,
    selectedCondition: state.condition.selectedCondition,
    selectedConditionNode: state.condition.selectedConditionNode,
    steps: state.condition.steps,
    isParentSelected: state.condition.isParentSelected,
    selectedConditionSiblings: state.condition.selectedConditionSiblings,
    selectedConditionChildren: state.condition.selectedConditionChildren,
    selectedConditionGrandSiblings:
      state.condition.selectedConditionGrandSiblings,
    notifyUpdateAction: state.condition.notifyUpdateAction,
    updatedName: state.condition.updatedName,
    needGetKeyList: state.condition.needGetKeyList,
    updatedComponent: state.condition.updatedComponent,
    history: state.condition.history,
    resetComponent: state.condition.resetComponent,
    hoveredComponent: state.condition.hoveredComponent,
    showActionPanel: state.condition.showActionPanel,
    showComponentSettingsPanel: state.condition.showComponentSettingsPanel,
    showStepPanel: state.condition.showStepPanel,
    showComponentListPanel: state.condition.showComponentListPanel,
    showLeftPanel: state.condition.showLeftPanel,
  }),
  (state) => state
);

export default conditionSlice.reducer;
