import React, { useEffect, useRef, useState } from 'react';
import { useAcademy } from '@context/UseAcademy';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const defaultArea = {
  left: 0,
  top: 0,
  h: 0,
  w: 0,
};

const AcademyOverlay = () => {
  const router = useRouter();
  const {
    isAcademyMode,
    setIsAcademyMode,
    step,
    config,
    isResize,
    setIsResize,
  } = useAcademy();
  const {
    focusElement,
    handleNextClick,
    modifyFocusArea,
    modifyTooltipPosition,
    showContinueBlock,
    tooltipPosition,
  } = config;
  const { t } = useTranslation('academy');
  const tooltipRef = useRef();
  const [focusArea, setFocusArea] = useState(defaultArea);
  const [showFocusArea, setShowFocusArea] = useState(false);
  const [showExitBox, setShowExitBox] = useState(false);
  const todos = t(`${step}.to-do-list`, { returnObjects: true });
  const outerBorderLength = 4; // 4px

  // Waiting the new position ready before showing it.
  useEffect(() => {
    setShowFocusArea(false);
  }, [step, router.pathname]);

  useEffect(() => {
    setShowExitBox(false);
  }, [isAcademyMode]);

  useEffect(() => {
    const getPosition = () => {
      if (focusElement?.current) {
        const { width, height, top, left } =
          focusElement.current.getBoundingClientRect();

        setFocusArea({
          left: left + modifyFocusArea.left,
          top: top + modifyFocusArea.top,
          h: height + modifyFocusArea.h,
          w: width + modifyFocusArea.w,
        });
        setShowFocusArea(true);
      }
    };
    if (focusElement?.current) {
      // To make sure element is already rendered so that it can get correct position,
      // and also we need to wait until changing page animation completed.
      setTimeout(() => {
        getPosition();
      }, 300);

      window.addEventListener('resize', getPosition);
    }

    if (isResize) {
      getPosition();
      setIsResize(false);
    }
  }, [focusElement?.current, isResize, router.pathname]);

  let { left, top, w, h } = focusArea;
  // Make border out of focus area.
  left -= outerBorderLength;
  top -= outerBorderLength - 1;
  w = w > 0 ? w + outerBorderLength * 2 : 0;
  h = h > 0 ? h + outerBorderLength * 2 - 1 : 0;

  return (
    <>
      {isAcademyMode && step && (
        <>
          {/* Top */}
          <div
            className="absolute z-[1009] top-0 left-0 right-0 bg-backdrop"
            style={{
              height: `${top}px`,
            }}
          />

          {/* Left */}
          <div
            className="absolute z-[1009] left-0 bg-backdrop"
            style={{
              top: `${top}px`,
              width: `${
                showFocusArea ? left + (outerBorderLength + 1) : left + w
              }px`,
              height: h,
            }}
          />
          {/* Focus area */}
          <div
            className="absolute z-[1010] pointer-events-none focus-box"
            style={{
              top,
              left,
              width: w,
              height: h,
              visibility: showFocusArea ? 'visible' : 'hidden',
            }}
          />

          <div
            className="absolute z-[1010]"
            style={{
              top,
              left,
              visibility: showFocusArea ? 'visible' : 'hidden',
            }}
          >
            <div
              className={`tooltip ${
                tooltipPosition === 'left' ? 'right-arrow' : 'left-arrow'
              }`}
              ref={tooltipRef}
              style={{
                left: tooltipPosition === 'left' ? '-304px' : `${w + 24}px`,
                top: `${modifyTooltipPosition.top + 4}px`,
              }}
            >
              <div className="text-xs font-normal text-[#babec2] relative top-1  mb-3">
                {t(`${step}.step`)}
              </div>
              <h2 className="font-bold text-base mb-2">{t(`${step}.title`)}</h2>
              <p className="mb-2">{t(`${step}.desc`)}</p>
              {Array.isArray(todos) && todos.length && (
                <ul
                  className={`${
                    todos.length > 1 ? 'list-decimal' : 'list-disc'
                  } pl-5`}
                >
                  {todos.map((todo) => (
                    <li key={todo} dangerouslySetInnerHTML={{ __html: todo }} />
                  ))}
                </ul>
              )}

              {handleNextClick && (
                <button
                  className="btn mt-4 btn-primary w-auto float-right min-w-[60px]"
                  onClick={handleNextClick}
                >
                  Next
                </button>
              )}
            </div>
          </div>

          {/* Right */}
          <div
            className="absolute z-[1009] right-0 bg-backdrop"
            style={{
              top: `${top}px`,
              left: `${
                left + w - (showFocusArea ? outerBorderLength + 1 : 0)
              }px`,
              height: h,
            }}
          />

          {/* Bottom */}
          <div
            className="absolute z-[1009] bottom-0 left-0 right-0 bg-backdrop"
            style={{
              top: `${top + h}px`,
            }}
          />

          <div className="fixed z-[1009] bottom-5 left-5 ">
            <img
              className="cursor-pointer"
              src="/images/icons/ic_leave.svg"
              width="48"
              height="48"
              alt="leave lesson"
              onClick={() => setShowExitBox(!showExitBox)}
            />

            {showExitBox && (
              <div
                className="tooltip left-arrow exit"
                style={{
                  top: '-110px',
                  left: '72px',
                }}
              >
                <h2 className="font-bold text-sm mb-2">
                  {t('exit-button-title')}
                </h2>
                <p className="mb-2">{t('exit-button-desc')}</p>

                <div className="flex justify-end">
                  <button
                    className="btn mt-4 mr-2 btn-secondary w-auto min-w-[60px]"
                    onClick={() => setIsAcademyMode(false)}
                  >
                    Exit
                  </button>
                  <button
                    className="btn mt-4 btn-primary w-auto min-w-[60px]"
                    onClick={() => setShowExitBox(false)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </div>

          {showContinueBlock && (
            <div
              className="fixed z-[1009] bg-white bottom-5 right-5 p-4 w-[444px]"
              style={{
                bottom: '20px',
              }}
            >
              <div className="flex items-center">
                <div>
                  <div style={{ width: '55px', height: '55px' }}>
                    <CircularProgressbar
                      text={t(`${step}.progress`)}
                      value={t(`${step}.percentage`)}
                      strokeWidth={4}
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: '#18aa78',
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'butt',
                          // Customize transition animation
                          // transition: 'stroke-dashoffset 0.5s ease 0s',
                          // Rotate the path
                          // transform: 'rotate(0.25turn)',
                          // transformOrigin: 'center center',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: '#ebedef',
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'butt',
                          // Rotate the trail
                          // transform: 'rotate(0.25turn)',
                          // transformOrigin: 'center center',
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: '#2b2c2d',
                          // Text size
                          fontSize: '24px',
                          fontWeight: '600',
                        },
                        // Customize background - only used when the `background` prop is true
                        // background: {
                        //   fill: '#3e98c7',
                        // },
                      }}
                    />
                  </div>
                </div>

                <div className="mx-4">
                  <h2 className="text-sm font-bold mb-2">
                    {t(`${step}.title`)}
                  </h2>
                  <p>
                    <Trans i18nKey={`academy:${step}.desc`} />
                  </p>
                </div>
                <button
                  className="btn btn-primary w-auto min-w-[88px]"
                  onClick={handleNextClick}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
        </>
      )}

      <style jsx>
        {`
          .bg-backdrop {
            background-color: rgba(43, 44, 45, 0.6);
          }

          .focus-box {
            border: 5px solid #678ab8;
            border-radius: 6px;
          }

          .tooltip {
            position: absolute;
            z-index: 1;
            width: 280px;
            font-size: 14px;
            padding: 16px;
            background: #fff;
            border-radius: 2px;

            &.exit {
              width: 316px;
              &:after {
                top: 136px;
              }
            }

            // Inner arrow
            &:after {
              position: absolute;
              z-index: 2;
              top: 15px;
              content: '';
              width: 0;
              height: 0;
              border-style: solid;
            }

            &.right-arrow {
              &:after {
                right: -11px;
                border-width: 12px 12px 0 0;
                border-color: #fff transparent transparent transparent;
              }
            }
            &.left-arrow {
              &:after {
                left: -11px;
                border-width: 0 12px 12px 0;
                border-color: transparent #fff transparent transparent;
              }
            }
          }
        `}
      </style>
    </>
  );
};

export default AcademyOverlay;
