import React, { useEffect, useRef } from 'react';
import { DROPDOWN_LABEL_TYPE } from '@js/constants';
import LabelSquareCheckWrapper from '@components/common/LabelSquareCheckWrapper';
import PropTypes from 'prop-types';

const DropdownItem = ({
  option,
  className,
  isFocused,
  isSelected,
  onItemClick,
  customAction,
  OptionLabel,
  CustomLabel,
  optionMultipleLine,
  multiple,
  value,
  isItemAutofocus,
  showFullLabel = false,
}) => {
  const ref = useRef();
  const options = {
    behavior: 'auto',
    block: 'center',
  };

  useEffect(() => {
    if (ref && (isSelected || isFocused) && isItemAutofocus) {
      const { current } = ref;
      if (current) {
        current.scrollIntoView(options, 0);
      }
    }
  }, [ref, isSelected, isFocused]);

  return (
    <li
      key={option.key}
      tabIndex={0}
      ref={ref}
      role="button"
      className={`menu-item ${className || ''} ${
        optionMultipleLine ? 'fit-content' : ''
      } ${!multiple && isSelected ? 'selected' : ''} ${
        !multiple && isFocused ? 'focused' : ''
      } ${option?.labelType === DROPDOWN_LABEL_TYPE.CUSTOM ? 'custom' : ''} ${
        multiple ? 'flex items-center' : ''
      }`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isSelected || multiple) {
          onItemClick(e, option);
        }
        if (customAction && option?.labelType) {
          customAction();
        }
      }}
      onKeyDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isSelected || multiple) {
          onItemClick(e, option);
        }
        if (customAction && option?.labelType) {
          customAction();
        }
      }}
    >
      {multiple && (
        <span>
          <LabelSquareCheckWrapper
            checked={
              value?.findIndex((item) => item.value === option.value) !== -1
            }
            inputClassName="!mt-0"
            onChange={() => {}}
          />
        </span>
      )}
      {OptionLabel ? (
        <OptionLabel option={option} />
      ) : (
        <>
          {option?.labelType === DROPDOWN_LABEL_TYPE.CUSTOM ? (
            <CustomLabel option={option} />
          ) : (
            option.label
          )}
        </>
      )}
      <style jsx>
        {`
          .menu-item {
            line-height: normal;
            margin-bottom: 8px;
            border-radius: 2px;
            padding: 6px 6px 4px;
            cursor: pointer;
            ${!showFullLabel &&
            `
               overflow: hidden;
               white-space: nowrap;
               text-overflow: ellipsis;
            `}

            text-align: left;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              background: #ebedef;
            }

            &.fit-content {
              max-height: none;
              height: auto;
              overflow: visible;
              white-space: normal;
              text-overflow: unset;
            }

            &.no-value {
              font-weight: 400;
              &:hover {
                background: #ffffff;
              }
            }

            &.focused,
            &:focus {
              background: #ebedef;
            }

            &.selected {
              $color: rgba(#ffbd2e, 0.2);
              background-color: $color;
              &.focused,
              &:focus {
                background-color: $color;
              }
            }
          }
        `}
      </style>
    </li>
  );
};

DropdownItem.propTypes = {
  option: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.any,
  }).isRequired,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  onItemClick: PropTypes.func.isRequired,
  customAction: PropTypes.func,
  OptionLabel: PropTypes.oneOfType([PropTypes.func]),
  CustomLabel: PropTypes.oneOfType([PropTypes.func]),
  optionMultipleLine: PropTypes.bool,
  isItemAutofocus: PropTypes.bool,
  showFullLabel: PropTypes.bool,
};

DropdownItem.defaultProps = {
  isFocused: false,
  isSelected: false,
  customAction: null,
  OptionLabel: null,
  CustomLabel: null,
  optionMultipleLine: false,
  isItemAutofocus: true,
};

export default DropdownItem;
