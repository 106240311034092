import { PROBLEM_TYPE, SUBJECT_TYPE } from '@js/constants';
import { returnKeyTypeName } from '@js/builderHelper';
import { hierarchy, tree } from 'd3-hierarchy';
import { MarkerType } from 'reactflow';

const RELATION_TYPES = {
  DEFAULT: 'default',
  REFERENCE: 'reference',
};

const ELEMENT_WIDTHS = {
  ACTION: 160,
  NO_ACTION: 84,
  EDGE: 32,
  COMPONENT: 242,
};

const EDGE_OFFSET = 24;
const REFERENCE_EDGE_OFFSET = 48;

const getEdgeOffset = (
  nodes,
  sourceId,
  targetId,
  reservedPositions,
  type = RELATION_TYPES.DEFAULT,
  targetNodePosition = 'right'
) => {
  const sourceNode = nodes.find((item) => item.id === sourceId);
  const targetNode = nodes.find((item) => item.id === targetId);
  const defaultEdgeOffset =
    type === RELATION_TYPES.REFERENCE ? REFERENCE_EDGE_OFFSET : EDGE_OFFSET;
  let offset = defaultEdgeOffset;

  if (!sourceNode || !targetNode) {
    return offset;
  }

  // Filter intermediate nodes based on position.y
  const filteredIntermediateNodes = nodes.filter((node) => {
    const OFFSET = 20; // to avoid the node in the same row
    const minY = Math.min(sourceNode.position.y, targetNode.position.y);
    const maxY =
      Math.max(sourceNode.position.y, targetNode.position.y) - OFFSET;

    const { y } = node.position;
    return y > minY && y < maxY && node.id !== sourceId && node.id !== targetId;
  });

  // Find the node with the highest x-coordinate in the same row, include the width of the node among the filtered results
  const intermediateNodeWithMaxX = filteredIntermediateNodes.reduce(
    (maxXNode, currentNode) =>
      currentNode.position.x + currentNode.width >
      maxXNode.position.x + maxXNode.width
        ? currentNode
        : maxXNode,
    filteredIntermediateNodes[0]
  );

  // Determine the edge position, initially aligned with either the source node or the target position, based on the x-coordinate of the farthest node.
  // If the target node is on the top, the edge's position should be always aligned with the source node.
  const initialEdgePositionX =
    targetNodePosition === 'top'
      ? sourceNode.position.x + sourceNode.width
      : Math.max(
          sourceNode.position.x + sourceNode.width,
          targetNode.position.x + targetNode.width
        );

  let edgePositionX = initialEdgePositionX + offset;
  // Calculate minY based on sourceNode as well
  const edgePositionMinY = Math.min(
    sourceNode.position.y,
    targetNode.position.y
  );
  const edgePositionMaxY = Math.max(
    sourceNode.position.y,
    targetNode.position.y
  );

  if (
    intermediateNodeWithMaxX &&
    intermediateNodeWithMaxX.position.x + intermediateNodeWithMaxX.width >
      edgePositionX
  ) {
    // Calculate the offset for the path
    offset =
      intermediateNodeWithMaxX.position.x +
      intermediateNodeWithMaxX.width -
      (sourceNode.position.x + sourceNode.width) +
      offset;

    // Initial value
    edgePositionX = initialEdgePositionX + offset;
  }

  const isCollisionXCheck = !(
    targetNodePosition === 'top' && !filteredIntermediateNodes.length
  );
  // Check if the initial value exists in given range
  // Move the edge position to the next available position
  if (isCollisionXCheck) {
    const valuesInRange = reservedPositions.filter((pos) => {
      const isXInRange =
        pos.x >= edgePositionX - defaultEdgeOffset &&
        pos.x <= edgePositionX + defaultEdgeOffset;

      const isYOverlapping =
        (pos.y.min <= edgePositionMaxY && pos.y.max >= edgePositionMinY) ||
        (edgePositionMinY <= pos.y.max && edgePositionMaxY >= pos.y.min);

      return isXInRange && isYOverlapping;
    });

    // If it exists, add offset to the value
    if (valuesInRange.length) {
      const maxInRange = valuesInRange.reduce(
        (max, pos) => (pos.x > max ? pos.x : max),
        valuesInRange[0].x
      );

      if (maxInRange + defaultEdgeOffset > edgePositionX) {
        edgePositionX = maxInRange + defaultEdgeOffset;
        offset = edgePositionX - initialEdgePositionX;
      }
    }
  }

  // Reserve X for 3 values in case of reference edge to cover reference edge area, x, x - EDGE_OFFSET, x + EDGE_OFFSET
  // Reserve the position
  reservedPositions.push({
    x: edgePositionX,
    y: { min: edgePositionMinY, max: edgePositionMaxY },
  });

  if (type === RELATION_TYPES.REFERENCE) {
    reservedPositions.push({
      x: edgePositionX - EDGE_OFFSET,
      y: { min: edgePositionMinY, max: edgePositionMaxY },
    });
    reservedPositions.push({
      x: edgePositionX + EDGE_OFFSET,
      y: { min: edgePositionMinY, max: edgePositionMaxY },
    });
  }

  return offset;
};

const buildReferenceEdge = (sourceId, relation, nodes, reservedPositions) => {
  const sourceHandle = `customer-${relation.source}-source-right-center`;

  const targetId = `customer-${relation.target}`;
  const targetHandle = `customer-${relation.target}-right`;

  const offset = getEdgeOffset(
    nodes,
    sourceId,
    targetId,
    reservedPositions,
    RELATION_TYPES.REFERENCE
  );

  return {
    id: `reference-edges-${relation.source}-${relation.target}`,
    source: sourceId,
    sourceHandle,
    targetHandle,
    target: targetId,
    type: 'referenceEdge',
    label: 'Reference',
    data: {
      label: 'Reference',
      offset: offset ?? REFERENCE_EDGE_OFFSET,
    },
    style: { strokeDasharray: '2,2', stroke: '#2b2c2d' },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#2b2c2d',
    },
  };
};

export const buildEdgesByRelations = (
  nodes,
  relations,
  isShowReferenceEdge
) => {
  const edges = [];
  const nodeIds = [];
  const reservedPositions = [];

  nodes.forEach((node) => {
    nodeIds.push(node.id);
  });

  // relations.slice(0, 5).forEach((relation, index) => {
  relations.forEach((relation, index) => {
    const sourceId = nodeIds.includes(`customer-${relation.source}`)
      ? `customer-${relation.source}`
      : `action-${relation.source}`;

    let targetId;
    let targetHandle;
    const pathOptions = {};
    let edge = null;

    if (relation.type === RELATION_TYPES.REFERENCE) {
      if (isShowReferenceEdge) {
        edge = buildReferenceEdge(sourceId, relation, nodes, reservedPositions);
      }
    } else {
      // Source rule: if customer response exist, it's source if not check action node.
      // Target rule: if target's action node exists point to action node
      if (nodeIds.includes(`action-${relation.target}`)) {
        targetId = `action-${relation.target}`;
        targetHandle = `action-${relation.target}-top`;
      } else {
        targetId = `customer-${relation.target}`;
        targetHandle = `customer-${relation.target}-right`;
      }

      const offset = getEdgeOffset(
        nodes,
        sourceId,
        targetId,
        reservedPositions,
        RELATION_TYPES.DEFAULT,
        targetHandle.includes('top') ? 'top' : 'right'
      );

      if (offset) {
        pathOptions.offset = offset;
      }

      edge = {
        id: `relation-edges-${index}`,
        source: sourceId,
        sourceHandle: `customer-${relation.source}-source-right-bottom`,
        target: targetId,
        type: 'smoothstep',
        style: { strokeDasharray: '2,2', stroke: '#2b2c2d' },
        pathOptions,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: '#2b2c2d',
        },
        targetHandle,
      };
    }

    if (edge) {
      edges.push(edge);
    }
  });

  return edges;
};

// Check whether current node has response
const hasResponse = (node) => {
  if (node.children) {
    return node.children.some(
      (item) => item?.data?.type === PROBLEM_TYPE.RESPONSE
    );
  }

  return false; // No object with type "RS" found
};

class MyTree {
  $onInit(data) {
    this.margin = { top: 20, right: 10, bottom: 20, left: 10 };
    this.width = 1400 - this.margin.right - this.margin.left;
    this.height = 800 - this.margin.top - this.margin.bottom;
    this.barHeight = 80;
    this.barWidth = this.width * 0.8;
    this.i = 0;
    this.duration = 750;
    this.tree = tree().nodeSize([0, 44]);
    this.root = this.tree(hierarchy(data));
    this.root.each((d) => {
      d.name = d.id; // transferring name to a name variable
      d.id = this.i; // Assigning numerical Ids
      this.i++;
    });
    this.root.x0 = this.root.x;
    this.root.y0 = this.root.y;

    // this.root.children.forEach(this.collapse);
    this.update(this.root);
  }

  collapse = (d) => {
    if (d.children) {
      d._children = d.children;
      d._children.forEach(this.collapse);
      d.children = null;
    }
  };

  click = (d) => {
    if (d.children) {
      d._children = d.children;
      d.children = null;
    } else {
      d.children = d._children;
      d._children = null;
    }
    this.update(d);
  };

  update = (source) => {
    this.width = 800;

    // Compute the new tree layout.
    const nodes = this.tree(this.root);
    const nodesSort = [];
    nodes.eachBefore((n) => {
      nodesSort.push(n);
    });
    this.height = Math.max(
      500,
      nodesSort.length * this.barHeight + this.margin.top + this.margin.bottom
    );
    // Compute the "layout".
    nodesSort.forEach((n, i) => {
      n.x = i * this.barHeight;
    });

    // Stash the old positions for transition.
    nodesSort.forEach((d) => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  };
}

export const getLayoutedElements = (
  data,
  isShowReferenceNode = false,
  relations = []
) => {
  const myTree = new MyTree();
  myTree.$onInit(data);

  const treeRoot = myTree.root;

  const newNodes = [];
  const newEdges = [];
  const compileNode = (node, siblingOrder = null) => {
    const type =
      node?.data?.type === 'response' ||
      node?.data?.type === PROBLEM_TYPE.SUBJECT
        ? PROBLEM_TYPE.RESPONSE
        : node?.data?.type;

    const title = returnKeyTypeName(type);
    const componentGroupId = `group-${node.id}`;
    const componentNodeId = `${node.id}`;

    const componentNode = {
      id: componentNodeId,
      type: 'componentNode',
      data: {
        id: `${node.data.id}`,
        url_key:
          type === PROBLEM_TYPE.RESPONSE
            ? node?.parent?.data?.url_key ?? node?.data?.url_key
            : node?.data?.url_key,
        groupId: componentGroupId,
        name: node.data.name,
        type,
        componentType:
          type === PROBLEM_TYPE.RESPONSE
            ? `${node?.parent?.data?.type}`
            : node?.data?.type,
        title,
        hasResponse: hasResponse(node),
        isConfigured: node.data.is_configured,
        parent: node?.parent?.data,
        componentPosition: {
          x: node.x,
          y: node.y,
        },
        componentNodeId,
      },
      position: {
        x: node.y,
        y: node.x,
      },
    };

    if (siblingOrder > 0 && type !== PROBLEM_TYPE.SELECTION) {
      componentNode.data.siblingConnectorHeight =
        node.x - node.parent.children[siblingOrder - 1].x;
    }

    if (type === PROBLEM_TYPE.SELECTION) {
      componentNode.data.childConnectorHeight = node.x - node.parent.x - 2;
    }

    newNodes.push(componentNode);

    // Push action node with Edge
    const actionId = `action-${node.data.action?.id ?? node.data.id}`;

    const actionNodeXPosition =
      node.y + ELEMENT_WIDTHS.COMPONENT + ELEMENT_WIDTHS.EDGE; // 242px component node size + 32px line connector length
    let customerResponseNodeXPosition =
      actionNodeXPosition + ELEMENT_WIDTHS.EDGE + ELEMENT_WIDTHS.ACTION; // Action node plus a space between customer response node.

    // Selection node we don't need an action node, only customer response node is there.
    if (type === PROBLEM_TYPE.SELECTION) {
      customerResponseNodeXPosition = actionNodeXPosition;
    }

    if (![PROBLEM_TYPE.SELECTION, PROBLEM_TYPE.PARAM_BUNDLE].includes(type)) {
      newNodes.push({
        id: actionId,
        type: 'actionNode',
        data: {
          id: actionId,
          urlKey:
            type === PROBLEM_TYPE.RESPONSE
              ? node?.parent?.data?.url_key ?? node?.data?.url_key
              : node?.data?.url_key,
          isConfigured: node.data.is_configured,
          type,
          componentType:
            type === PROBLEM_TYPE.RESPONSE
              ? `${node?.parent?.data?.type}`
              : node?.data?.type,
          groupId: componentGroupId,
          action: node.data.action,
          actionType:
            // @Todo it should be fixed from BE, this is temporary fix.
            node.data.action?.name === 'Confirm'
              ? 'confirm'
              : node.data.action?.type,
          componentPosition: {
            x: node.x,
            y: node.y,
          },
        },
        position: {
          x: actionNodeXPosition,
          y: node.x + 10,
        },
        draggable: false,
      });
    }

    const edgeToAction = {
      id: `edges-${node.id}`,
      source: `${node.id}`, // component node id
      target: actionId,
    };

    if (node.data.action) {
      edgeToAction.style = { stroke: '#2b2c2d' };
      edgeToAction.markerEnd = 'edge-action';
    } else {
      edgeToAction.style = { strokeDasharray: '2,2', stroke: '#2b2c2d' };
    }

    newEdges.push(edgeToAction);

    // Push customer response node with arrows
    // @Todo Move it to a function build customer response node
    let customerResponseMsg = node.data.statement?.name;
    let customerResponseNodeSource = actionId;

    if (type === PROBLEM_TYPE.SELECTION) {
      customerResponseNodeSource = componentNodeId;

      if (!customerResponseMsg) {
        customerResponseMsg = 'Not Defined';
      }
    }

    const customerRespondId = `customer-${
      node.data.statement?.id ?? node.data.id
    }`;

    // Only Selection allows to have customer response node without action node
    const isEnabledCustomerResponse =
      (customerResponseMsg && node.data?.action) ||
      type === PROBLEM_TYPE.SELECTION;

    if (isEnabledCustomerResponse) {
      newNodes.push({
        id: customerRespondId,
        type: 'customerResponseNode',
        data: {
          id: customerRespondId,
          groupId: componentGroupId,
          text: customerResponseMsg,
          stepSequence: node.data.statement?.step_sequence,
          composition: node.data.statement?.composition,
        },
        position: {
          x: customerResponseNodeXPosition,
          y: node.x + 10,
        },
        draggable: false,
      });

      // Connection line from action node
      newEdges.push({
        id: `customer-edge-${node.id}`,
        source: customerResponseNodeSource,
        target: customerRespondId,
        style: { stroke: '#2b2c2d' },
        markerEnd: 'edge-customer-response',
      });
    }

    // Build Reference Node
    const isReferenceNodeInSameStep = relations.filter(
      (edge) =>
        edge.source === (node.data.statement?.id ?? node.data.id) &&
        edge.type === 'reference'
    ).length;

    if (
      node.data.reference &&
      type !== PROBLEM_TYPE.RESPONSE &&
      isShowReferenceNode &&
      !isReferenceNodeInSameStep
    ) {
      const referenceNodeId = `reference-node-${
        node.data.statement?.id ?? node.data.id
      }`;

      const xPosition =
        customerResponseNodeXPosition +
        (isEnabledCustomerResponse ? 250 : ELEMENT_WIDTHS.ACTION); // @todo calculate the position from previous node

      newNodes.push({
        id: referenceNodeId,
        type: 'referenceNode',
        componentNodeId: referenceNodeId,
        data: {
          id: referenceNodeId,
          name: node.data.reference.name,
          type: node.data.reference.type,
          title: returnKeyTypeName(node.data.reference.type),
          step_url_key: node.data.reference.step_url_key,
        },
        position: {
          x: xPosition,
          y: [
            SUBJECT_TYPE.FS,
            SUBJECT_TYPE.QNA,
            SUBJECT_TYPE.IR,
            SUBJECT_TYPE.IP,
          ].includes(node.data.reference.type)
            ? node.x + 6
            : node.x,
        },
        draggable: false,
      });

      // Connection line from action node
      newEdges.push({
        id: `reference-edge-${node.id}`,
        source: isEnabledCustomerResponse ? customerRespondId : actionId,
        target: referenceNodeId,
        type: 'referenceEdge',
        label: 'Reference',
        data: {
          label: 'Reference',
        },
        style: { strokeDasharray: '2,2', stroke: '#2b2c2d' },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: '#2b2c2d',
        },
      });
    }

    if (node?.children?.length) {
      const count = node.children.length;
      for (let i = 0; i < count; i += 1) {
        compileNode(node.children[i], i);
      }
    }
  };

  compileNode(treeRoot);

  return {
    newNodes,
    newEdges,
  };
};

export const isNamespace = (type) =>
  [PROBLEM_TYPE.PARAM_BUNDLE, PROBLEM_TYPE.SELECTION_CLASS].includes(type);
