import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { useAcademy } from '@context/UseAcademy';

const ScopePopupWrapper = ({
  className,
  title,
  onClose,
  children,
  popupWidth = 384,
  isFooterButtonFixedSize = false,
  testId = '',
}) => {
  const [isAni, setIsAni] = useState(false);
  const ref = useRef();
  const { isAcademyMode } = useAcademy();

  const handleKeyDown = useCallback(
    (event) => {
      if (event?.key === 'Escape' && !isAcademyMode) {
        onClose();
      }
    },
    [ref, onClose]
  );

  const onClickContainer = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // document.addEventListener('mousedown', handleClickOutside, true);
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      // document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        setIsAni(true);
      }, 0);
    }
  }, [ref]);

  return (
    <Portal node={document.getElementById('builder-layout')}>
      <div
        className={`popup-default popup-scope ${className} ${
          isAni ? 'popup-open' : ''
        }`}
        data-testid={testId}
      >
        <div className="popup-container" onMouseDown={onClickContainer}>
          <div className="popup-wrapper">
            <div className="popup-content">
              <div ref={ref} className="content-wrapper">
                <div className="popup-header whitespace-pre-line">{title}</div>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx global>
        {`
          @import './src/sass/_vars.scss';
          @import './src/sass/_mixins.scss';
          .popup-scope.popup-default {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1001;
            overflow: auto;
            background: rgba(43, 44, 45, 0.5);
            -webkit-overflow-scrolling: touch;

            &.max-height-auto {
              .popup-content .content-wrapper {
                max-height: none;
              }
            }

            .popup {
              &-container {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                // min-width: $resolution_d_min;
                min-height: $builder_min_height;

                @media all and (max-width: 768px) {
                  min-width: auto;
                }
              }

              &-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: table;
                opacity: 0;
                transform: translateY(-30px);
                transition: transform 0.5s, opacity 0.3s;
              }

              &-content {
                display: table-cell;
                // min-width: $resolution_d_min;
                vertical-align: middle;
                padding: 24px 0;
                height: 100%;
                @media all and (min-height: $builder_min_height) {
                  height: auto;
                }

                @media all and (max-width: 768px) {
                  min-width: auto;
                }

                .content-wrapper {
                  position: relative;
                  margin: 0 auto;
                  width: ${popupWidth}px;
                  border-radius: 2px;
                  display: flex;
                  flex-direction: column;
                  background: $white;
                  max-height: $builder_min_height - 160px;
                  padding: 32px;
                  @media all and (min-height: $builder_min_height) {
                    max-height: calc(100vh - 160px);
                  }

                  @media all and (max-width: 768px) {
                    width: 90%;
                  }
                }
              }

              &-header {
                @include font(20px, 700, -0.4px, 27px, inherit);
              }

              &-body {
                margin-top: 32px;
              }

              &-footer {
                margin-top: 48px;
                padding-left: 128px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                button {
                  display: inline-block;
                  width: ${isFooterButtonFixedSize
                    ? '88px'
                    : 'calc((100% - 16px) / 2)'};
                  min-width: 88px;
                  margin-right: 16px;

                  &:last-child {
                    margin-right: 0px;
                  }
                }

                // &.edit {
                //   padding-left: 64px;
                // }
              }
            }

            &.popup-open {
              .popup {
                &-wrapper {
                  opacity: 1;
                  transform: translateY(0);
                }
              }
            }
          }
        `}
      </style>
    </Portal>
  );
};

ScopePopupWrapper.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  popupWidth: PropTypes.number,
  isFooterButtonFixedSize: PropTypes.bool,
  testId: PropTypes.string,
};

ScopePopupWrapper.defaultProps = {
  className: '',
  title: '',
};

export default ScopePopupWrapper;
