import React from 'react';
import PropTypes from 'prop-types';

const ResponsiveLayout = ({ children }) => (
  <>
    {children}
    <style jsx global>
      {`
        @import './src/sass/_vars.scss';
        @import './src/sass/_mixins.scss';
        // Reset
        body {
          color: $font_color;
        }
        .body-overlay {
          position: relative;
          &:before {
            content: '';
            background: rgba(43, 44, 45, 0.2);
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 11;
            @media (max-width: 1365px) {
              display: none;
            }
          }
        }
        a {
          color: $font_color;
          font: {
            size: $font_size;
            family: $font_family;
          }
        }

        input[type='text'],
        input[type='tel'],
        input[type='email'],
        input[type='password'],
        textarea {
          font-family: $font-family;
          display: block;
          width: 100%;
          height: 56px;
          border-radius: 2px;
          border: solid 1px #cbd1d7;
          background-color: #f2f4f5;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: -0.4px;
          color: #2b2c2d;
          padding: 0 16px;

          &.input-md {
            font-size: 14px;
            height: 40px;
            padding: 8px 12px;
            &::placeholder {
              font-size: 14px;
            }
          }

          &::placeholder {
            font-family: $font_family;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: -0.4px;
            color: #555f69;
            opacity: 0.4;
          }

          @media (hover: hover) {
            &:hover {
              border-color: #2b2c2d;

              & + .lbl-text {
                opacity: 1;
              }
            }
          }

          &:active {
            border-color: #2b2c2d;
          }

          &:focus {
            border-color: #2b2c2d;
            background: $white;
          }

          &.error,
          &.invalid {
            border-color: #ff3535;
            background-color: #fff;
          }

          &.error[disabled],
          &.error[disabled='disabled'],
          &.error[disabled='true'],
          &[disabled],
          &[disabled='disabled'],
          &[disabled='true'] {
            border-color: #cbd1d7;
            //opacity: 0.6 !important;
            //& + .lbl-text {
            //  opacity: 0.4;
            //}

            @media (hover: hover) {
              &:hover {
                border-color: #cbd1d7;

                //& + .lbl-text {
                //  opacity: 0.4;
                //}
              }
            }
            &:active,
            &:focus {
              border-color: #cbd1d7;

              //& + .lbl-text {
              //  opacity: 0.4;
              //}
            }
          }

          &.dark {
            color: $white;
            border: solid 1px #818a93;
            background-color: #455361;
            &::placeholder {
              color: $white;
            }

            @media (hover: hover) {
              &:hover {
                border-color: $white;
              }
            }

            &:active {
              border-color: $white;
            }

            &:focus {
              border-color: $white;
              background: $dark_grey;
            }

            &.error[disabled],
            &.error[disabled='disabled'],
            &.error[disabled='true'],
            &[disabled],
            &[disabled='disabled'],
            &[disabled='true'] {
              border-color: #818a93;
              background: #455361;
              @media (hover: hover) {
                &:hover {
                  border-color: #818a93;
                  background: #455361;
                }
              }
              &:active,
              &:focus {
                border-color: #818a93;
                background: #455361;
              }
            }
          }
        }

        textarea {
          min-height: 320px;
          resize: none;
          line-height: 26px;
          padding-top: 15px;
          padding-bottom: 15px;
        }

        input[type='button'],
        input[type='submit'],
        button {
          font-family: $font_family;
          display: block;
          width: 100%;
          height: 56px;
          // line-height: 54px;
          font-size: 18px;
          font-weight: 700;
          letter-spacing: -0.4px;
          background: $white;
          border: 1px solid #555f69;
          border-radius: 2px;
          text-align: center;
          cursor: pointer;
          color: #555f69;

          @media (hover: hover) {
            &:hover {
              border-color: #555f69;
              background-color: #555f69;
              color: #ffffff;
            }
          }

          &:active {
            border-color: #555f69;
            background-color: #555f69;
            color: #ffffff;
          }

          &[disabled],
          &[disabled='disabled'],
          &[disabled='true'] {
            background: #ffffff;
            border-color: #555f69;
            opacity: 0.6 !important;
            cursor: default;
            @media (hover: hover) {
              &:hover {
                border-color: #555f69;
                background: #ffffff;
                color: #555f69;
              }
            }

            &:active {
              border-color: #555f69;
              background: #ffffff;
              color: #555f69;
            }
          }

          &.primary {
            background: $primary;
            border-color: $primary;
            color: #ffffff;

            @media (hover: hover) {
              &:hover {
                background: $primary_hover;
                border-color: $primary_hover;
              }
            }
            &:active {
              background: $primary_hover;
              border-color: $primary_hover;
            }

            &[disabled],
            &[disabled='disabled'],
            &[disabled='true'] {
              background: $primary;
              border-color: $primary;
              @media (hover: hover) {
                &:hover {
                  background: $primary;
                  border-color: $primary;
                  color: #ffffff;
                }
              }
              &:active {
                background: $primary;
                border-color: $primary;
                color: #ffffff;
              }
            }
          }

          &.grey {
            background: #e3e6e9;
            border-color: #e3e6e9;
            @media (hover: hover) {
              &:hover {
                background: rgba(#e3e6e9, 0.4);
                border-color: transparent;
                color: #555f69;
              }
            }
            &:active {
              background: rgba(#e3e6e9, 0.4);
              border-color: transparent;
              color: #555f69;
            }
          }
        }

        .lbl-container .lbl-error,
        .dropdown-select .lbl-error {
          margin-top: 4px;
          @include font(16px, 700, -0.4px, 22px, #ff3535);
        }

        .lbl-container {
          position: relative;
          vertical-align: top;
          display: inline-block;
          width: 100%;

          .lbl-wrapper {
            display: flex;
            flex-direction: column-reverse;
            padding-top: 0;
          }

          .lbl-text {
            position: relative;
            @include font(16px, 700, -0.4px, 22px, rgba($black, 0.4));
          }

          input,
          textarea,
          .lbl-value {
            margin-top: 8px;
          }
        }

        @media all and (min-width: $resolution_d_min) {
          textarea {
            padding-top: 13px;
            padding-bottom: 13px;
          }

          .lbl-container {
            .lbl-text {
              font-size: 16px;
              letter-spacing: -0.4px;
              line-height: 22px;
            }
          }
        }

        //common
        .wrapper {
          min-width: 360px;
          max-width: 460px;
          //max-width: 414px;
          //max-width: #{414px - ($global_m_padding * 2)};
          margin: 0 auto;
          padding: 0 $global_m_padding;
        }

        .mind-body {
          background: #ffffff;
          //padding-top: $header_m_height;
          min-height: 500px;
        }

        .me-body {
          padding-top: $header_m_height;
        }

        .docs-body-wrapper {
          padding-top: $header_m_height;
          .mind-body {
            padding-top: 0;
          }
        }

        .btn {
          background-color: $white;

          &-get-started {
            display: inline-block;
            @include font(16px, 700, -0.4px, 32px, $primary);
            padding: 0 0px;
            text-align: center;

            @media (hover: hover) {
              &:hover {
                color: $primary_hover;
              }
            }

            &:active {
              color: $primary_hover;
            }
          }

          &-icon {
            display: inline-block;
            width: 32px;
            height: 32px;
            padding: 0px;
            background-position: center center;
            background-size: cover;
            vertical-align: top;
            cursor: pointer;
          }
        }

        @media all and (min-width: 768px) {
          .wrapper {
            //$width: 768px - ($global_t_padding * 2);
            max-width: 1024px;
            padding: 0px $global_t_padding;
          }

          .me-body {
            padding-top: $header_d_height;
          }

          .docs-body-wrapper {
            padding-top: $header_d_height;
            .mind-body {
              padding-top: 0;
            }
          }
        }

        @media all and (min-width: $resolution_d_min) {
          .wrapper {
            //$width: $resolution_d_min - ($global_d_padding * 2);
            max-width: $resolution_d_min;
            padding: 0 $global_d_padding;
          }

          .btn {
            &-primary {
              font-size: 18px;
            }
          }
        }
      `}
    </style>
  </>
);

ResponsiveLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.array,
  ]).isRequired,
};

export default ResponsiveLayout;
